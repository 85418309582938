import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';

function buildFirebaseConfig(
  apiKey: string,
  projectId: string,
  appId: string,
  messagingSenderId: string,
  measurementId: string
) {
  const firebaseConfig: FirebaseOptions = {
    apiKey,
    authDomain: `${projectId}.firebaseapp.com`,
    databaseURL: `https://${projectId}.firebaseio.com`,
    projectId,
    storageBucket: `${projectId}.appspot.com`,
    messagingSenderId,
    appId,
    measurementId,
  };
  return firebaseConfig;
}

let firebaseApp: FirebaseApp | undefined;

export function init(
  apiKey: string,
  projectId: string,
  appId: string,
  messagingSenderId: string,
  measurementId: string
) {
  if (firebaseApp) {
    // skip if app already initialized
    return;
  }
  const config = buildFirebaseConfig(apiKey, projectId, appId, messagingSenderId, measurementId);
  firebaseApp = initializeApp(config);
}

export function getApp() {
  if (!firebaseApp) {
    throw Error('Firebase app is not initialized yet!');
  }
  return firebaseApp;
}

export function destroy() {
  firebaseApp = undefined;
}
