import React from 'react';
import { connect } from 'react-redux';
import { acceptAllAction, declineAllAction, saveConsentAction, toggleSettingsAction } from '../actions';
import * as Selectors from '../selectors';
import { EBannerMode, ICookieConsent } from '../types';
import ConsentBanner from './ConsentBanner';
import './CookieConsent.scss';
import CookieSettings from './CookieSettings';

export interface ICookieConsentOwnProps {
  privacyUrl: string;
}

export interface ICookieConsentProps extends ICookieConsentOwnProps {
  firebase: boolean;
  userData: boolean;
  settings: boolean;
  submitted: boolean;
  bannerMode: EBannerMode;
  onAcceptAll: () => void;
  onDeclineAll: () => void;
  onSaveConsent: (consent: ICookieConsent) => void;
  onToggleSettings: () => void;
}

export const CookieConsentFC: React.FC<ICookieConsentProps> = ({
  firebase,
  userData,
  privacyUrl,
  settings,
  submitted,
  bannerMode,
  onAcceptAll,
  onDeclineAll,
  onSaveConsent,
  onToggleSettings,
}) => {
  const hideBanner = (bannerMode === EBannerMode.NoBanner || submitted) && !settings;
  if (hideBanner) {
    return null;
  }
  const hideOverlay = bannerMode === EBannerMode.Postponable;
  return (
    <div className="cookie-consent-container">
      {hideOverlay ? null : <div className="cookie-consent-overlay" data-testid="cookie-consent-overlay" />}
      <CookieSettings
        firebase={firebase}
        userData={userData}
        privacyUrl={privacyUrl}
        visible={settings}
        onSubmit={onSaveConsent}
        onCancel={onToggleSettings}
      />
      <ConsentBanner
        privacyUrl={privacyUrl}
        show={!submitted}
        onAcceptAll={onAcceptAll}
        onDeclineAll={onDeclineAll}
        onToggleSettings={onToggleSettings}
      />
    </div>
  );
};

export const mapStateToProps = (state: Selectors.IAppState) => {
  const firebase = Selectors.getFirebaseConsent(state);
  const userData = Selectors.getUserDataConsent(state);
  const settings = Selectors.isSettingsOpen(state);
  const submitted = Selectors.isConsentGiven(state);
  const bannerMode = Selectors.getBannerMode(state);
  return {
    firebase,
    userData,
    settings,
    submitted,
    bannerMode,
  };
};

export const mapDispatchToProps = {
  onAcceptAll: acceptAllAction,
  onDeclineAll: declineAllAction,
  onSaveConsent: saveConsentAction,
  onToggleSettings: toggleSettingsAction,
};

const CookieConsent = connect(mapStateToProps, mapDispatchToProps)(CookieConsentFC);
export default CookieConsent;
