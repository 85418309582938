import { InitOptions } from 'i18next';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import service from './service';
import { ILanguageStore } from './types';

const LanguageContext = createContext<ILanguageStore | undefined>(undefined);

interface ILanguageProviderProps {
  localesPath: string;
  ns?: string | readonly string[];
  defaultNS?: string | false | readonly string[];
  fallbackNS?: string | false | readonly string[];
  supportedLngs?: false | readonly string[];
  debug?: boolean;
}

export const LanguageProvider: FC<React.PropsWithChildren<ILanguageProviderProps>> = ({
  children,
  localesPath,
  debug,
  ns,
  defaultNS,
  fallbackNS,
  supportedLngs,
}) => {
  const [store, setStore] = useState<ILanguageStore>(service.getStore());
  const dispatch = useDispatch();
  useEffect(() => {
    const customConfig: InitOptions = {};
    if (ns) {
      customConfig.ns = ns;
    }
    if (debug) {
      customConfig.debug = debug;
    }
    if (defaultNS) {
      customConfig.defaultNS = defaultNS;
    }
    if (fallbackNS) {
      customConfig.fallbackNS = fallbackNS;
    }
    if (supportedLngs) {
      customConfig.supportedLngs = supportedLngs;
    }
    service.init(localesPath, dispatch, customConfig);
    const unsubscribe = service.subscribe(setStore);
    return () => {
      unsubscribe();
    };
  }, [dispatch, debug, localesPath, ns, defaultNS, fallbackNS, supportedLngs]);
  return <LanguageContext.Provider value={store}>{children}</LanguageContext.Provider>;
};

export default function useTranslation() {
  const value = useContext(LanguageContext);
  if (!value) {
    throw new Error('This hook must be used only inside the LanguageProvider component!');
  }
  return value;
}
