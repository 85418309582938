import { useEffect } from 'react';
import useTranslation from './useTranslation';

export default function useDocumentLang() {
  const { language } = useTranslation();
  useEffect(() => {
    if (!language) {
      return;
    }
    window.document.documentElement.lang = language;
  }, [language]);
}
