import sumBy from 'lodash/sumBy';
import * as Constants from './constants';
import { IHistogramBin } from './types';

/**
 * returns the total number of all users in the given histogram
 * @param bins histogram bins
 */
function getTotalUsers(bins: IHistogramBin[]) {
  return sumBy(bins, 'numberUsers');
}

/**
 * calculate median result for given histogram
 * @param bins histogram bins
 */
function getThresholdResult(bins: IHistogramBin[]) {
  return getResultFromPerformance(0.5, bins);
}

/**
 * calculated the max result for the given histogram
 * @param bins histogram bins
 */
function getMaxResult(bins: IHistogramBin[]) {
  return getResultFromPerformance(1, bins);
}

/**
 * calculates the performance of the user in the gameplay
 * @param result current score
 * @param bins histogram bins
 * @param maxCapped to cap or not the performance by 99%
 */
function getResultPerformance(result: number, bins: IHistogramBin[], maxCapped = true) {
  const maxCap = maxCapped ? Constants.MAX_SCORE_CAP_PERCENTAGE : 1;
  const threshold = getThresholdResult(bins);
  if (result <= threshold) {
    return Math.max(0, Math.min(maxCap, (result / Math.max(threshold, 1)) * 0.5));
  }
  const maxResult = getMaxResult(bins);
  return Math.max(0, Math.min(maxCap, ((result - threshold) / Math.max(maxResult - threshold, 1)) * 0.5 + 0.5));
}

/**
 * calculated the approximate result user needs to achieve to get given performance
 * @param performance performance for which we need to calculate the result
 * @param bins histogram bins
 */
function getResultFromPerformance(performance: number, bins: IHistogramBin[]) {
  const allUsersSum = getTotalUsers(bins);
  let usersWithPerformance = Math.round(allUsersSum * performance);
  let result = 0;
  let minBinResult = 0;
  let binRange;
  for (const value of bins) {
    binRange = value.maxResult - minBinResult;
    if (usersWithPerformance - value.numberUsers >= 0) {
      result += binRange;
      usersWithPerformance -= value.numberUsers;
    } else {
      const binPercentageUserAmount = usersWithPerformance / value.numberUsers;
      result += Math.round(binRange * binPercentageUserAmount);
      break;
    }
    minBinResult = value.maxResult;
  }
  return result;
}

/**
 * rounds number to two number after decimal point
 * @param num number to be rounded
 */
function roundDecimal(num: number) {
  return Math.round(num * 100) / 100;
}

/**
 * calculated performance (comparison) for given users score for given workout histogram
 * @param score users score
 * @param bins histogram bins
 */
export function calcComparison(score: number, bins: IHistogramBin[]) {
  if (!bins.length) {
    return 0;
  }
  const performance = 100 * getResultPerformance(score, bins, true); // get 100% scale
  // round to two digits after comma
  return roundDecimal(performance);
}

/**
 * generates fallback histogram used when no histogram is available
 * on the backend for given workout or not enough users are in it
 */
function createFallbackHistogram() {
  const binSize = Constants.FALLBACK_HISTOGRAM_MAX_SCORE / Constants.FALLBACK_HISTOGRAM_NUM_BINS;
  const bins: IHistogramBin[] = [];
  for (let i = 1; i <= Constants.FALLBACK_HISTOGRAM_NUM_BINS; i++) {
    const users = i > Constants.FALLBACK_HISTOGRAM_NUM_BINS / 2 ? Constants.FALLBACK_HISTOGRAM_NUM_BINS + 1 - i : i;
    const bin: IHistogramBin = {
      maxResult: roundDecimal(i * binSize),
      numberUsers: users * Constants.FALLBACK_HISTOGRAM_USER_MULTIPLIER,
    };
    bins.push(bin);
  }
  return bins;
}

/**
 * memoized value for the fallback histogram
 */
let fallbackHistogram: IHistogramBin[] | undefined;

/**
 * public interface function returns the fallback histogram
 */
export function getFallbackHistogram() {
  if (!fallbackHistogram) {
    fallbackHistogram = createFallbackHistogram();
  }
  return [...fallbackHistogram];
}

/**
 * calculated the goal for the given workout histogram for the user
 * @param bins histogram bins
 */
export function calcGoal(bins: IHistogramBin[]) {
  const result = getResultFromPerformance(Constants.PERFORMANCE_THRESHOLD, bins);
  return Math.round(result * Constants.GOAL_RATIO);
}

export function getResultNormalizedPerformance(result: number, bins: IHistogramBin[]) {
  return roundDecimal(getResultPerformance(result, bins, false) * Constants.NORMALIZED_MAX_SCORE);
}
