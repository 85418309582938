import * as React from 'react';

export interface ISectionTitleProps {
  className?: string;
}

const SectionTitle: React.FC<React.PropsWithChildren<ISectionTitleProps>> = ({ className = '', children }) => (
  <h1 className={`section-title ${className}`}>{children}</h1>
);

export default SectionTitle;
