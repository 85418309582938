import {
  TExchangeTokenFunction,
  TRedirectLoginFunction,
  TRedirectLogoutFunction,
  TUpdateSearchParamsFunction,
} from './types';

export function createRoutingService() {
  let getCurrentSearchParams: (() => URLSearchParams) | undefined = undefined;
  let exchangeAccessToken: TExchangeTokenFunction | undefined = undefined;
  let updateSearchParams: TUpdateSearchParamsFunction | undefined = undefined;
  let refreshToken: (() => void) | undefined = undefined;
  let redirectLogin: TRedirectLoginFunction | undefined = undefined;
  let redirectLogout: TRedirectLogoutFunction | undefined = undefined;

  const service = {
    init: (routingFunctions: {
      getCurrentSearchParams: () => URLSearchParams;
      exchangeAccessToken: TExchangeTokenFunction;
      updateSearchParams: TUpdateSearchParamsFunction;
      refreshToken: () => void;
      redirectLogin: TRedirectLoginFunction;
      redirectLogout: TRedirectLogoutFunction;
    }) => {
      getCurrentSearchParams = routingFunctions.getCurrentSearchParams;
      exchangeAccessToken = routingFunctions.exchangeAccessToken;
      updateSearchParams = routingFunctions.updateSearchParams;
      refreshToken = routingFunctions.refreshToken;
      redirectLogin = routingFunctions.redirectLogin;
      redirectLogout = routingFunctions.redirectLogout;
    },
    get getCurrentSearchParams() {
      if (!getCurrentSearchParams) {
        throw new Error('getCurrentSearchParams function is not initialized');
      }
      return getCurrentSearchParams;
    },
    get exchangeAccessToken() {
      if (!exchangeAccessToken) {
        throw new Error('exchangeAccessToken function is not initialized');
      }
      return exchangeAccessToken;
    },
    get updateSearchParams() {
      if (!updateSearchParams) {
        throw new Error('updateSearchParams function is not initialized');
      }
      return updateSearchParams;
    },
    get refreshToken() {
      if (!refreshToken) {
        throw new Error('refreshToken function is not initialized');
      }
      return refreshToken;
    },
    get redirectLogin() {
      if (!redirectLogin) {
        throw new Error('redirectLogin function is not initialized');
      }
      return redirectLogin;
    },
    get redirectLogout() {
      if (!redirectLogout) {
        throw new Error('redirectLogout function is not initialized');
      }
      return redirectLogout;
    },
  };
  return service;
}

const RoutingService = createRoutingService();
export default RoutingService;
