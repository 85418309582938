import { BUILD_ID_FALLBACK } from './constants';

function removeLastSlash(str = '') {
  return str.replace(/\/$/, '');
}
// App build ID
export const BuildID = process.env.NX_PUBLIC_BUILD_ID || BUILD_ID_FALLBACK;
// NN api url
if (!process.env.NX_PUBLIC_API_URL) {
  throw new Error('API URL is not specified');
}
export const ApiUrl = process.env.NX_PUBLIC_API_URL;
// api version
export const ApiAppVersion = process.env.NX_PUBLIC_API_VERSION || '3.0.0';
// base url to locales bucket on S3
export const LocalesBaseUrl = removeLastSlash(process.env.NX_PUBLIC_LOCALES_URL);
// general debug flag (used by i18next currently)
export const IsDebug = !!process.env.NX_PUBLIC_DEBUG;
export const UnityPath = removeLastSlash(process.env.NX_PUBLIC_UNITY_PATH);
export const UnityCanvasId = process.env.NX_PUBLIC_UNITY_ID || 'unity';
// separate unity debug flag
export const UnityDebug = !!process.env.NX_PUBLIC_UNITY_DEBUG || IsDebug;
export const AssetsBaseUrl = removeLastSlash(process.env.NX_PUBLIC_ASSETS_URL);
export const VideoBaseUrl = `${AssetsBaseUrl}/video`;
export const AuthBaseUrl = removeLastSlash(process.env.NX_PUBLIC_AUTH_URL);
export const LoginBaseUrl = `${AuthBaseUrl}/opxp/login/?theme=opxp`;
export const RegistrationBaseUrl = `${AuthBaseUrl}/opxp/signup/?theme=opxp`;
export const LogoutBaseUrl = `${AuthBaseUrl}/logout/?theme=opxp`;
export const RegistrationValidationUrl = `${AuthBaseUrl}/opxp/signup/validation/?theme=opxp`;
export const PrivacyPolicyUrl = `${AuthBaseUrl}/legals/dataprivacy/?theme=opxp`;
export const TrainingBaseUrl = removeLastSlash(process.env.NX_PUBLIC_OPXP_TRAINING_URL);
export const SubscribeBaseUrl = removeLastSlash(process.env.NX_PUBLIC_OPXP_SUBSCRIBE_URL);
export const BlogBaseUrl = removeLastSlash(process.env.NX_PUBLIC_BLOG_URL);
export const ShopBaseUrl = removeLastSlash(process.env.NX_PUBLIC_CHECKOUT_URL);
export const BasePath = process.env.NX_PUBLIC_BASE_PATH || '/';
export const MobileAppLink = process.env.NX_PUBLIC_MOBILE_APP_LINK || '/';
export const RemoteConfigOnboarding = process.env.NX_PUBLIC_REMOTE_CONFIG_ONBOARDING || 'web_onboarding';
export const RemoteConfigUnity = process.env.NX_PUBLIC_REMOTE_CONFIG_UNITY || 'unity_dev';
export const RemoteConfigBalancing =
  process.env.NX_PUBLIC_REMOTE_CONFIG_LED_THRESHOLD || 'dynamic_balancing_initial_thresholds';
export const RemoteConfigRecruitmentBanner =
  process.env.NX_PUBLIC_REMOTE_CONFIG_RECRUITMENT_BANNER || 'recruitment_banner_dev';
export const RemoteConfigTraining = process.env.NX_PUBLIC_REMOTE_CONFIG_TRAINING || 'training_config_dev';
export const RemoteConfigSubscribe = process.env.NX_PUBLIC_REMOTE_CONFIG_SUBSCRIBE || 'productweb_dev';
export const RemoteConfigDomainCalculation =
  process.env.NX_PUBLIC_REMOTE_CONFIG_DOMAIN_CALCULATION || 'domain_calculation_config_dev';
export const UserAppPath = removeLastSlash(process.env.NX_PUBLIC_OPXP_USER_URL);
export const RemoteConfigRevenueModel = process.env.NX_PUBLIC_REMOTE_CONFIG_REVENUE_MODEL || 'revenue_model_config_dev';

export const RemoteConfigWorkoutPopularity =
  process.env.NX_PUBLIC_REMOTE_CONFIG_WORKOUT_POPULARITY || 'workout_popularity_config_dev';
export const RemoteConfigOnboardingMascot =
  process.env.NX_PUBLIC_REMOTE_CONFIG_ONBOARDING_MASCOT || 'onboarding_mascot_dev';
export const RemoteConfigMessageExperiment =
  process.env.NX_PUBLIC_REMOTE_CONFIG_MESSAGE_EXPERIMENT || 'message_experiment_dev';
export const RemoteConfigMedPatientsBanner =
  process.env.NX_PUBLIC_REMOTE_CONFIG_MED_PATIENTS_BANNER || 'med_patients_banner_dev';
export const RemoteConfigCorporateProducts =
  process.env.NX_PUBLIC_REMOTE_CONFIG_CORP_PRODUCTS || 'productweb_corporate_dev';
// Vapid
export const VapId = process.env.NX_PUBLIC_FIREBASE_VAPID_TOKEN || '';

// Firebase config object
let FirebaseMainConfig;
if (process.env.NODE_ENV === 'test') {
  FirebaseMainConfig = {
    apiKey: 'mock-api-key',
    authDomain: 'mock-auth-domain',
    databaseURL: 'mock-database-url',
    projectId: 'mock-project-id',
    storageBucket: 'mock-storage-bucket',
    messagingSenderId: 'mock-messaging-sender-id',
    appId: 'mock-app-id',
    measurementId: 'mock-measurement-id',
  };
} else {
  FirebaseMainConfig = {
    apiKey: process.env.NX_PUBLIC_FIREBASE_API_KEY || '',
    authDomain: process.env.NX_PUBLIC_FIREBASE_DOMAIN,
    databaseURL: process.env.NX_PUBLIC_FIREBASE_URL,
    projectId: process.env.NX_PUBLIC_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.NX_PUBLIC_FIREBASE_BUCKET,
    messagingSenderId: process.env.NX_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.NX_PUBLIC_FIREBASE_APP_ID || '',
    measurementId: process.env.NX_PUBLIC_FIREBASE_MEASUREMENT_ID || '',
  };
}

export const FirebaseConfig = FirebaseMainConfig;
