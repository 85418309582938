import { EDomain } from '../../common/types';
import { IDomainCalculation } from './types';

export function copyCycleEvaluation(cycleId: number, evaluation: IDomainCalculation[]) {
  // create copy of calculation with given cycle id
  return evaluation.map((progress) => ({ ...progress, cycleId }));
}

export function calculateDomainValues(
  cycleId: number,
  performanceByAssessment: Map<number, number>,
  domainWeights: Map<
    EDomain,
    {
      domainId: EDomain;
      workoutId: number;
      weight: number;
    }[]
  >,
  timestamp?: number
) {
  const domainScores: IDomainCalculation[] = [];
  let resultPerformance: number;
  for (const [domainId, domainWeight] of domainWeights.entries()) {
    let sumOfWeights = 0;
    let domainScore = 0;
    for (const domainWorkoutWeight of domainWeight) {
      if (performanceByAssessment.has(domainWorkoutWeight.workoutId)) {
        sumOfWeights += domainWorkoutWeight.weight;
      }
    }
    if (sumOfWeights > 0) {
      for (const domainWorkoutWeight of domainWeight) {
        resultPerformance = performanceByAssessment.get(domainWorkoutWeight.workoutId) || 0;
        domainScore += (resultPerformance * domainWorkoutWeight.weight) / sumOfWeights;
      }
    }
    domainScores.push({
      cycleId,
      domainId: +domainId,
      value: Math.round(domainScore),
      timestamp: timestamp ? timestamp : -1,
    });
    console.log(`Domain score for domain ${EDomain[+domainId]}: ${domainScore}`);
  }
  return domainScores;
}
