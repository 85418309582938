import * as React from 'react';
import { Header2 } from '../header';
import { renderMenuItem } from './menu';
import { IMenuNavItem } from './types';

export interface IAppHeaderProps {
  logoLabel: string;
  logoLink: string | undefined;
  logoSrc: string;
  showMenu?: boolean;
  menuItems: IMenuNavItem[];
}

export const AppHeader: React.FC<IAppHeaderProps> = ({ logoLabel, logoLink, logoSrc, showMenu = true, menuItems }) => {
  const navItems = showMenu ? menuItems.map((i, index) => renderMenuItem(i, index)) : null;
  return (
    <Header2 slim={true} logoLabel={logoLabel} logoLink={logoLink} logoSrc={logoSrc}>
      {navItems}
    </Header2>
  );
};

export default AppHeader;
