import compact from 'lodash/compact';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import forEachRight from 'lodash/forEachRight';
import groupBy from 'lodash/groupBy';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import sortedUniqBy from 'lodash/sortedUniqBy';
import takeRight from 'lodash/takeRight';
import { WorkoutUnityResult, WorkoutUserAction } from 'web_unity_engine';
import { getUnixTimestamp } from '../../common/dateUtils';
import {
  NUMBER_OF_TENDENCY_LAST_GAMEPLAYS,
  PROGRESS_HISTORY_LOADING_LIMIT,
  TRAINING_ID,
  WHICH_BEST_RESULT,
} from './constants';
import { IBaseResult, ILocalResult, IResult } from './types';

export function isFullProgressPage(progress: IBaseResult[]) {
  return progress.length === PROGRESS_HISTORY_LOADING_LIMIT;
}

export function sortProgress<T extends IResult>(results: (T | undefined | null)[] | undefined): T[] {
  return sortBy(compact(results), 'timestamp');
}

export function getLastProgressEntry<T extends IResult>(results: (T | undefined | null)[] | undefined, last = 1) {
  const sorted = sortProgress(results);
  if (sorted.length < last) {
    return;
  }
  return sorted[sorted.length - last];
}

export function getLastProgressTimestamp<T extends IResult>(results: T[] | undefined) {
  return getLastProgressEntry(results)?.timestamp ?? 0;
}

function convertProgressFromApi(result: IBaseResult) {
  const converted: ILocalResult = {
    ...result,
    syncTimestamp: getUnixTimestamp(),
  };
  return converted;
}

export function parseProgress(fetchedData: IBaseResult[]) {
  return fetchedData.map(convertProgressFromApi);
}

export const filterBySyncTimestamp = (progress?: ILocalResult[]) => filter(progress, ['syncTimestamp', 0]);

export function mergeProgressFromApiToLocal(fetchedData: IBaseResult[], existingData: ILocalResult[]): ILocalResult[] {
  const mergedProgress = sortProgress([...existingData, ...parseProgress(fetchedData)]);
  // exclude progress with same timestamps
  return sortedUniqBy(mergedProgress, 'timestamp');
}

/**
 * function converts data received from unity to the format currently used for saving to backend
 * @param {string} configVariant current configuration variant
 * @param {number} configVersion current configuration version
 * @param {number} cycleId Id of the current cycle
 * @param {number} sessionId Id of the current session (> 0 for non-assessment sessions)
 * @param {number} workoutId Id of the workout
 * @param {WorkoutUnityResult} unityResult result data send by Unity
 */
export const resultBuilder = (
  configVariant: string,
  configVersion: number,
  cycleId: number,
  sessionId: number,
  workoutId: number,
  unityResult: WorkoutUnityResult
): ILocalResult => {
  const { stats, trials } = unityResult;
  const {
    score,
    startDifficulty,
    endDifficulty,
    meanDifficulty,
    minDifficulty,
    maxDifficulty,
    reactionTimeGoodRunAverage,
    reactionTimeBadRunAverage,
  } = stats;
  return {
    configVersion,
    configVariant,
    trainingId: TRAINING_ID,
    sessionId,
    cycleId,
    workoutId,
    timestamp: getUnixTimestamp(),
    localTs: getUnixTimestamp(),
    result: score,
    gameHistory: '',
    startDifficulty,
    endDifficulty,
    meanDifficulty,
    minDifficulty,
    maxDifficulty,
    avgBadReactionTime: reactionTimeBadRunAverage,
    avgGoodReactionTime: reactionTimeGoodRunAverage,
    trials,
    // mark object as unsynchronized
    syncTimestamp: 0,
  };
};

/**
 * function creates proper result format currently used for saving to backend from given score and relevant data
 * @param {string} configVariant current configuration variant
 * @param {number} configVersion current configuration version
 * @param {number} cycleId Id of the current cycle
 * @param {number} sessionId Id of the current session (> 0 for non-assessment sessions)
 * @param {number} workoutId Id of the workout
 * @param {number} score score number generated from answer
 */
export const scoreBuilder = (
  configVariant: string,
  configVersion: number,
  cycleId: number,
  sessionId: number,
  workoutId: number,
  score: number
): ILocalResult => {
  return {
    configVersion,
    configVariant,
    trainingId: TRAINING_ID,
    sessionId,
    cycleId,
    workoutId,
    timestamp: getUnixTimestamp(),
    localTs: getUnixTimestamp(),
    result: score,
    gameHistory: '',
    startDifficulty: 0,
    endDifficulty: 0,
    meanDifficulty: 0,
    minDifficulty: 0,
    maxDifficulty: 0,
    avgBadReactionTime: 0,
    avgGoodReactionTime: 0,
    trials: [],
    // mark object as unsynchronized
    syncTimestamp: 0,
  };
};

/**
 * this function makes sure that we have a unique timestamp
 * if it meets a same timestamp it shifts all previous timestamps by one second
 */
export function ensureUniqueTimestamp<T extends IBaseResult>(progress: T[]) {
  const sortedResults = sortProgress(progress);
  let previousTimestamp = 0;
  const parsedResults: T[] = [];
  forEachRight(sortedResults, (result) => {
    const parsedResult: T = {
      ...result,
    };
    if (parsedResult.timestamp >= previousTimestamp) {
      parsedResult.timestamp = previousTimestamp - 1;
      parsedResult.localTs = previousTimestamp - 1;
    }
    previousTimestamp = parsedResult.timestamp;
    parsedResults.unshift(parsedResult);
  });
  return parsedResults;
}

function convertToApiResult(localResult: ILocalResult) {
  const now = getUnixTimestamp();
  const {
    configVersion,
    configVariant,
    trainingId,
    sessionId,
    cycleId,
    workoutId,
    timestamp,
    localTs,
    result,
    gameHistory,
    startDifficulty,
    endDifficulty,
    meanDifficulty,
    minDifficulty,
    maxDifficulty,
    avgBadReactionTime,
    avgGoodReactionTime,
    trials,
  } = localResult;
  // omit syncTimestamp
  const apiProgress: IBaseResult = {
    configVersion,
    configVariant,
    trainingId,
    sessionId,
    cycleId,
    workoutId,
    // calculate difference between creation date and current timestamp
    // so that its not dependent on users timezone
    timestamp: Math.min(timestamp - now, -1),
    localTs: Math.min(localTs - now, -1),
    result,
    gameHistory,
    startDifficulty,
    endDifficulty,
    meanDifficulty,
    minDifficulty,
    maxDifficulty,
    avgBadReactionTime,
    avgGoodReactionTime,
    trials,
  };
  return apiProgress;
}

export function prepareProgressForSaving(progress: ILocalResult[]) {
  return ensureUniqueTimestamp(progress.map(convertToApiResult));
}

export function markResultAsSynchronized(result: IBaseResult): ILocalResult {
  return {
    ...result,
    syncTimestamp: getUnixTimestamp(),
  };
}

export function markDataAsSynchronized(progress: ILocalResult[], savedResults: ILocalResult[]) {
  const savedTimestamps = savedResults.map((result) => result.timestamp);
  return progress.map((cacheItem) => {
    if (savedTimestamps.includes(cacheItem.timestamp)) {
      // set syncTimestamp for saved item
      return markResultAsSynchronized(cacheItem);
    }
    // keep other items untouched
    return cacheItem;
  });
}

export function filterProgressByWorkout<T extends IBaseResult>(progress: T[] | undefined, workoutId: number) {
  return filter(progress, ['workoutId', workoutId]);
}

export function filterProgressByTimestamp<T extends IResult>(
  progress: T[] | undefined,
  startTimestamp: number,
  endTimestamp: number
) {
  return filter(
    progress,
    (progressItem) => progressItem.timestamp <= endTimestamp && progressItem.timestamp >= startTimestamp
  );
}

export const getRangeId = (startTimestamp: number, endTimestamp: number) => {
  return String(startTimestamp) + '-' + String(endTimestamp);
};

export const findWorkoutProgress = <T extends IBaseResult>(progress: T[], workoutId: number) =>
  find(progress, ['workoutId', workoutId]);

export function groupProgressByWorkout(progress: IBaseResult[]) {
  return groupBy(progress, 'workoutId');
}

export function mergeWorkoutsProgress<T extends IResult>(workoutsProgress: ((T | undefined | null)[] | undefined)[]) {
  const filteredWorkoutsProgressHistory = compact(workoutsProgress);
  const mergedProgress = filteredWorkoutsProgressHistory.reduce((acc, current) => acc.concat(current), []);
  const sortedProgress = sortProgress(mergedProgress);
  // exclude progress with same timestamps
  return sortedUniqBy(sortedProgress, 'timestamp');
}

export function getMaxResult<T extends IResult>(progress: T[]) {
  return maxBy(progress, 'result');
}

export const findProgressForCycleAndSession = (
  progress: IBaseResult[],
  workoutId: number,
  sessionId: number,
  cycleId: number
) => filter(progress, { workoutId, cycleId, sessionId });

export function cleanupProgress<T extends IResult>(history: (T | null)[]) {
  return compact(history);
}

function createLinearFunction(a0: number, a1: number) {
  return (x: number) => a0 + a1 * x;
}

function createLinearRegression(points: number[]) {
  const amountOfPoints = points.length;
  if (amountOfPoints < 1) {
    // no way to calculate when no points
    return createLinearFunction(0, 0);
  }
  if (amountOfPoints === 1) {
    // for 1 point - horizontal line (constant value)
    return createLinearFunction(points[0], 0);
  }
  let sumX, sumY, sumXX, sumXY;
  sumX = sumY = sumXX = sumXY = 0;
  for (let i = 0; i < points.length; i++) {
    const x = i;
    const y = points[i];
    sumX += x;
    sumY += y;
    sumXX += x * x;
    sumXY += x * y;
  }
  const a1numerator = amountOfPoints * sumXY - sumX * sumY;
  const a0numerator = sumY * sumXX - sumX * sumXY;
  const denominator = amountOfPoints * sumXX - sumX * sumX;
  const a1 = a1numerator / denominator;
  const a0 = a0numerator / denominator;
  return createLinearFunction(a0, a1);
}

function predictResult(scores: number[]) {
  const linearFunction = createLinearRegression(scores);
  return linearFunction(scores.length);
}

export function calculateWorkoutGoal(results: IResult[]) {
  // make sure progress is sorted by timestamp
  const sortedResults = sortProgress(results);
  // extract scores from result
  const scores = sortedResults.map((result) => result.result);
  // take last gameplays and extract scores
  const tendencyLength = Math.min(scores.length, NUMBER_OF_TENDENCY_LAST_GAMEPLAYS);
  const tendencyScores = takeRight(scores, tendencyLength);
  const predictedResult = predictResult(tendencyScores);
  // calculate 'best result'
  const bestLength = Math.min(scores.length, WHICH_BEST_RESULT);
  const bestResults = takeRight(sortBy(scores), bestLength);
  // take least result from selected best scores
  const bestResult = bestResults.length === 0 ? 0 : bestResults[0];
  return Math.round(Math.max(predictedResult, bestResult));
}

function filterGoodRuns(trials: WorkoutUserAction[]) {
  return filter(trials, (t) => t.answer > 0);
}

function filterBadRuns(trials: WorkoutUserAction[]) {
  return filter(trials, (t) => t.answer < 0);
}

export function calculateAccuracy<T extends IBaseResult>(result: T) {
  const trials = result.trials;
  if (!trials) {
    return 0;
  }
  const actions = flatten(trials);
  const total = actions.length;
  const goodReactions = filterGoodRuns(actions);
  return goodReactions.length / total;
}

export function calculateGoodRuns(result: IBaseResult) {
  const trials = result.trials;
  if (!trials) {
    return 0;
  }
  const actions = flatten(trials);
  const goodReactions = filterGoodRuns(actions);
  return goodReactions.length;
}

export function calculateBadRuns(result: IBaseResult) {
  const trials = result.trials;
  if (!trials) {
    return 0;
  }
  const actions = flatten(trials);
  const goodReactions = filterBadRuns(actions);
  return goodReactions.length;
}

export const filterByStartDifficulty = <T extends IBaseResult>(progress: T[], difficulty: number) =>
  filter(progress, (value) => value.startDifficulty >= difficulty);

export const filterByDay = <T extends IBaseResult>(progress: T[], date: Date) =>
  filter(progress, (value) => {
    const progressDate = new Date(value.timestamp * 1000);
    return (
      progressDate.getDate() === date.getDate() &&
      progressDate.getMonth() === date.getMonth() &&
      progressDate.getFullYear() === date.getFullYear()
    );
  });

export const filterByNonAssessment = <T extends IBaseResult>(progress: T[] | undefined | null) =>
  filter(progress, (value) => value.sessionId !== 0);
