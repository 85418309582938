import { CacheService } from 'web_core_library';
import { ConfigError, IVersionCache, IWorkoutConfigMap } from './types';

let _userId = 0;
let workoutsCacheId: string;
let versionCacheId: string;

async function init(userId: number) {
  _userId = userId;
  workoutsCacheId = `${_userId}-config-workouts`;
  versionCacheId = `${_userId}-config-version`;
  await CacheService.init();
}

async function getWorkouts() {
  try {
    if (!_userId) {
      throw new ConfigError('ConfigCache requires userId to be set!');
    }
    return await CacheService.load<IWorkoutConfigMap>(workoutsCacheId);
  } catch (error) {
    throw new ConfigError('Workouts ConfigCache loading failed');
  }
}

async function setWorkouts(config: IWorkoutConfigMap) {
  try {
    if (!_userId) {
      throw new ConfigError('ConfigCache requires userId to be set!');
    }
    return await CacheService.save(workoutsCacheId, config);
  } catch (error) {
    throw new ConfigError('Workouts ConfigCache saving failed');
  }
}

async function getVersion() {
  try {
    if (!_userId) {
      throw new ConfigError('ConfigCache requires userId to be set!');
    }
    return await CacheService.load<IVersionCache>(versionCacheId);
  } catch (error) {
    throw new ConfigError('Workouts ConfigCache loading failed');
  }
}

async function setVersion(version: IVersionCache) {
  try {
    if (!_userId) {
      throw new ConfigError('ConfigCache requires userId to be set!');
    }
    return await CacheService.save(versionCacheId, version);
  } catch (error) {
    throw new ConfigError('Workouts ConfigCache saving failed');
  }
}

const ConfigCacheService = {
  init,
  getWorkouts,
  setWorkouts,
  getVersion,
  setVersion,
};
export default ConfigCacheService;
