import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ELanguage } from '.';
import service from './service';
import { LanguageActions } from './slice';

export function* changeLanguageSaga(action: PayloadAction<ELanguage>) {
  const language = action.payload;
  try {
    yield call(service.changeLanguage, language);
  } catch (error) {
    yield put(LanguageActions.languageFail(error as Error));
  }
}

export default function* languageSagaWatcher() {
  yield all([takeEvery(LanguageActions.changeLanguage, changeLanguageSaga)]);
}
