import * as React from 'react';

import './Checkbox.scss';

export enum ECheckboxStyle {
  Light = 'nn-light',
  Dark = 'nn-dark',
}

export interface ICheckboxProps {
  id: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  color?: ECheckboxStyle;
  hidePlaceholder?: boolean;
}

const Checkbox: React.FC<React.PropsWithChildren<ICheckboxProps>> = ({
  id,
  checked,
  disabled,
  onChange,
  children,
  className,
  color = ECheckboxStyle.Dark,
  hidePlaceholder,
}) => {
  const [checkedValue, toggleChecked] = React.useState<boolean>(checked);
  React.useEffect(() => {
    toggleChecked(checked);
  }, [checkedValue, checked]);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.checked;
    toggleChecked(value);
    onChange(value);
  };
  return (
    <div className={className}>
      <input
        id={id}
        type="checkbox"
        className={`filled-in ${color}`}
        disabled={disabled}
        onChange={handleChange}
        checked={checkedValue}
      />
      <label htmlFor={id}>
        {!hidePlaceholder && <span className="checkbox-placeholder transparent">&nbsp;</span>}
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
