import { Classes, Dialog } from '@blueprintjs/core';
import * as React from 'react';

import './TrustBanner.scss';

export interface ITrustBannerItem {
  label: string;
  src: string;
}

export interface ITrustBannerProps {
  leftTitle: string;
  leftItems: ITrustBannerItem[];
  rightTitle: string;
  rightItems: ITrustBannerItem[];
  popupTitle: string;
}

const TrustBanner: React.FC<ITrustBannerProps> = ({ leftTitle, leftItems, rightTitle, rightItems, popupTitle }) => {
  const [isDialogOpen, setDialogOpenState] = React.useState(false);
  const handleDialogClose = () => setDialogOpenState(false);
  const handleDialogOpen = () => setDialogOpenState(true);
  return (
    <div className="trust-banner">
      <div onClick={handleDialogOpen} className="left clickable">
        <p>{leftTitle}</p>
        <div className="trust-items">
          {leftItems.map((bannerItem) => (
            <img key={`${bannerItem.label}-${bannerItem.src}`} alt={bannerItem.label} src={bannerItem.src} />
          ))}
        </div>
      </div>
      <div className="gap"></div>
      <div className="right">
        <p>{rightTitle}</p>
        <div className="trust-items">
          {rightItems.map((bannerItem) => (
            <img key={`${bannerItem.label}-${bannerItem.src}`} alt={bannerItem.label} src={bannerItem.src} />
          ))}
        </div>
      </div>
      <Dialog className="trust-banner-dialog" onClose={handleDialogClose} title={popupTitle} isOpen={isDialogOpen}>
        <div className={`${Classes.DIALOG_BODY} trust-banner-dialog-body`}>
          <img src="https://cms.cdn.neuronation.com/assets/edison/trust/aok_certificate.png" alt={popupTitle} />
        </div>
      </Dialog>
    </div>
  );
};

export default TrustBanner;
