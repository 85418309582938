function getDomain() {
  const hostname = window.location.hostname;
  const splitHostname = hostname.split('.');
  const prefix = splitHostname.length > 1 ? '.' : '';
  return prefix + splitHostname.slice(splitHostname.length - 2).join('.');
}

function getCookie(cName: string) {
  const name = cName + '=';
  // get the full cookies string
  const decodedCookie = decodeURIComponent(document.cookie);
  // split into single cookies strings
  const cookies = decodedCookie.split(';');
  // search for cookie with given name
  for (const cookie of cookies) {
    // trim whitespaces from start
    const trimmedCookie = cookie.trimStart();
    if (trimmedCookie.startsWith(name)) {
      return trimmedCookie.substring(name.length, trimmedCookie.length);
    }
  }
  return '';
}

function setCookie(cName: string, cValue: string, expiration: Date | number = 365) {
  let expiresAt: Date;
  if (expiration instanceof Date) {
    expiresAt = expiration;
  } else {
    expiresAt = new Date(Date.now());
    expiresAt.setTime(expiresAt.getTime() + expiration * 24 * 60 * 60 * 1000);
  }
  const expires = 'expires=' + expiresAt.toUTCString();
  const cDomain = `; domain=${getDomain()}`;
  const cPolicy = '; SameSite=Lax';
  const newCookie = cName + '=' + encodeURIComponent(cValue) + '; ' + expires + '; path=/' + cDomain + cPolicy;
  document.cookie = newCookie;
  return newCookie;
}

function deleteCookie(cName: string) {
  const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC';
  const cDomain = `; domain=${getDomain()}`;
  const cPolicy = '; SameSite=Lax';
  const newCookie = cName + '=; ' + expires + '; path=/' + cDomain + cPolicy;
  document.cookie = newCookie;
}

const COOKIE_AUTH_SESSION = 'nn_auth_session';
const COOKIE_AUTH_USER = 'nn_auth_user';
const COOKIE_AUTH_LOGOUT = 'nn_auth_logout';
const COOKIE_AUTH_TOKEN = 'nn_auth_token';

const CookieManager = {
  COOKIE_AUTH_LOGOUT,
  COOKIE_AUTH_SESSION,
  COOKIE_AUTH_USER,
  COOKIE_AUTH_TOKEN,
  deleteCookie,
  getCookie,
  setCookie,
};

export default CookieManager;
