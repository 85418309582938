import { i18n, TFunction } from 'i18next';

export enum ELanguage {
  German = 'de',
  English = 'en',
  French = 'fr',
  Russian = 'ru',
  Japanese = 'ja',
  Spanish = 'es',
  Italian = 'it',
  Polish = 'pl',
}

export interface ILanguageStore {
  t: TFunction;
  language?: ELanguage;
  i18n: i18n;
}

export type TStoreListener = (store: ILanguageStore) => void;

export type { TFunction } from 'i18next';

export interface HtmlTagRule {
  tag: string;
  allowed_attributes?: string[];
}
