import { SharedErrors } from 'web_core_library';

export class AuthError extends SharedErrors.BaseError {
  constructor(message: string) {
    super(message);
    this.constructor = AuthError;
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, AuthError.prototype);
    this.feature = 'Auth';
    // redirect to sso by default
    this.errorType = 'sso';
  }
}

export class NotVerfiedError extends AuthError {
  constructor(message: string) {
    super(message);
    // show auth error page for not verified case
    this.errorType = 'page';
    this.constructor = NotVerfiedError;
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, NotVerfiedError.prototype);
  }
}

export interface IRoleData {
  id: number;
  name: string;
  expiresAt?: string;
}

export interface IUser {
  userId: number;
  name: string;
  mail?: string;
  picture?: string;
  username?: string;
  language?: string;
  created?: number;
  roles?: IRoleData[];
}

export interface IUnreadMessages {
  regular: number;
  friendship: number;
  system?: number;
}

// Routing types
export type TExchangeTokenFunction = (accessToken: string) => void;

export type TUpdateSearchParamsFunction = (newSearch: URLSearchParams) => void;

export type TRedirectInternalFunction = (url: string, state?: object) => void;

export type TRedirectLoginFunction = (backUrlPath?: string) => void;

export type TRedirectLogoutFunction = (backUrlPath?: string) => void;

export interface IRevalidateToken {
  result: {
    sessionToken: string;
  };
}
