import { isProduction } from './utils';

export enum EErrorLevel {
  Error = 'error',
  Info = 'info',
  Log = 'log',
  Warning = 'warn',
}

function logger(data: unknown, level: EErrorLevel = EErrorLevel.Info) {
  const CI = process.env.CI;
  const isDebug = !!process.env.NX_PUBLIC_DEBUG;
  if ((isProduction() || CI) && !isDebug) {
    return;
  }
  let logFunc = console.log;
  switch (level) {
    case EErrorLevel.Info:
      logFunc = console.info;
      break;
    case EErrorLevel.Warning:
      logFunc = console.warn;
      break;
    case EErrorLevel.Error:
      logFunc = console.error;
      break;
  }
  if (Array.isArray(data)) {
    logFunc(...data);
  } else {
    logFunc(data);
  }
}

export default logger;
