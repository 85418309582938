import * as Reducer from './reducer';

export interface IAppState {
  // this feature state
  [Reducer.statePropName]: Reducer.IFirebaseState;
  // other features we don't care of
  [otherKeys: string]: any;
}

const getState = (state: IAppState) => state[Reducer.statePropName];

export const isInitialized = (state: IAppState) => getState(state).initDone;

export const isReady = (state: IAppState) => getState(state).ready;

export const isMessagingActive = (state: IAppState) => getState(state).messaging;

export const getMessages = (state: IAppState) => getState(state).messages;

// GDPR Selectors

export const getCks = (state: IAppState) => getState(state).utm.cks;

export const getTaboolaId = (state: IAppState) => getState(state).utm.taboola_click_id;

export const getOutbrainId = (state: IAppState) => getState(state).utm.ob_click_id;

export const getFirebaseConsent = (state: IAppState) => getState(state).firebase;

export const getUserDataConsent = (state: IAppState) => !!getState(state).userData;

export const isSettingsOpen = (state: IAppState) => getState(state).settings;

export const isConsentGiven = (state: IAppState) => getState(state).submitted;

export const getBannerMode = (state: IAppState) => getState(state).mode;

export const getSessionId = (state: IAppState) => getState(state).utm.sessionId;

// The following selector might not be used. It can be deleted if not used.
// export const getUtmParameters = (state: IAppState) => getState(state).utm;
