import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ApiService } from 'web_core_library';
import AssessmentEvaluationService from './service';
import { AssessmentEvaluationActions, ISaveEvaluationPayload } from './slice';
import { copyCycleEvaluation } from './utils';

export function* initFeatureSaga() {
  yield call(AssessmentEvaluationService.init, ApiService);
  yield put(AssessmentEvaluationActions.assessmentEvaluationReady());
}

export function* saveCycleEvaluationSaga({
  payload: { cycleId, domaincalculation },
}: PayloadAction<ISaveEvaluationPayload>) {
  const evaluation: ReturnType<typeof copyCycleEvaluation> = yield call(
    copyCycleEvaluation,
    cycleId,
    domaincalculation
  );
  yield call(AssessmentEvaluationService.saveCycleEvaluation, evaluation);
  yield put(AssessmentEvaluationActions.cycleEvaluationSaved());
}

export default function* assessmentEvaluationWatcherSaga() {
  yield all([
    takeEvery(AssessmentEvaluationActions.initAssessmentEvaluation, initFeatureSaga),
    takeEvery(AssessmentEvaluationActions.saveCycleEvaluationAction, saveCycleEvaluationSaga),
  ]);
}
