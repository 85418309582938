import React from 'react';
import './Gauge.scss';
import Lottie from './Lottie';
import Speedometer from './speedometer.json';

export interface IGaugeProps {
  amount: number;
  id?: string;
  minLabel: string;
  maxLabel: string;
}

export const Gauge: React.FC<IGaugeProps> = ({ amount, minLabel, maxLabel }) => {
  return (
    <div className="gauge">
      <Lottie className="gauge-speedometer" name="speedometer" stopOn={amount} animationData={Speedometer} />
      <div className="gauge-labels">
        <label className="gauge-label">{minLabel}</label>
        <label className="gauge-label">{maxLabel}</label>
      </div>
    </div>
  );
};

export default Gauge;
