import { CacheService } from 'web_core_library';
import { CACHE_TIMEOUT_MS } from './constants';
import * as Types from './types';

export function getService(service: typeof CacheService = CacheService) {
  let _userId = 0;
  let _fetchCacheKey = '';

  const init = async (userId: number) => {
    _userId = userId;
    _fetchCacheKey = `${_userId}-stats`;
    await service.init();
  };

  const clean = () => {
    return service.delete(_fetchCacheKey);
  };

  const isReady = () => {
    return _userId !== 0;
  };

  /**
   * loading stats from cache
   */
  const getStats = async () => {
    if (!isReady()) {
      throw new Types.StatsCacheError('Trying to load from cache before initializing!');
    }
    const cacheValue = await service.load<Types.IUserStatGetCache>(_fetchCacheKey);
    // check for expired cache right here instead of saga
    if (isExpired(cacheValue)) {
      return;
    }
    return cacheValue;
  };

  /**
   * storing stats to the cache
   * @param stats stats data to store in cache
   */
  const setStats = (stats: Types.IUserStatGet[], timestamp: number = Date.now()) => {
    if (!isReady()) {
      throw new Types.StatsCacheError('Trying to save to cache before initializing!');
    }
    const cacheValue: Types.IUserStatGetCache = {
      stats,
      timestamp,
    };
    return service.save(_fetchCacheKey, cacheValue);
  };

  const isExpired = (cacheValue?: Types.IUserStatGetCache) => {
    if (!cacheValue) {
      return true;
    }
    return Date.now() - cacheValue.timestamp >= CACHE_TIMEOUT_MS;
  };

  return {
    init,
    clean,
    isReady,
    getStats,
    setStats,
    isExpired,
  };
}

export type IStatsCacheService = ReturnType<typeof getService>;

const StatsCache = getService();
export default StatsCache;
