import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStatsPayload, IStatsQueuePayload, IUpdatePeerGroupsPayload, IUserPeerGroup, IUserStat } from './types';
import { parseGenericStat, parseStringStat, removeStatsFromQueue } from './utils';

export interface IStatsState {
  // loading flag
  loading: boolean;
  // array of all user stats
  stats: IUserStat[];
  // array of stats to be saved to backend
  statsQueueToSave: IUserStat[];
  // array of all peer groups
  peergroups: IUserPeerGroup[];
  // saving flag
  saving: boolean;
  // state for custom user
  users: {
    [userId: number]: {
      stats?: IUserStat[];
      statsQueueToSave?: IUserStat[];
      peergroups?: IUserPeerGroup[];
    };
  };
}

export const initialState: IStatsState = {
  loading: true,
  stats: [],
  statsQueueToSave: [],
  peergroups: [],
  saving: false,
  users: {},
};

export const statePropName = 'stats';

const statsQueueActionType = `${statePropName}/addStatsQueue`;

const statsSlice = createSlice({
  name: statePropName,
  initialState,
  reducers: {
    initStats: (state: IStatsState, action: PayloadAction<number>) => {
      // Handle initialization if needed
    },
    loadStats: {
      reducer: (state: IStatsState, action: PayloadAction<number | undefined>) => {
        state.loading = true;
      },
      prepare: (userId?: number) => {
        return {
          payload: userId ?? undefined,
        };
      },
    },
    statsReady: (state: IStatsState) => {
      state.loading = false;
    },
    updateStats: {
      reducer: (state: IStatsState, action: PayloadAction<IStatsPayload>) => {
        const { stats, userId } = action.payload;
        if (!userId) {
          // if user not defined we keep default behavior
          return {
            ...state,
            stats,
          };
        }
        // if user is defined we update custom users state
        const usersState = state.users[userId] || {};
        const udpatedUsersState = {
          ...usersState,
          stats,
        };
        return {
          ...state,
          users: {
            ...state.users,
            [userId]: udpatedUsersState,
          },
        };
      },
      prepare: (stats: IUserStat[], userId?: number) => {
        return { payload: { stats, userId } };
      },
    },
    saveStatsQueue: (state: IStatsState, action: PayloadAction<number | undefined>) => {
      state.saving = true;
    },
    saveStatsQueueFailed: (state: IStatsState, action: PayloadAction<string>) => {
      state.saving = false;
    },
    saveStatsQueueSuccess: {
      reducer: (state: IStatsState, action: PayloadAction<IStatsQueuePayload>) => {
        const { statsQueue, userId } = action.payload;
        if (!userId) {
          // keep same logic for default user
          const updatedQueue = removeStatsFromQueue(state.statsQueueToSave, statsQueue);
          return {
            ...state,
            saving: false,
            statsQueueToSave: updatedQueue,
          };
        }
        const usersState = state.users[userId] || {};
        const usersQueue = usersState.statsQueueToSave || [];
        const updatedQueue = removeStatsFromQueue(usersQueue, statsQueue);
        const udpatedUsersState = {
          ...usersState,
          statsQueueToSave: updatedQueue,
        };
        return {
          ...state,
          saving: false,
          users: {
            ...state.users,
            [userId]: udpatedUsersState,
          },
        };
      },
      prepare: (statsQueue: IUserStat[], userId?: number) => {
        return { payload: { statsQueue, userId } };
      },
    },
    addStatsQueue: {
      reducer: (state: IStatsState, action: PayloadAction<IStatsPayload>) => {},
      prepare: (stats: IUserStat[], userId?: number) => {
        return { payload: { stats, userId } };
      },
    },
    updateStatsQueue: {
      reducer: (state: IStatsState, action: PayloadAction<IStatsQueuePayload>) => {
        const { statsQueue, userId } = action.payload;
        if (!userId) {
          // keep default behavior for default user
          return {
            ...state,
            statsQueueToSave: statsQueue,
          };
        }
        const usersState = state.users[userId] || {};
        const udpatedUsersState = {
          ...usersState,
          statsQueueToSave: statsQueue,
        };
        return {
          ...state,
          users: {
            ...state.users,
            [userId]: udpatedUsersState,
          },
        };
      },
      prepare: (statsQueue: IUserStat[], userId?: number) => {
        return { payload: { statsQueue, userId } };
      },
    },
    loadPeerGroups: (state: IStatsState, action: PayloadAction<number | undefined>) => {},
    updatePeerGroups: {
      reducer: (state: IStatsState, action: PayloadAction<IUpdatePeerGroupsPayload>) => {
        const { peergroups, userId } = action.payload;
        if (!userId) {
          return {
            ...state,
            peergroups,
          };
        }
        const usersState = state.users[userId] || {};
        const udpatedUsersState = {
          ...usersState,
          peergroups,
        };
        return {
          ...state,
          users: {
            ...state.users,
            [userId]: udpatedUsersState,
          },
        };
      },
      prepare: (peergroups: IUserPeerGroup[], userId?: number) => {
        return { payload: { peergroups, userId } };
      },
    },
    saveAgeGroup: (state: IStatsState, action: PayloadAction<number>) => {},
  },
});

const updateGenericStat = createAction(
  statsQueueActionType,
  (statId: string, payload: string | Partial<IUserStat>, userId?: number) => {
    const stats: IUserStat[] = [parseGenericStat(statId, payload)];
    return { payload: { stats, userId } };
  }
);

const updateNumericStat = createAction(statsQueueActionType, (statId: string, payload: number, userId?: number) => {
  const stats: IUserStat[] = [parseGenericStat(statId, payload)];
  return { payload: { stats, userId } };
});

const updateBooleanStat = createAction(statsQueueActionType, (statId: string, payload: boolean, userId?: number) => {
  const stats: IUserStat[] = [parseGenericStat(statId, payload)];
  return { payload: { stats, userId } };
});

const updateStringStat = createAction(statsQueueActionType, (statId: string, payload: string, userId?: number) => {
  const stats: IUserStat[] = [parseStringStat(statId, payload)];
  return { payload: { stats, userId } };
});

export const StatsActions = {
  ...statsSlice.actions,
  updateGenericStat,
  updateNumericStat,
  updateBooleanStat,
  updateStringStat,
};
export default statsSlice.reducer;
