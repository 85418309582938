import * as React from 'react';

import './BannerSection.scss';

import Section from './Section';
import SectionSubtitle from './SectionSubtitle';
import SectionTitle from './SectionTitle';

export type TBannerSectionDirection = 'left' | 'right';

export interface IBannerSectionProps {
  direction: TBannerSectionDirection;
  title: string;
  // description is optional to render children instead of description
  // if more complicated content is needed
  description?: string;
  imgSrc: string;
  id?: string;
}

const BannerSection: React.FC<React.PropsWithChildren<IBannerSectionProps>> = ({
  children,
  direction,
  title,
  description,
  imgSrc,
  id,
}) => {
  const sectionDirectionClass = `banner-section__${direction}`;
  return (
    <Section id={id} className={`banner-section ${sectionDirectionClass}`}>
      <div className="row banner-section-row">
        <div className="col-5 col-m-4">
          <img className="banner-section-banner" alt={title} src={imgSrc} />
        </div>
        <div className="col-1 col-m-4" />
        <div className="col-6 col-m-4 banner-section-content">
          <SectionTitle>{title}</SectionTitle>
          {description ? <SectionSubtitle>{description}</SectionSubtitle> : null}
          {children}
        </div>
      </div>
    </Section>
  );
};

export default BannerSection;
