import { SharedErrors } from 'web_core_library';

export interface IHistogramBin {
  maxResult: number;
  numberUsers: number;
}

export interface IWorkoutHistogram {
  [workoutId: string]: IHistogramBin[];
}

export interface IHistogramApiResponse {
  trainingworkouthistogram: IWorkoutHistogram;
}

export interface IWorkoutHistogramCache {
  histogram: IHistogramBin[];
  groupId: number;
  syncTimestamp: number;
}

export class HistogramError extends SharedErrors.BaseError {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, HistogramError.prototype);
    this.feature = 'Histogram';
  }
}

export interface ILoadHistogramPayload {
  workoutIds: number[];
  groupId: number;
  userId?: number;
}

export interface IUpdateHistogramPayload {
  workoutId: number;
  histogram: IHistogramBin[];
  userId?: number;
}
