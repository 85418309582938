import { IApiService } from 'web_core_library';
import {
  AuthenticationResponseJSON,
  ELoginStep,
  IAuthResponsePayload,
  ILoginCredentialsResponsePayload,
  PublicKeyCredentialCreationOptionsJSON,
  TListOfUsersPasskeys,
  TSendRegistrationPayload,
  TSendUpgradePayload,
} from './types';

export function createCredentialsService() {
  let ApiService: IApiService;

  const getLoginCredentials = () => {
    return ApiService.action<ILoginCredentialsResponsePayload>('login_webauthn', {
      method: 'get',
      urlParams: {
        step: ELoginStep.Login,
      },
    });
  };

  const sendLoginCredentials = (cred: AuthenticationResponseJSON) => {
    return ApiService.action<IAuthResponsePayload>('login_webauthn', {
      method: 'post',
      urlParams: {
        step: ELoginStep.Login,
      },
      data: cred,
    });
  };

  const getRegistrationCredentials = (email: string) => {
    return ApiService.action<PublicKeyCredentialCreationOptionsJSON>('login_webauthn', {
      method: 'get',
      urlParams: {
        step: ELoginStep.Registration,
      },
      params: {
        email,
      },
    });
  };

  const sendRegistrationCredentials = (cred: TSendRegistrationPayload) => {
    return ApiService.action<IAuthResponsePayload>('login_webauthn', {
      method: 'post',
      urlParams: {
        step: ELoginStep.Registration,
      },
      data: cred,
    });
  };

  const getListOfPasskeys = () => {
    return ApiService.action<TListOfUsersPasskeys>('users_webauthn', {
      method: 'get',
      urlParams: {
        step: 'list',
        userId: ApiService.getUserId(),
      },
    });
  };

  const deleteUsersPasskey = (key: string) => {
    return ApiService.action<unknown>('users_webauthn_keys', {
      method: 'delete',
      urlParams: {
        step: 'key',
        userId: ApiService.getUserId(),
        key,
      },
    });
  };

  const requestUserUpgrade = () => {
    return ApiService.action<PublicKeyCredentialCreationOptionsJSON>('users_webauthn', {
      method: 'get',
      urlParams: {
        step: 'register',
        userId: ApiService.getUserId(),
      },
    });
  };

  const saveUserPasskeyUpgrade = (cred: TSendUpgradePayload) => {
    return ApiService.action<unknown>('users_webauthn', {
      method: 'post',
      urlParams: {
        step: 'register',
        userId: ApiService.getUserId(),
      },
      data: cred,
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    getLoginCredentials,
    sendLoginCredentials,
    getRegistrationCredentials,
    sendRegistrationCredentials,
    getListOfPasskeys,
    deleteUsersPasskey,
    requestUserUpgrade,
    saveUserPasskeyUpgrade,
  };

  return service;
}

const CredentialsService = createCredentialsService();
export default CredentialsService;
