import { findIndex } from 'lodash';
import differenceWith from 'lodash/differenceWith';
import { DEFAULT_STAT_POST_PROPS } from './constants';
import { IUserStat, IUserStatGet, IUserStatId, IUserStatPost } from './types';

export function parseUserStatIds(id: string) {
  const ids = id.split('-');
  if (ids.length !== 3) {
    throw new Error('Wrong user stat definition!');
  }
  const stat: IUserStatId = {
    statType: +ids[0],
    statId: +ids[1],
    statComponentId: +ids[2],
  };
  return stat;
}

export function convertIUserStats2IUserStatGets(userStats: IUserStat[]) {
  return userStats.map((userStat) => {
    return { lastEdit: -1, ...userStat } as IUserStatGet;
  });
}

export function convertIUserStats2IUserStatPosts(userStatPosts: IUserStat[]) {
  return userStatPosts.map((post) => {
    return { ...post, ...DEFAULT_STAT_POST_PROPS } as IUserStatPost;
  });
}

export function updateExistingStatsArrayWithAnother<T extends IUserStat>(currentStatsArray: T[], newArray: T[]) {
  const existingStatsArray = [...currentStatsArray];
  newArray.forEach((newOne) => {
    const { statType, statId, statComponentId } = newOne;
    const predicate: (stat: T) => boolean = (stat) => {
      return stat.statComponentId === statComponentId && stat.statId === statId && stat.statType === statType;
    };
    const index = findIndex(existingStatsArray, predicate);
    if (index !== -1) {
      existingStatsArray[index] = newOne;
    } else {
      existingStatsArray.push(newOne);
    }
  });
  return existingStatsArray;
}

export function parseGenericStat(statId: string, payload: string | number | boolean | Partial<IUserStat>) {
  const statIds = parseUserStatIds(statId);
  let restStat: Partial<IUserStat> = {};
  if (typeof payload === 'string') {
    restStat = JSON.parse(payload);
  } else if (typeof payload === 'number') {
    restStat.valueInt = payload;
  } else if (typeof payload === 'boolean') {
    restStat.valueInt = payload ? 1 : 0;
  } else restStat = payload;
  const returnStat: IUserStat = {
    ...statIds,
    ...restStat,
    valueInt: restStat.valueInt ? restStat.valueInt : 0,
    valueTxt: restStat.valueTxt ? restStat.valueTxt : '',
  };
  return returnStat;
}

export function parseStringStat(statId: string, payload: string) {
  const statIds = parseUserStatIds(statId);
  const returnStat: IUserStat = {
    ...statIds,
    valueInt: 0,
    valueTxt: payload,
  };
  return returnStat;
}

function areStatsSame(statA: IUserStat, statB: IUserStat) {
  return (
    `${statA.statType}-${statA.statId}-${statA.statComponentId}` ===
    `${statB.statType}-${statB.statId}-${statB.statComponentId}`
  );
}

export function removeStatsFromQueue(queue: IUserStat[], savedQueue: IUserStat[]) {
  return differenceWith(queue, savedQueue, areStatsSame);
}
