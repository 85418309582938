import { IApiService } from 'web_core_library';
import { TExperimentsResponse } from './types';

export function createExperimentsService() {
  let ApiService: IApiService;

  const getExperiments = (experiments: Array<number>) => {
    const experimentIds = experiments.join(',');
    return ApiService.action<TExperimentsResponse>('user_experiments', {
      method: 'get',
      urlParams: {
        userId: ApiService.getUserId(),
        ids: experimentIds,
      },
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    getExperiments,
  };
  return service;
}

const ExperimentsService = createExperimentsService();
export default ExperimentsService;
