import { Classes } from '@blueprintjs/core';
import { FC } from 'react';

export interface ICloseButtonProps {
  className?: string;
  onClose?: () => void;
}

const CloseButton: FC<ICloseButtonProps> = ({ className, onClose }) => {
  const customClassname = className ?? '';
  return (
    <button
      className={`${Classes.DIALOG_CLOSE_BUTTON} ${Classes.BUTTON} ${Classes.MINIMAL} ${customClassname}`}
      onClick={onClose}>
      X
    </button>
  );
};

export default CloseButton;
