export default function CloseIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7488 0.25158C16.0837 0.587021 16.0837 1.13088 15.7488 1.46632L9.21297 8.01153L15.7257 14.5337C16.0607 14.8691 16.0607 15.413 15.7257 15.7484C15.3908 16.0839 14.8477 16.0839 14.5127 15.7484L7.99999 9.22627L1.48724 15.7484C1.15228 16.0839 0.609209 16.0839 0.274252 15.7484C-0.0607044 15.413 -0.0607039 14.8691 0.274253 14.5337L6.787 8.01153L0.251218 1.46632C-0.083739 1.13088 -0.0837395 0.587021 0.251217 0.25158C0.586174 -0.0838598 1.12925 -0.08386 1.4642 0.25158L7.99999 6.7968L14.5358 0.25158C14.8707 -0.0838602 15.4138 -0.08386 15.7488 0.25158Z"
        fill="#319DD9"
      />
    </svg>
  );
}
