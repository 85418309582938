import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { ApiService } from 'web_core_library';
import ConfigCache from './configCache';
import ConfigService from './configService';
import { DEFAULT_VARIANT, DEFAULT_VERSION, DEFAULT_WORKOUTS } from './constants';
import * as ConfigSelectors from './selectors';
import { ConfigActions } from './slice';
import { ConfigError, IVersionCache, IWorkoutConfigApiResponse, IWorkoutConfigMap } from './types';
import { isCacheExpired } from './utils';

export function* initFeatureSaga(action: PayloadAction<number>) {
  const userId = action.payload;
  ConfigService.init(ApiService);
  yield call(ConfigCache.init, userId);
  const cachedData: { cachedVersion?: IVersionCache; cachedWorkouts?: IWorkoutConfigMap } | undefined =
    yield call(loadCachedDataSaga);
  let { cachedWorkouts, cachedVersion } = cachedData || {};
  try {
    if (!cachedWorkouts || !cachedVersion || !cachedVersion.variant || !cachedVersion.version) {
      const workoutsResponse: AxiosResponse<IWorkoutConfigApiResponse> = yield call(ConfigService.getWorkoutsConfig);
      const { variant, version, workouts } = workoutsResponse.data;
      cachedWorkouts = workouts || DEFAULT_WORKOUTS;
      cachedVersion = {
        variant: variant || DEFAULT_VARIANT,
        version: version || DEFAULT_VERSION,
        syncTimestamp: Date.now(),
      };
      yield call(ConfigCache.setWorkouts, cachedWorkouts);
      yield call(ConfigCache.setVersion, cachedVersion);
    }
    yield call(updateWorkoutsConfigSaga, cachedWorkouts, cachedVersion);
  } catch (error) {
    const cachedWorkouts = DEFAULT_WORKOUTS as IWorkoutConfigMap;
    const cachedVersion = {
      variant: DEFAULT_VARIANT,
      version: DEFAULT_VERSION,
    };
    yield call(updateWorkoutsConfigSaga, cachedWorkouts, cachedVersion);
    throw new ConfigError('Workouts Config loading failed');
  }
}

export function* preloadConfig() {
  const isConfigLoading: boolean = yield select(ConfigSelectors.isConfigLoading);
  if (!isConfigLoading) {
    return;
  }
  yield take(ConfigActions.configReady);
}

export function* loadCachedDataSaga() {
  try {
    const cachedVersion: IVersionCache | undefined = yield call(ConfigCache.getVersion);
    const syncTimestamp = cachedVersion?.syncTimestamp;
    const cacheExpired: boolean = yield call(isCacheExpired, syncTimestamp);
    if (cacheExpired) {
      // return empty value to force reloading from api
      return {};
    }
    const cachedWorkouts: IWorkoutConfigMap | undefined = yield call(ConfigCache.getWorkouts);
    return { cachedWorkouts, cachedVersion };
  } catch (error: any) {
    const { data } = error;
    // rethrow error with correct message
    const message = data && data.msg ? data.msg : error.message;
    throw new ConfigError(message);
  }
}

export function* updateWorkoutsConfigSaga(workouts: IWorkoutConfigMap, version: IVersionCache) {
  yield put(ConfigActions.updateWorkouts(workouts));
  yield put(ConfigActions.updateVersion(version.variant, version.version));
  yield put(ConfigActions.configReady());
}

export default function* configWatcher() {
  yield all([takeEvery(ConfigActions.initConfig, initFeatureSaga)]);
}
