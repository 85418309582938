import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkoutUnityResult } from 'web_unity_engine';
import {
  IBaseResult,
  ILoadLastProgressPayload,
  ILoadProgressHistoryPayload,
  ILoadworkoutProgressPayload,
  ILocalResult,
  ISaveProgressPayload,
  ISaveProgressScorePayload,
  IUpdateProgressHistoryPayload,
  IUpdateProgressPayload,
  IUpdateWorkoutLastProgressPayload,
  IUpdateWorkoutProgressPayload,
} from './types';
import { findWorkoutProgress, getRangeId } from './utils';

export interface IProgressState {
  loading: boolean;
  saving: boolean;
  ready: boolean;
  // global progress storage
  // may be should not store the full progress in state
  // but store it in some custom service to prevent
  // redux from complaining about too big state
  fullProgress?: ILocalResult[];
  // shortcuts when global progress is not loaded yet
  // undefined - progress not loaded
  // null - progress loaded but empty
  history: {
    // history for training calendar
    [range: string]: IBaseResult[] | null | undefined;
    // progress for particular workouts
    [workoutId: number]: IBaseResult[] | null | undefined;
  };
  last: {
    // last progress entries for particular workouts
    [workoutId: number]: IBaseResult | null | undefined;
  };
  // data for custom users (group admin feature)
  users: {
    [userId: number]: {
      fullProgress?: ILocalResult[];
      history?: {
        [range: string]: IBaseResult[] | null | undefined;
        [workoutId: number]: IBaseResult[] | null | undefined;
      };
      last?: {
        [workoutId: number]: IBaseResult | null | undefined;
      };
    };
  };
}

export const initialState: IProgressState = {
  loading: false,
  saving: false,
  ready: false,
  history: {},
  last: {},
  users: {},
};

export const statePropName = 'progress';

const progressSlice = createSlice({
  name: statePropName,
  initialState,
  reducers: {
    initProgress: (state: IProgressState, action: PayloadAction<number>) => {
      // Handle initialization if needed
    },
    progressReady: (state: IProgressState) => {
      state.ready = true;
    },
    loadProgress: (state: IProgressState, action: PayloadAction<number | undefined>) => {
      state.loading = true;
    },
    progressLoaded: (state: IProgressState) => {
      state.loading = false;
    },
    updateProgress: {
      reducer: (state: IProgressState, action: PayloadAction<IUpdateProgressPayload>) => {
        const { progress, userId } = action.payload;
        if (!userId) {
          return {
            ...state,
            fullProgress: progress,
            // reset explicit state since its not needed anymore
            history: {},
            last: {},
          };
        }
        const userState = {
          ...state.users[userId],
          fullProgress: progress,
          // reset explicit state since its not needed anymore
          history: undefined,
          last: undefined,
        };
        return {
          ...state,
          users: {
            ...state.users,
            [userId]: userState,
          },
        };
      },
      prepare: (progress: ILocalResult[], userId?: number) => {
        return { payload: { progress, userId } };
      },
    },
    saveProgress: {
      reducer: (state: IProgressState, action: PayloadAction<ISaveProgressPayload>) => {},
      /**
       * parse unity result and save progress to backend
       * @param {string} variant config variant
       * @param {number} version config version
       * @param {number} cycleId id of the current cycle
       * @param {number} sessionId id of the current session
       * @param {number} workoutId id of the workout
       * @param {WorkoutUnityResult} progress result object from unity
       */
      prepare: (
        variant: string,
        version: number,
        cycleId: number,
        sessionId: number,
        workoutId: number,
        progress: WorkoutUnityResult
      ) => {
        return { payload: { variant, version, cycleId, sessionId, workoutId, progress } };
      },
    },
    saveProgressScore: {
      reducer: (state: IProgressState, action: PayloadAction<ISaveProgressScorePayload>) => {},
      /**
       * save score for workout (CFQ) to backend
       * @param {string} variant config variant
       * @param {number} version config version
       * @param {number} cycleId id of the current cycle
       * @param {number} sessionId id of the current session
       * @param {number} workoutId id of the workout
       * @param {number} progress score gained
       */
      prepare: (
        variant: string,
        version: number,
        cycleId: number,
        sessionId: number,
        workoutId: number,
        score: number
      ) => {
        return { payload: { variant, version, cycleId, sessionId, workoutId, score } };
      },
    },
    startSaveProgress: (state: IProgressState) => {
      state.saving = true;
    },
    endSaveProgress: (state: IProgressState) => {
      state.saving = false;
    },
    loadProgressHistory: {
      reducer: (state: IProgressState, action: PayloadAction<ILoadProgressHistoryPayload>) => {},
      prepare: (startTimestamp: number, endTimestamp: number, userId?: number) => {
        return { payload: { startTimestamp, endTimestamp, userId } };
      },
    },
    updateProgressHistory: {
      reducer: (state: IProgressState, action: PayloadAction<IUpdateProgressHistoryPayload>) => {
        const { endTimestamp, progress, startTimestamp, userId } = action.payload;
        const rangeId = getRangeId(startTimestamp, endTimestamp);
        if (!userId) {
          const history = {
            ...state.history,
            [rangeId]: progress,
          };
          return {
            ...state,
            history,
          };
        }
        const userProgressState = state.users[userId] || {};
        const userHistory = userProgressState.history || {};
        const userState = {
          ...userProgressState,
          history: {
            ...userHistory,
            [rangeId]: progress,
          },
        };
        return {
          ...state,
          users: {
            ...state.users,
            [userId]: userState,
          },
        };
      },
      prepare: (startTimestamp: number, endTimestamp: number, progress: IBaseResult[], userId?: number) => {
        return { payload: { startTimestamp, endTimestamp, progress, userId } };
      },
    },
    loadLastProgress: {
      reducer: (state: IProgressState, action: PayloadAction<ILoadLastProgressPayload>) => {},
      prepare: (workoutIds: number[], userId?: number) => {
        return { payload: { workoutIds, userId } };
      },
    },
    updateWorkoutLastProgress: {
      reducer: (state: IProgressState, action: PayloadAction<IUpdateWorkoutLastProgressPayload>) => {
        const { workoutIds, progress, userId } = action.payload;
        if (!workoutIds) {
          return state;
        }
        if (!userId) {
          const last = {
            ...state.last,
          };
          workoutIds.forEach((workoutId) => {
            const result = findWorkoutProgress(progress, workoutId);
            last[workoutId] = result ?? null;
          });
          return {
            ...state,
            last,
          };
        }
        const userProgressState = state.users[userId] || {};
        const userLast = userProgressState.last || {};
        const updatedLast = {
          ...userLast,
        };
        workoutIds.forEach((workoutId) => {
          const result = findWorkoutProgress(progress, workoutId);
          updatedLast[workoutId] = result ?? null;
        });
        const updatedUserProgressState = {
          ...userProgressState,
          last: updatedLast,
        };
        return {
          ...state,
          users: {
            ...state.users,
            [userId]: updatedUserProgressState,
          },
        };
      },
      prepare: (workoutIds: number[], progress: IBaseResult[], userId?: number) => {
        return { payload: { workoutIds, progress, userId } };
      },
    },
    loadWorkoutsProgress: {
      reducer: (state: IProgressState, action: PayloadAction<ILoadworkoutProgressPayload>) => {},
      prepare: (workoutIds: number[], userId?: number) => {
        return { payload: { workoutIds, userId } };
      },
    },
    updateWorkoutProgress: {
      reducer: (state: IProgressState, action: PayloadAction<IUpdateWorkoutProgressPayload>) => {
        const { workoutId, progress, userId } = action.payload;
        if (!userId) {
          const history = {
            ...state.history,
            [workoutId]: progress,
          };
          return {
            ...state,
            history,
          };
        }
        const userProgressState = state.users[userId] || {};
        const userHistory = userProgressState.history || {};
        const updatedHistory = {
          ...userHistory,
          [workoutId]: progress,
        };
        const updatedUserProgressState = {
          ...userProgressState,
          history: updatedHistory,
        };
        return {
          ...state,
          users: {
            ...state.users,
            [userId]: updatedUserProgressState,
          },
        };
      },
      prepare: (workoutId: number, progress: IBaseResult[], userId?: number) => {
        return { payload: { workoutId, progress, userId } };
      },
    },
    cleanProgress: (state: IProgressState) => {},
    cleanProgressDone: (state: IProgressState) => {},
  },
});

export const ProgressActions = progressSlice.actions;
export default progressSlice.reducer;
