import { IApiService } from 'web_core_library';
import { IGetUserPeerGroupResult, IGetUserStatsResult, IUserPeerGroup, IUserStatPost } from './types';

export function createStatsService() {
  let ApiService: IApiService;

  const getUserStats = (userId?: number) => {
    const statUserId = userId ?? ApiService.getUserId();
    if (!statUserId) {
      throw new Error('User not defined!');
    }
    return ApiService.action<IGetUserStatsResult>('users_stats', {
      method: 'get',
      urlParams: {
        userId: statUserId,
      },
    });
  };

  const saveUserStats = (stats: IUserStatPost[], userId?: number) => {
    const statUserId = userId ?? ApiService.getUserId();
    if (!statUserId) {
      throw new Error('User not defined!');
    }
    return ApiService.action<IGetUserStatsResult>('users_stats', {
      method: 'patch',
      urlParams: {
        userId: statUserId,
      },
      data: stats,
    });
  };

  const getUserPeerGroup = (customUserId?: number) => {
    const userId = customUserId ?? ApiService.getUserId();
    if (!userId) {
      throw new Error('User not defined!');
    }
    return ApiService.action<IGetUserPeerGroupResult>('users_peergroup', {
      method: 'get',
      urlParams: {
        userId,
      },
    });
  };

  const saveUserPeerGroups = (peergroups: IUserPeerGroup[]) => {
    const userId = ApiService.getUserId();
    if (!userId) {
      throw new Error('User not defined!');
    }
    return ApiService.action('users_peergroup', {
      method: 'post',
      urlParams: {
        userId,
      },
      data: {
        peergroups,
      },
    });
  };

  const saveUserAgeGroup = (ageGroup: number) => {
    const group: IUserPeerGroup = {
      categoryId: 1,
      groupId: ageGroup,
      inserted: -1,
    };
    return saveUserPeerGroups([group]);
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    getUserStats,
    getUserPeerGroup,
    saveUserAgeGroup,
    saveUserStats,
  };
  return service;
}

const StatsService = createStatsService();
export default StatsService;
