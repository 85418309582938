import * as React from 'react';

import './Button.scss';
import { useDoubleClickProtection } from './hooks';

export enum EButtonColor {
  white = 'nn-button__white',
  blue = 'nn-button__blue',
  green = 'nn-button__green',
  red = 'nn-button__red',
}

export const CButtonClassname = 'nn-button';

export interface IButtonProps {
  label: React.ReactNode;
  color?: EButtonColor;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<React.PropsWithChildren<IButtonProps>> = ({
  label,
  color = EButtonColor.blue,
  disabled,
  onClick,
  className = '',
  type = 'button',
  children,
}) => {
  const onClickHandler = useDoubleClickProtection(onClick);
  return (
    <button
      type={type}
      className={`${CButtonClassname} ${color} ${className}`}
      onClick={onClickHandler}
      disabled={disabled}>
      {label}
      {children}
    </button>
  );
};

export default Button;
