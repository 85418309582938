import * as HistoryService from './historyService';

// normalize th result with folder symbol at the end
function normalizeHref(href: string) {
  let normalizedHref = href;
  if (normalizedHref.indexOf('/?') < 0 && normalizedHref.indexOf('?') >= 0) {
    normalizedHref = normalizedHref.replace('?', '/?');
  }
  return normalizedHref;
}

// strip the search parameters of the current path from the hreflang url
function stripSearch(href: string) {
  const index = href.indexOf('?');
  if (index < 0) {
    return href;
  }
  return href.substring(0, index);
}

function hreflangListener(path: string) {
  const links = document.querySelectorAll('link[rel="alternate"]');
  links.forEach((link) => {
    const hreflang = link.getAttribute('hreflang');
    const href = window.location.protocol + '//' + window.location.host + stripSearch(path);
    const url = new URL(href);
    switch (hreflang) {
      case 'de':
        url.searchParams.set('lang', 'de');
        break;
      case 'fr':
        url.searchParams.set('lang', 'fr');
        break;
      case 'ru':
        url.searchParams.set('lang', 'ru');
        break;
      default:
        break;
    }
    link.setAttribute('href', normalizeHref(url.toString()));
  });
}

function initHreflangService() {
  HistoryService.addListener(hreflangListener);
}

export default initHreflangService;
