import * as React from 'react';
import SocialBar, { ISocialIconProps } from '../SocialBar';
import { Section } from '../sections';
import './Footer.scss';
import FooterMenu from './FooterMenu';
import LegalsMenu from './LegalsMenu';
import { IFooterMenuItem, ILegalLinks } from './types';

export interface IFooterProps {
  hideFooter?: boolean;
  languageSelector?: React.ReactNode;
  copyrightLine: string;
  footerMenuItems?: IFooterMenuItem[];
  extraMenuItems?: IFooterMenuItem[];
  socialBarItems?: ISocialIconProps[];
  mobileAppLink?: string;
  mobileTitle?: string;
  assetBaseUrl?: string;
  legalLinks: ILegalLinks;
  cookieLinkTitle?: string;
  onToggleCookieSettings: () => void;
}

const Footer: React.FC<IFooterProps> = ({
  hideFooter,
  languageSelector,
  copyrightLine,
  footerMenuItems,
  extraMenuItems,
  socialBarItems,
  legalLinks,
  cookieLinkTitle,
  mobileAppLink,
  mobileTitle,
  assetBaseUrl,
  onToggleCookieSettings,
}) => {
  if (hideFooter) {
    return null;
  }

  const appStoreImgSrc = `${assetBaseUrl}/lp/app_store_badge_de.svg`;
  const playStoreImgSrc = `${assetBaseUrl}/lp/google-play-badge.svg`;

  const appStoreSection = (
    <div className="col-8 footer-mobile">
      <h3>{mobileTitle}</h3>
      <a href={mobileAppLink}>
        <img src={appStoreImgSrc} alt={mobileTitle} />
      </a>
      <a href={mobileAppLink}>
        <img src={playStoreImgSrc} alt={mobileTitle} />
      </a>
    </div>
  );

  return (
    <footer className="App-footer">
      <Section className="footer-section">
        <div className="row">
          <div className="col-6 col-m-4 footer-icons" data-testid="footer-icons">
            {mobileAppLink && appStoreSection}
            {socialBarItems && <SocialBar icons={socialBarItems} />}
          </div>
          <div className="col-6 col-m-4 footer-language" data-testid="footer-language">
            {languageSelector}
          </div>
          <div className="col-5 col-m-4 footer-nav" data-testid="footer-nav">
            {footerMenuItems && <FooterMenu footerMenuItems={footerMenuItems} extraMenuItems={extraMenuItems} />}
          </div>
        </div>
        <div className="row footer-copyright" data-testid="footer-copyright">
          <div className="col-6 col-m-4 copyright">
            <small>{copyrightLine}</small>
          </div>
          <div className="col-6 col-m-4 legals" data-testid="legals-navbar">
            <LegalsMenu
              cookieLinkTitle={cookieLinkTitle}
              legalLinks={legalLinks}
              onToggleCookieSettings={onToggleCookieSettings}
            />
          </div>
        </div>
      </Section>
    </footer>
  );
};

export default Footer;
