import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { all, call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from './actionTypes';
import LottieCache from './lottieCache';
import LottieController from './lottieController';
import LottieService from './lottieService';

export function* loadLottie(name: string) {
  //@ts-ignore
  let cachedValue = yield call([LottieCache, 'load'], name);
  if (!cachedValue) {
    const response: AxiosResponse = yield call([LottieService, 'loadLottie'], name);
    const data = response.data;
    yield call([LottieCache, 'save'], name, data);
    cachedValue = data;
  }
  return cachedValue;
}

export function* initLottieSaga({ assetsUrl }: ActionTypes.IInitLottieAction) {
  yield call(LottieService.setAssetsUrl, assetsUrl);
  yield call(LottieCache.init);
}

export function* loadLottieSaga({ name, autoplay, loop, dontKeep, id = name, stopOn }: ActionTypes.ILottieLoadAction) {
  //@ts-ignore
  const cachedValue = yield call(loadLottie, name);
  yield call([LottieController, 'load'], id, cachedValue, autoplay, loop, dontKeep, stopOn);
}

export function* startLottieSaga({ id }: ActionTypes.ILottieStartAction) {
  yield call([LottieController, 'play'], id);
}

export function* forceCacheLottieSaga({ name }: ActionTypes.ILottieForceCacheAction) {
  yield call(loadLottie, name);
}

export default function* watchLottie(dispatch: Dispatch) {
  // initialize controller with dispatch function
  LottieController.setup(dispatch);
  // start watching for actions
  yield all([
    takeEvery(ActionTypes.LOTTIE_INIT, initLottieSaga),
    takeEvery(ActionTypes.LOTTIE_LOAD, loadLottieSaga),
    takeEvery(ActionTypes.LOTTIE_START, startLottieSaga),
    takeEvery(ActionTypes.LOTTIE_FORCE_CACHE, forceCacheLottieSaga),
  ]);
}
