import { IApiService } from 'web_core_library';

export function getOutbrainService() {
  let ApiService: IApiService;
  let clickId: string | undefined;
  const init = (ApiServiceInstance: IApiService, outbrainClickId?: string) => {
    ApiService = ApiServiceInstance;
    clickId = outbrainClickId;
  };
  const track = (trackName: string) => {
    if (!clickId) {
      // do not track if click id not set
      return Promise.resolve();
    }
    return ApiService.action('analytics', {
      method: 'get',
      urlParams: {
        service: 'o',
      },
      params: {
        ob_click_id: clickId,
        name: trackName,
      },
    });
  };
  const trackSubscribe = () => track('subscribe');
  const trackValidation = () => track('validation');
  return {
    init,
    trackSubscribe,
    trackValidation,
  };
}

const outbrainService = getOutbrainService();
export default outbrainService;
