import { DBSchema, IDBPDatabase } from 'idb';
import getCacheDB, { registerUpgradeFunction } from '../../common/cacheDb';
import { ILocalResult, IProgressCacheValue, ProgressError } from './types';
import { getLastProgressTimestamp } from './utils';

type TProgressSchema = {
  // we use different key to store values for different users
  // by default it will be only one user
  key: number;
  value: IProgressCacheValue;
};

export const CACHE_DB_NAME = 'progress2';

interface IProgressDBSchema extends DBSchema {
  [CACHE_DB_NAME]: TProgressSchema;
}

registerUpgradeFunction<IProgressDBSchema>((db) => {
  if (!db.objectStoreNames.contains(CACHE_DB_NAME)) {
    db.createObjectStore(CACHE_DB_NAME);
  }
});

export function initProgressDB() {
  let db: IDBPDatabase<IProgressDBSchema>;
  let userId: number;
  const init = async (uId: number) => {
    userId = uId;
    try {
      db = await getCacheDB<IProgressDBSchema>(uId);
    } catch {
      return;
    }
  };
  async function getProgress(customUserId?: number) {
    try {
      if (!db) {
        throw new ProgressError('Client DB not open');
      }
      const key = customUserId ?? userId;
      const result = await db.get(CACHE_DB_NAME, key);
      return result;
    } catch {
      return;
    }
  }
  async function saveProgress(progress: ILocalResult[], customUserId?: number) {
    try {
      if (!db) {
        throw new ProgressError('Client DB not open');
      }
      const key = customUserId ?? userId;
      const cacheValue: IProgressCacheValue = {
        progress,
        timestamp: getLastProgressTimestamp(progress),
      };
      await db.put(CACHE_DB_NAME, cacheValue, key);
    } catch {
      return;
    }
  }
  async function clear() {
    try {
      await db.clear(CACHE_DB_NAME);
      return true;
    } catch {
      return false;
    }
  }
  return {
    init,
    getProgress,
    saveProgress,
    clear,
  };
}

const ProgressDB = initProgressDB();
export default ProgressDB;
