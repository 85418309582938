import { useState } from 'react';

// Minimum allowed time between two button clicks in milliseconds
export const BUTTON_DOUBLECLICK_DELAY = 400;

export function useDoubleClickProtection(onClick?: React.MouseEventHandler) {
  const [lastClick, setLastClick] = useState<number | undefined>(undefined);
  const onClickHandler: React.MouseEventHandler = (event: React.MouseEvent) => {
    const now = Date.now();
    if (lastClick === undefined || now - lastClick > BUTTON_DOUBLECLICK_DELAY) {
      // If first click or delay passed, send a click event
      setLastClick(now);
      onClick && onClick(event);
    } else {
      event.preventDefault();
    }
  };
  return onClickHandler;
}
