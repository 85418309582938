import * as React from 'react';

import './LoadingSpinner.scss';

export interface ILoadingSpinnerProps {
  assetsUrl?: string;
}
const defaultAssetsPath = 'https://cms.cdn.neuronation.com/assets/opxp';
const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({ assetsUrl = defaultAssetsPath }) => (
  <div className="loading-indicator">
    <img className="spin" alt="loading indicator" src={`${assetsUrl}/spinner.png`} />
  </div>
);
export default LoadingSpinner;
