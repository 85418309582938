import { ILanguage } from './types';

export const LANGUAGES: ILanguage[] = [
  {
    code: 'en',
    title: 'English',
  },
  {
    code: 'de',
    title: 'Deutsch',
  },
  {
    code: 'ru',
    title: 'Русский',
  },
  {
    code: 'fr',
    title: 'Français',
  },
  {
    code: 'ja',
    title: '日本語',
  },
  {
    code: 'es',
    title: 'Español',
  },
  {
    code: 'it',
    title: 'Italiano',
  },
  {
    code: 'pl',
    title: 'Polski',
  },
];
