import { SharedErrors } from 'web_core_library';
const LOTTIE_FEATURE = 'Lottie';

export default class LottieError extends SharedErrors.BaseError {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, LottieError.prototype);
    this.feature = LOTTIE_FEATURE;
  }
}
