import * as React from 'react';

export interface INavItemProps {
  className?: string;
  title?: string;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
}

export const NavItem: React.FC<React.PropsWithChildren<INavItemProps>> = ({
  children,
  className = '',
  title,
  onClick,
}) => {
  const customClass = className ? ` ${className}` : '';
  const itemClass = `nav-item${customClass}`;
  return (
    <li className={itemClass} title={title} onClick={onClick}>
      {children}
    </li>
  );
};

export default NavItem;
