export const getCacheBustedUrl = (pictureUrl?: string) => {
  if (!pictureUrl) {
    return;
  }
  const url = new URL(pictureUrl);
  const randomKey = Math.floor(Math.random() * 1000);
  url.searchParams.append('cache_buster', `${randomKey}`);
  return url.toString();
};

export const parseSessionToken = (sessionToken: string) => {
  const userData = JSON.parse(window.atob(sessionToken.split('.')[1]));
  const userId = userData.uid;
  const expires = parseInt(userData.exp, 10) * 1000;
  const iat = parseInt(userData.iat, 10) * 1000;
  return { userId, expires, iat };
};
