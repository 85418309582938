import takeRight from 'lodash/takeRight';
import { IApiService } from 'web_core_library';
import { isLocalhost } from '../../common/utils';
import { ICookieConsent } from './types';

function getCookie(cName: string) {
  const name = cName + '=';
  // get the full cookies string
  const decodedCookie = decodeURIComponent(document.cookie);
  // split into single cookies strings
  const cookies = decodedCookie.split(';');
  // search for cookie with given name
  for (const cookie of cookies) {
    // trim whitespaces from start
    const trimmedCookie = cookie.trimStart();
    // detect if cookie starts with requested name
    if (trimmedCookie.startsWith(name)) {
      return trimmedCookie.substring(name.length, trimmedCookie.length);
    }
  }
}

export const COOKIE_CONSENT_NAME = 'nn_consent';

export function getConsent() {
  const consentCookie = getCookie(COOKIE_CONSENT_NAME);
  if (!consentCookie) {
    return;
  }
  try {
    return JSON.parse(consentCookie) as ICookieConsent;
  } catch (error) {
    return;
  }
}

export function saveConsent(consent: ICookieConsent) {
  const consentValue = JSON.stringify(consent);
  return setCookie(COOKIE_CONSENT_NAME, consentValue);
}

export const COOKIE_DURATION_MS = 365 * 24 * 60 * 60 * 1000;

function setCookie(cName: string, cValue: string, disableSameSite = false) {
  const cookieValue = encodeURIComponent(cValue);
  const attributes: string[] = [];
  const expirationDate = new Date(Date.now() + COOKIE_DURATION_MS);
  attributes.push('expires=' + expirationDate.toUTCString());
  attributes.push('domain=' + getDomain());
  attributes.push('path=/');
  if (!isLocalhost) {
    attributes.push('Secure');
    if (!disableSameSite) {
      attributes.push('SameSite=Strict');
    }
  }
  if (disableSameSite) {
    attributes.push('SameSite=None');
  }
  const newCookie = `${cName}=${cookieValue}; ` + attributes.join('; ');
  document.cookie = newCookie;
  return newCookie;
}

function getDomain() {
  // get domain from current url
  const domain = window.location.hostname;
  // split in parts
  const parts = domain.split('.');
  // take last two domains
  const lastParts = takeRight(parts, 2);
  // compile back to string
  return lastParts.join('.');
}

export function createGDPRHttpService() {
  let ApiService: IApiService;
  const requestGDPRExport = () => {
    return ApiService.action('user_exportdata', {
      method: 'put',
      urlParams: {
        userId: ApiService.getUserId(),
      },
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    requestGDPRExport,
  };
  return service;
}

export const GDPRHttpService = createGDPRHttpService();
