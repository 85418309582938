import { LoadingSpinner } from '@web_opxp/components';
import { SentryService, isLocalhost, isProduction } from '@web_opxp/features';
import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { Route, Router, Switch } from 'react-router';
import { AssetsBaseUrl, BuildID } from './base/env';
import history from './base/historyService';
import initHreflangService from './base/hreflangService';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (!isLocalhost) {
  SentryService.initSentry(isProduction(), BuildID);
}

const OnboardingApp = lazy(() => import('./RootApp'));
const ExercisesBigLandingPage = lazy(() => import('./landing/exercisesBig'));
const ExercisesDeviceLandingPage = lazy(() => import('./landing/exercisesDevice'));
const QuestionnairePage = lazy(() => import('./questionnaire'));
const NativeLandingPage = lazy(() => import('./landing/native'));
const TestimonialLandingPage = lazy(() => import('./landing/testimonial'));
const GoLandingPage = lazy(() => import('./landing/lumosity'));
const QuizPage = lazy(() => import('./quiz'));
const CorpLandingPage = lazy(() => import('./landing/corp'));
const BlogGoLandingPage = lazy(() => import('./landing/blogGo'));
const AccountDeletedPage = lazy(() => import('./landing/accountDeleted'));

const container = document.getElementById('root');
// eslint-disable-next-line
const root = createRoot(container!);

root.render(
  <Router history={history}>
    <Suspense fallback={<LoadingSpinner assetsUrl={AssetsBaseUrl} />}>
      <Switch>
        <Route exact path="/account-deleted">
          <AccountDeletedPage />
        </Route>
        <Route exact path="/lp/exercises-big">
          <ExercisesBigLandingPage />
        </Route>
        <Route exact path="/lp/frontpage/exercises-big">
          <ExercisesBigLandingPage frontpage={true} />
        </Route>
        <Route exact path="/lp/exercises-big-white">
          <ExercisesBigLandingPage color="white" />
        </Route>
        <Route exact path="/lp/frontpage/exercises-big-white">
          <ExercisesBigLandingPage color="white" frontpage={true} />
        </Route>
        <Route exact path="/lp/exercises-device">
          <ExercisesDeviceLandingPage />
        </Route>
        <Route exact path="/lp/frontpage/exercises-device">
          <ExercisesDeviceLandingPage frontpage={true} />
        </Route>
        <Route exact path="/lp/exercises-device-white">
          <ExercisesDeviceLandingPage color="white" />
        </Route>
        <Route exact path="/lp/frontpage/exercises-device-white">
          <ExercisesDeviceLandingPage color="white" frontpage={true} />
        </Route>
        <Route path="/questionnaire">
          <QuestionnairePage />
        </Route>
        <Route exact path="/lp/native">
          <NativeLandingPage />
        </Route>
        <Route exact path="/lp/videofeedback/andrean">
          <TestimonialLandingPage />
        </Route>
        <Route exact path="/lp/videoandrean">
          <TestimonialLandingPage onboardingCTA />
        </Route>
        <Route path="/lp/go">
          <GoLandingPage />
        </Route>
        <Route path="/lp/corp">
          <CorpLandingPage />
        </Route>
        <Route path="/quiz">
          <QuizPage />
        </Route>
        <Route path="/lp/blog_go/:locaId?">
          <BlogGoLandingPage />
        </Route>
        <Route>
          <OnboardingApp />
        </Route>
      </Switch>
    </Suspense>
  </Router>
);

// init listener for hreflang tags udpate
initHreflangService();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
