import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { CacheService } from 'web_core_library';
import { HISTOGRAM_CACHE_EXPIRATION_TIMEOUT_MS } from './constants';
import { IHistogramBin, IWorkoutHistogramCache } from './types';

async function init() {
  await CacheService.init();
}

/**
 * checks if cache has expired
 * undefined timestamp counts as expired cache for backward compatibility
 * @param syncTimestamp synchronization timestamp for the given cache value
 */
const isCacheExpired = (syncTimestamp?: number) => {
  if (!syncTimestamp) {
    return true;
  }
  const current = Date.now();
  return current - syncTimestamp >= HISTOGRAM_CACHE_EXPIRATION_TIMEOUT_MS;
};

/**
 * checks if cache value is invalid (old type of value, not set or expired)
 * @param value cache value
 */
const isCacheInvalid = (value: void | IWorkoutHistogramCache, expectedGroupId: number) => {
  if (!value) {
    return true;
  }
  if (isArray(value)) {
    // return true if an old version of cache is found
    return true;
  }
  const { histogram, syncTimestamp, groupId } = value;
  if (expectedGroupId !== groupId) {
    // if age group has changed - cache is invalid
    return true;
  }
  return isCacheExpired(syncTimestamp) || !isArray(histogram) || isEmpty(histogram);
};

async function get(workoutId: number, groupId: number) {
  try {
    const cacheValue = await CacheService.load<IWorkoutHistogramCache>(`histogram-${workoutId}`);
    const isInvalid = isCacheInvalid(cacheValue, groupId);
    if (isInvalid) {
      return;
    }
    return cacheValue?.histogram;
  } catch {
    // ignore errors, return undefined as if cache was empty
    return;
  }
}

async function set(workoutId: number, groupId: number, histogramBin: IHistogramBin[]) {
  try {
    const cacheValue: IWorkoutHistogramCache = {
      histogram: histogramBin,
      groupId,
      syncTimestamp: Date.now(),
    };
    return await CacheService.save(`histogram-${workoutId}`, cacheValue);
  } catch {
    // ignore errors, cache will not be saved
    return;
  }
}

const HistogramCacheService = {
  get,
  init,
  set,
};
export default HistogramCacheService;
