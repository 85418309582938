import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_VARIANT, DEFAULT_VERSION } from './constants';
import { IUpdateVersionPayload, IWorkoutConfigMap } from './types';

export interface IConfigState {
  loading: boolean;
  workouts: IWorkoutConfigMap;
  variant: string;
  version: number;
}

export const initialState: IConfigState = {
  loading: true,
  workouts: {},
  variant: DEFAULT_VARIANT,
  version: DEFAULT_VERSION,
};

export const statePropName = 'config';

const configSlice = createSlice({
  name: statePropName,
  initialState,
  reducers: {
    initConfig: (state: IConfigState, action: PayloadAction<number>) => {
      // Handle config initialization if needed
    },
    configReady: (state: IConfigState) => {
      state.loading = false;
    },
    updateWorkouts: (state: IConfigState, action: PayloadAction<IWorkoutConfigMap>) => {
      state.workouts = action.payload;
    },
    updateVersion: {
      reducer: (state: IConfigState, action: PayloadAction<IUpdateVersionPayload>) => {
        const { variant, version } = action.payload;
        state.variant = variant;
        state.version = version;
      },
      prepare: (variant: string, version: number) => {
        return { payload: { variant: variant, version: version } };
      },
    },
  },
});

export const ConfigActions = configSlice.actions;
export default configSlice.reducer;
