import { IApiService } from 'web_core_library';
import { IHistogramApiResponse } from './types';

export function createHistogramService() {
  let ApiService: IApiService;

  // this call returns training workout histogram for given workout ids
  const getWorkoutHistogram = (workoutIds: number[], userId?: number) => {
    const workoutId = workoutIds.join(',');
    const filter = '';
    return ApiService.action<IHistogramApiResponse>('training_workout_histogram', {
      method: 'get',
      urlParams: {
        // override userId if given as argument
        userId: userId ?? ApiService.getUserId(),
        workoutId,
        filter,
      },
    });
  };

  // this call returns training workout histogram for given workout ids for given age peer group
  const getWorkoutHistogramForAgeGroup = (workoutIds: number[], groupId: number, userId?: number) => {
    const workoutId = workoutIds.join(',');
    const filter = `category/1/group/${groupId}`;
    return ApiService.action<IHistogramApiResponse>('training_workout_histogram', {
      method: 'get',
      urlParams: {
        // override userId if given as argument
        userId: userId ?? ApiService.getUserId(),
        workoutId,
        filter,
      },
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    getWorkoutHistogram,
    getWorkoutHistogramForAgeGroup,
  };
  return service;
}

const HistogramService = createHistogramService();
export default HistogramService;
