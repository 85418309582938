import { IApiService } from 'web_core_library';

export function getTaboolaService() {
  let ApiService: IApiService;
  let clickId: string | undefined;
  const init = (ApiServiceInstance: IApiService, taboolaClickId?: string) => {
    ApiService = ApiServiceInstance;
    clickId = taboolaClickId;
  };
  const track = (trackName: string) => {
    if (!clickId) {
      // do not track if click id not set
      return Promise.resolve();
    }
    return ApiService.action('analytics', {
      method: 'get',
      urlParams: {
        service: 't',
      },
      params: {
        'click-id': clickId,
        name: trackName,
      },
    });
  };
  const trackSubscribe = () => track('subscribe');
  const trackValidation = () => track('validation');
  return {
    init,
    trackSubscribe,
    trackValidation,
  };
}

const taboolaService = getTaboolaService();
export default taboolaService;
