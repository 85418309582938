import { Action } from 'redux';

const LOTTIE_PREFIX = '@@LOTTIE';

// initialize lottie service with assets url
export const LOTTIE_INIT = `${LOTTIE_PREFIX}/LOTTIE_INIT`;
export type LOTTIE_INIT_TYPE = typeof LOTTIE_INIT;
export interface IInitLottieAction extends Action<LOTTIE_INIT_TYPE> {
  type: LOTTIE_INIT_TYPE;
  assetsUrl: string;
}

// load animation
export const LOTTIE_LOAD = `${LOTTIE_PREFIX}/LOTTIE_LOAD`;
export type LOTTIE_LOAD_TYPE = typeof LOTTIE_LOAD;
export interface ILottieLoadAction extends Action<LOTTIE_LOAD_TYPE> {
  type: LOTTIE_LOAD_TYPE;
  name: string;
  autoplay?: boolean;
  loop?: boolean;
  dontKeep?: boolean;
  id?: string;
  stopOn?: number;
}

// animation loaded
export const LOTTIE_LOADED = `${LOTTIE_PREFIX}/LOTTIE_LOADED`;
export type LOTTIE_LOADED_TYPE = typeof LOTTIE_LOADED;
export interface ILottieLoadedAction extends Action<LOTTIE_LOADED_TYPE> {
  type: LOTTIE_LOADED_TYPE;
  name: string;
}

// start animation
export const LOTTIE_START = `${LOTTIE_PREFIX}/LOTTIE_START`;
export type LOTTIE_START_TYPE = typeof LOTTIE_START;
export interface ILottieStartAction extends Action<LOTTIE_START_TYPE> {
  type: LOTTIE_START_TYPE;
  id: string;
}

// animation completed
export const LOTTIE_COMPLETE = `${LOTTIE_PREFIX}/LOTTIE_COMPLETE`;
export type LOTTIE_COMPLETE_TYPE = typeof LOTTIE_COMPLETE;
export interface ILottieCompleteAction extends Action<LOTTIE_COMPLETE_TYPE> {
  type: LOTTIE_COMPLETE_TYPE;
  id: string;
}

// load animation to cache
export const LOTTIE_FORCE_CACHE = `${LOTTIE_PREFIX}/LOTTIE_FORCE_CACHE`;
export type LOTTIE_FORCE_CACHE_TYPE = typeof LOTTIE_FORCE_CACHE;
export interface ILottieForceCacheAction extends Action<LOTTIE_FORCE_CACHE_TYPE> {
  type: LOTTIE_FORCE_CACHE_TYPE;
  name: string;
}

export type LottieActions =
  | IInitLottieAction
  | ILottieLoadAction
  | ILottieStartAction
  | ILottieLoadedAction
  | ILottieCompleteAction
  | ILottieForceCacheAction;
