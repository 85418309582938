import { SharedErrors } from 'web_core_library';
import { EDomain, EExerciseCategory, IDomainWeight, NO_ALERT_TYPE } from '../../common/types';

// platforms where workout is available: currently web or app
export type WorkoutPlatform = 'web' | 'app';

export type TWorkoutType = 'exercise' | 'assessment' | 'selfAssessmentQuestionaire' | 'challenge';

export interface IWorkoutConfig {
  // workout id
  workoutId: number;
  // name of the exercise
  exerciseId: string;
  type: TWorkoutType;
  mode: number;
  // balancing configuration
  exerciseJsonFile: string;
  // workout duration in seconds
  duration: number;
  // balancing category: normal/easy/hard etc.
  categoryId: EExerciseCategory;
  // exercise where workout belong to
  categoryGroupId: number;
  // mental domains trained in this workout
  domains: IDomainWeight[];
  // is workout available only for premium users
  premium?: boolean;
  // platforms where workout is available, everywhere if not set
  platforms?: WorkoutPlatform[];
}

export interface IWorkoutConfigMap {
  [workoutId: number]: IWorkoutConfig;
}

export interface IVersionCache {
  variant: string;
  version: number;
  syncTimestamp?: number;
}

export interface IWorkoutConfigApiResponse {
  count: number;
  variant: string;
  version: number;
  workouts: IWorkoutConfigMap;
}

export class ConfigError extends SharedErrors.BaseError {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ConfigError.prototype);
    this.feature = 'Config';
    this.errorType = NO_ALERT_TYPE;
  }
}

export interface IWorkoutDomainWeight {
  domainId: EDomain;
  workoutId: number;
  weight: number;
}

export type TWeightsByDomainMap = Map<EDomain, IWorkoutDomainWeight[]>;

export class ConfigNotLoadedError extends ConfigError {
  constructor(workoutId: number) {
    const message = `Workout ${workoutId} configuration not loaded`;
    super(message);
  }
}

export interface IUpdateVersionPayload {
  variant: string;
  version: number;
}
