import { Button, Checkbox, EButtonColor } from '@web_opxp/components';
import React, { useState } from 'react';
import { ETagType, renderStringToReact, useTranslation } from '../../language';
import { ICookieConsent } from '../types';

export interface ICookieSettingsProps {
  firebase: boolean;
  userData: boolean;
  privacyUrl: string;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (consent: ICookieConsent) => void;
}

const CookieSettings: React.FC<ICookieSettingsProps> = ({
  firebase,
  userData,
  privacyUrl,
  visible,
  onCancel,
  onSubmit,
}) => {
  const [firebaseConsent, setFirebaseConsent] = useState(firebase);
  const [userDataConsent, setUserDataConsent] = useState(userData);
  const { t } = useTranslation();
  if (!visible) {
    return null;
  }
  const onSaveConsent = () => {
    onSubmit({
      firebase: firebaseConsent,
      userData: userDataConsent,
    });
  };
  const onAcceptAll = () => {
    onSubmit({
      firebase: true,
      userData: true,
    });
  };
  const privacyPolicyLink = `<a href="${privacyUrl}" rel="noopener noreferrer" target="_blank">${t(
    'common.common:Cookies.LinkLabel'
  )}</a>`;
  const cookieDescription = renderStringToReact(t('common.common:Cookies.Description'), ETagType.p);
  const firebaseCheckboxLabel = renderStringToReact(
    t('common.common:Cookies.Option', { privacyPolicyLink }),
    ETagType.span
  );
  const firebaseConsentHandler = () => {
    const newConsent = !firebaseConsent;
    setFirebaseConsent(newConsent);
    setUserDataConsent(newConsent);
  };
  const optimizeCheckboxLabel = renderStringToReact(t('common.common:Cookies.OptimizeHtml'), ETagType.span);
  const optimizeConsentHandler = () => setUserDataConsent(!userDataConsent);
  return (
    <>
      <div className="cookie-consent-modal-overlay" />
      <div className="cookie-consent-modal">
        <div className="modal-header">
          <h2>{t('common.common:Cookies.Settings')}</h2>
          <span
            className="close-button"
            role="button"
            tabIndex={0}
            onClick={() => onCancel()}
            title={t('common.common:Cookies.Close') ?? ''}>
            x
          </span>
        </div>
        {cookieDescription}
        <div className="modal-form">
          <Checkbox
            className="consent-checkbox"
            hidePlaceholder={true}
            id="firebaseconsent"
            checked={firebaseConsent}
            onChange={firebaseConsentHandler}>
            {firebaseCheckboxLabel}
          </Checkbox>
          <Checkbox
            className="consent-checkbox"
            hidePlaceholder={true}
            id="optimizeconsent"
            disabled={!firebaseConsent}
            checked={userDataConsent}
            onChange={optimizeConsentHandler}>
            {optimizeCheckboxLabel}
          </Checkbox>
        </div>
        <div className="buttons-container">
          <Button color={EButtonColor.white} label={t('common.common:Cookies.CTA.Save')} onClick={onSaveConsent} />
          <Button color={EButtonColor.blue} label={t('common.common:Cookies.CTA.ActivateAll')} onClick={onAcceptAll} />
        </div>
      </div>
    </>
  );
};

export default CookieSettings;
