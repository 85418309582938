import * as React from 'react';

import './SocialBar.scss';

import { NavBar, NavItem } from '../navBar';

export interface ISocialIconProps {
  iconSrc: string;
  link: string;
  title: string;
}

export interface ISocialBarProps {
  icons: ISocialIconProps[];
}

const SocialBar: React.FC<ISocialBarProps> = ({ icons }) => {
  const items = icons.map(({ iconSrc, link, title }, index) => {
    const icon = <img src={iconSrc} alt={title} />;
    return (
      <NavItem key={`${index}${link}${title}`} title={title}>
        <a href={link}>{icon}</a>
      </NavItem>
    );
  });
  return <NavBar className="footer-icons-bar">{items}</NavBar>;
};
export default SocialBar;
