import * as ActionTypes from './actionTypes';
import { IUrlParams } from './attributionService';
import { EBannerMode, IMessage } from './types';

export interface IFirebaseState {
  initDone: boolean;
  // ready flag
  ready: boolean;
  // apps started in current session
  analytics: boolean;
  config: boolean;
  messaging: boolean;
  // list of messages received in foreground
  messages: IMessage[];
  // utm parameters found in url
  utm: IUrlParams;
  // consent for firebase given
  firebase: boolean;
  // consent for google userData given
  userData: boolean;
  // cookie banner mode
  mode: EBannerMode;
  // user choice is submitted
  submitted: boolean;
  // settings dialog open
  settings: boolean;
}

export const initialState: IFirebaseState = {
  initDone: false,
  ready: false,
  analytics: false,
  config: false,
  messaging: false,
  messages: [],
  utm: {},
  firebase: false,
  userData: false,
  // default banner mode changed to Postponable as requested in BW-4143
  mode: EBannerMode.Postponable,
  submitted: false,
  settings: false,
};

export const statePropName = 'firebase';

export default function firebaseReducer(
  state: IFirebaseState = initialState,
  action: ActionTypes.IFirebaseActions
): IFirebaseState {
  switch (action.type) {
    case ActionTypes.FIREBASE_INIT: {
      return {
        ...initialState,
      };
    }
    case ActionTypes.FIREBASE_INITIALIZED: {
      return {
        ...state,
        initDone: true,
      };
    }
    case ActionTypes.FIREBASE_READY: {
      const { analytics, config, messaging } = action;
      return {
        ...state,
        ready: true,
        analytics,
        config,
        messaging,
      };
    }
    case ActionTypes.FIREBASE_MESSAGING_ENABLED: {
      return {
        ...state,
        messaging: true,
      };
    }
    case ActionTypes.FIREBASE_MESSAGE_RECEIVED: {
      const message = action.payload;
      return {
        ...state,
        messages: [...state.messages, message],
      };
    }
    case ActionTypes.FIREBASE_MESSAGE_SHOWN: {
      const { message } = action;
      const messages = state.messages.filter((m) => m.messageId !== message.messageId);
      return {
        ...state,
        messages,
      };
    }
    case ActionTypes.GDPR_UPDATE_UTM: {
      const utm = action.utm;
      return {
        ...state,
        utm,
      };
    }
    case ActionTypes.GDPR_UPDATE_CONSENT: {
      const firebase = !!action.consent.firebase;
      const userData = !!action.consent.userData;
      return {
        ...state,
        firebase,
        userData,
        submitted: true,
        settings: false,
      };
    }
    case ActionTypes.GDPR_SETTINGS_TOGGLE: {
      const settings = !state.settings;
      return {
        ...state,
        settings,
      };
    }
    case ActionTypes.BANNER_MODE_UPDATE: {
      return {
        ...state,
        mode: action.mode,
      };
    }
  }
  return state;
}
