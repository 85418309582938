import * as React from 'react';
import NavBar, { NavItem } from '../navBar';
import { IFooterMenuItem } from './types';

export interface IFooterMenuProps {
  footerMenuItems: IFooterMenuItem[];
  extraMenuItems?: IFooterMenuItem[];
}

const FooterMenu: React.FC<IFooterMenuProps> = ({ footerMenuItems, extraMenuItems }) => {
  const menu = footerMenuItems.map((item, index) => (
    <NavItem key={index}>
      <a href={item.link} target={item.target} onClick={item.onClick}>
        {item.title}
      </a>
    </NavItem>
  ));
  const extraMenu = extraMenuItems ? (
    <NavBar className="nav-extras">
      {extraMenuItems.map((item, index) => (
        <NavItem key={index}>
          <a href={item.link} target={item.target} onClick={item.onClick}>
            {item.title}
          </a>
        </NavItem>
      ))}
    </NavBar>
  ) : null;

  return (
    <>
      <NavBar>{menu}</NavBar>
      {extraMenu}
    </>
  );
};

export default FooterMenu;
