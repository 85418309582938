import * as ActionTypes from './actionTypes';

export const initLottieAction: (assetsUrl: string) => ActionTypes.IInitLottieAction = (assetsUrl) => ({
  type: ActionTypes.LOTTIE_INIT,
  assetsUrl,
});

export const lottieLoadAction: (
  name: string,
  autoplay?: boolean,
  loop?: boolean,
  dontKeep?: boolean,
  id?: string,
  stopOn?: number
) => ActionTypes.ILottieLoadAction = (name, autoplay, loop, dontKeep, id, stopOn) => ({
  type: ActionTypes.LOTTIE_LOAD,
  name,
  autoplay,
  loop,
  dontKeep,
  id,
  stopOn,
});

export const lottieLoadedAction: (name: string) => ActionTypes.ILottieLoadedAction = (name) => ({
  type: ActionTypes.LOTTIE_LOADED,
  name,
});

export const lottieStartAction: (id: string) => ActionTypes.ILottieStartAction = (id) => ({
  type: ActionTypes.LOTTIE_START,
  id,
});

export const lottieCompleteAction: (id: string) => ActionTypes.ILottieCompleteAction = (id) => ({
  type: ActionTypes.LOTTIE_COMPLETE,
  id,
});

export const lottieForceCacheAction: (name: string) => ActionTypes.ILottieForceCacheAction = (name) => ({
  type: ActionTypes.LOTTIE_FORCE_CACHE,
  name,
});
