export function getUnixTimestamp(jsTimestamp: number = Date.now()) {
  return Math.ceil(jsTimestamp / 1000);
}

export function unixToJsTimestamp(unixTimestamp = -1) {
  // timestamp === -1 means 'now' when sent to backend
  return unixTimestamp === -1 ? Date.now() : unixTimestamp * 1000;
}

export function unixTimestampToDate(unixTimestamp?: number) {
  return new Date(unixToJsTimestamp(unixTimestamp));
}
