// constants used to create fallback histogram
export const FALLBACK_HISTOGRAM_NUM_BINS = 30;
export const FALLBACK_HISTOGRAM_MAX_SCORE = 200;
export const FALLBACK_HISTOGRAM_USER_MULTIPLIER = 20;

// values used to calculate goal from histogram
export const PERFORMANCE_THRESHOLD = 0.5;
export const GOAL_RATIO = 0.1;

// max cap for performance (aka comparison) calculation
export const MAX_SCORE_CAP_PERCENTAGE = 0.99;

// histogram cache expiration timeout
export const HISTOGRAM_CACHE_EXPIRATION_TIMEOUT_MS = 24 * 60 * 60 * 1000; // 24 hours

export const NORMALIZED_MAX_SCORE = 10000;
