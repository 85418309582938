import * as React from 'react';
import './HeroSection.scss';
import HeroSectionCircle from './HeroSectionCircle';
import Section, { ESectionAlign, ESectionColor } from './Section';

export interface IHeroSectionProps {
  className?: string;
  imgSrc?: string;
  lottie?: string;
  lottieAuto?: boolean;
  lottieLoop?: boolean;
  noPadding?: boolean;
}

export default function HeroSection({
  className = '',
  imgSrc = '',
  lottie,
  lottieAuto = true,
  lottieLoop = true,
  noPadding,
  children,
}: React.PropsWithChildren<IHeroSectionProps>) {
  return (
    <Section
      color={ESectionColor.blue}
      align={ESectionAlign.left}
      noPadding={noPadding}
      className={`hero-section ${className}`}>
      <div className="row">
        <div className="col-6 col-m-4 hero-section-content">{children}</div>
        <div className="col-1 col-m-4" />
        <HeroSectionCircle
          className="col-5 col-m-4 hero-section-illustration"
          imgSrc={imgSrc}
          lottie={lottie}
          lottieAuto={lottieAuto}
          lottieLoop={lottieLoop}
        />
      </div>
    </Section>
  );
}
