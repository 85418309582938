import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as ActionTypes from './actionTypes';
import { ILoginPayload, TListOfUsersPasskeys } from './types';

export const statePropName = 'passkeyAuth';

export interface IPasskeyAuthState {
  ready: boolean;
  started: boolean;
  loading: boolean;
  error?: string;
  passkeys?: TListOfUsersPasskeys;
}

export const initialState: IPasskeyAuthState = {
  ready: false,
  started: false,
  loading: false,
};

const passkeyAuthSlice = createSlice({
  name: statePropName,
  initialState,
  reducers: {
    initPasskey: () => {
      return { ...initialState };
    },
    featureReady: (state: IPasskeyAuthState, action: ActionTypes.TFeatureReadyAction) => {
      return {
        ...state,
        ready: action.payload,
        started: true,
      };
    },
    startLogin: (state: IPasskeyAuthState) => {
      return {
        ...state,
        loading: true,
      };
    },
    loginFailed: (state: IPasskeyAuthState, action: ActionTypes.TFailureAction) => {
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    },
    loginSuccess: (state: IPasskeyAuthState, action: PayloadAction<ILoginPayload>) => {
      return {
        ...state,
        loading: false,
      };
    },
    startRegistration: (state: IPasskeyAuthState, action: ActionTypes.TStartRegistrationAction) => {
      return {
        ...state,
        loading: true,
      };
    },
    registrationFailed: (state: IPasskeyAuthState, action: ActionTypes.TFailureAction) => {
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    },
    registrationDone: (state: IPasskeyAuthState, action: PayloadAction<ILoginPayload>) => {
      return {
        ...state,
        loading: false,
      };
    },
    requestListOfPasskeys: (state: IPasskeyAuthState) => {
      return {
        ...state,
        loading: true,
      };
    },
    updateListOfPasskeys: (state: IPasskeyAuthState, action: PayloadAction<TListOfUsersPasskeys>) => {
      return {
        ...state,
        loading: false,
        passkeys: action.payload,
      };
    },
    deleteUsersPasskey: (state: IPasskeyAuthState, action: ActionTypes.TDeleteUsersPasskeyAction) => {
      return {
        ...state,
        loading: true,
      };
    },
    deleteUsersPasskeySuccess: (state: IPasskeyAuthState, action: ActionTypes.TDeleteUsersPasskeyAction) => {
      const allPasskeys = state.passkeys ?? [];
      const passkeys = allPasskeys.filter(({ id }) => id !== action.payload);
      return {
        ...state,
        loading: false,
        passkeys,
      };
    },
    upgradeUserToPasskey: (state: IPasskeyAuthState) => {
      return {
        ...state,
        loading: true,
      };
    },
    upgradeUserToPasskeySuccess: (state: IPasskeyAuthState) => {
      return {
        ...state,
        loading: false,
      };
    },
    upgradeUserToPasskeyFailed: (state: IPasskeyAuthState, action: ActionTypes.TFailureAction) => {
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    },
  },
});

export const PasskeyAuthActions = passkeyAuthSlice.actions;
export default passkeyAuthSlice.reducer;
