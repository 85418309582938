import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';
import { EDomain, EExerciseCategory, IDomainWeight } from '../../common/types';
import { CONFIG_CACHE_EXPIRATION_TIMEOUT } from './constants';
import { IWorkoutConfig, IWorkoutConfigMap, IWorkoutDomainWeight, TWeightsByDomainMap, TWorkoutType } from './types';

export const getWorkoutsArray = (config: IWorkoutConfigMap) => values(config);

export const getWorkoutDomain = (workoutConfig: IWorkoutConfig) => {
  const domains = workoutConfig.domains;
  const biggest = maxBy(domains, 'weight');
  return biggest?.id;
};

export const filterByExerciseType = (workouts: IWorkoutConfig[]) => filterByType(workouts, 'exercise');

export const filterByAssessmentType = (workouts: IWorkoutConfig[]) => filterByType(workouts, 'assessment');

export const filterByChallengeType = (workouts: IWorkoutConfig[]) => filterByType(workouts, 'challenge');

export const filterBySelfassessmentType = (workouts: IWorkoutConfig[]) =>
  filterByType(workouts, 'selfAssessmentQuestionaire');

export const filterByDomain = (workouts: IWorkoutConfig[], domain: EDomain) =>
  filter(workouts, (workout) => {
    const workoutDomain = getWorkoutDomain(workout);
    return workoutDomain === domain;
  });

export const getExerciseIds = (workouts: IWorkoutConfig[]) =>
  map(uniqBy(workouts, 'exerciseId'), (workout) => workout.exerciseId);

export const getCategoryGroupId = (config: IWorkoutConfigMap, exerciseId: string) => {
  // compare lowercased id to allow this search also for ids coming from url
  const workouts = filter(
    getWorkoutsArray(config),
    (workout) => workout.exerciseId.toLowerCase() === exerciseId.toLowerCase()
  );
  // we assume that all workouts with same exerciseId must have same categoryGroupId
  return workouts && workouts.length > 0 ? workouts[0].categoryGroupId : undefined;
};

export const filterByCategoryGroupId = (workouts: IWorkoutConfig[], categoryGroupId: number | undefined) =>
  filter(workouts, ['categoryGroupId', categoryGroupId]);

export const getWorkoutIds = (workouts: IWorkoutConfig[]) => map(workouts, (workout) => workout.workoutId);

export const filterByType = (workouts: IWorkoutConfig[], type: TWorkoutType) => filter(workouts, ['type', type]);

export const filterByTypes = (workouts: IWorkoutConfig[], types: TWorkoutType[]) =>
  filter(workouts, (w) => types.includes(w.type));

// platforms should be either undefined or include 'web' key to be considered
// as released for web
export const isReleasedForWeb = (workout: IWorkoutConfig) => !workout.platforms || workout.platforms.includes('web');

export const filterByPlatform = (workouts: IWorkoutConfig[]) => filter(workouts, isReleasedForWeb);

export const filterByIds = (workouts: IWorkoutConfig[], workoutIds: number[]) => {
  return filter(workouts, (workout) => workoutIds.includes(workout.workoutId));
};

export const isCacheExpired = (syncTimestamp?: number) => {
  if (!syncTimestamp) {
    return true;
  }
  const current = Date.now();
  return current - syncTimestamp >= CONFIG_CACHE_EXPIRATION_TIMEOUT;
};

export const mapToWorkoutDomainWeight = (workouts: IWorkoutConfig[], cfqDomains = false) =>
  flatten(
    map(workouts, (workout) => {
      const { domains, workoutId } = workout;
      const filterPredicate = cfqDomains
        ? (domain: IDomainWeight) => domain.id >= EDomain.SelfRegulation
        : (domain: IDomainWeight) => domain.id < EDomain.SelfRegulation;
      const filteredDomains = filter(domains, filterPredicate);
      return map(filteredDomains, (domain) => {
        const workoutDomainWeight: IWorkoutDomainWeight = {
          domainId: domain.id,
          weight: domain.weight,
          workoutId,
        };
        return workoutDomainWeight;
      });
    })
  );

export const groupByDomain = (workouts: IWorkoutConfig[], cfqDomains = false) => {
  const weightsByDomain: TWeightsByDomainMap = new Map();
  forEach(mapToWorkoutDomainWeight(workouts, cfqDomains), (workoutDomainWeight) => {
    const domainId = workoutDomainWeight.domainId;
    const weights = weightsByDomain.get(domainId) || [];
    weights.push(workoutDomainWeight);
    weightsByDomain.set(domainId, weights);
  });
  return weightsByDomain;
};

export const getCategoryGroupIds = (workouts: IWorkoutConfig[]) =>
  map(uniqBy(workouts, 'categoryGroupId'), (workout) => workout.categoryGroupId);

export const isChallengeWorkout = (workout: IWorkoutConfig) => {
  return workout.type === 'challenge';
};

export const getWorkoutId = (categoryId: EExerciseCategory, categoryGroupId: number) =>
  (categoryId + 1) * 100 + categoryGroupId;
