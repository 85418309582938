import * as React from 'react';

import './ProgressItem.scss';

import CircleIndicator from './CircleIndicator';
import { ProgressItemState } from './types';

export interface IProgressItemProps {
  name: string;
  label: string;
  value?: React.ReactNode;
  state?: ProgressItemState;
  progress?: number;
  // icon overrides the circle indicator
  iconSrc?: string;
  // apply additional styles to hover state
  selectable?: boolean;
  // callback for click
  onSelect?: () => void;
}

export const ProgressItem: React.FC<IProgressItemProps> = ({
  name,
  label,
  value = '',
  state = ProgressItemState.None,
  progress,
  iconSrc,
  selectable = false,
  onSelect,
}) => {
  const hoverClass = selectable && onSelect ? ' selectable' : '';
  const clickHandler = () => selectable && onSelect && onSelect();
  const inactiveClass = state === ProgressItemState.None ? 'inactive' : '';
  const doneClass = state === ProgressItemState.Done ? 'done' : '';
  return (
    <div className={`segment ${name}${hoverClass}`} onClick={clickHandler}>
      <div className={`item ${doneClass}`}>
        {iconSrc ? (
          <img className="item-icon" src={iconSrc} alt={`icon ${label}`} />
        ) : (
          <CircleIndicator progress={progress} />
        )}
        <div className={`item-value`}>{value}</div>
      </div>
      <span className={`item-label ${inactiveClass} ${doneClass}`}>{label}</span>
    </div>
  );
};

export default ProgressItem;
