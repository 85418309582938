export enum EDomain {
  Assessment = 0,
  Speed = 1,
  Reasoning = 2,
  Attention = 3,
  Memory = 4,
  SelfRegulation = 5,
  CFQScore = 100,
  Forgetfulness = 101,
  Distractibility = 102,
  FalseTriggering = 103,
  Mood = 20001,
}

export interface IDomainWeight {
  id: EDomain;
  weight: number;
}

export const NO_ALERT_TYPE = 'noAlert';

export enum EExerciseCategory {
  Medium = 0,
  Easy = 1,
  Hard = 2,
}
