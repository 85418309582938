import { Icon, IconName } from '@blueprintjs/core';
import * as React from 'react';
import Badge from '../badge';
import './NavItem2.scss';

export interface INavItemProps {
  className?: string;
  title?: string;
  icon?: IconName | React.ReactNode;
  badge?: number | string;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
}

export const NavItem2: React.FC<React.PropsWithChildren<INavItemProps>> = ({
  children,
  className = '',
  title,
  icon,
  badge,
  onClick,
}) => {
  const customClass = className ? ` ${className}` : '';
  const badgeClass = badge ? ' has-badge' : '';
  const withIcon = icon ? ' has-icon' : '';
  const itemClass = `nav-item-2${customClass}${badgeClass}${withIcon}`;
  const menuBadge = badge ? (
    <Badge className="nav-item-badge" color="blue">
      {badge}
    </Badge>
  ) : null;
  const renderIcon = () => {
    if (!icon) {
      return null;
    }
    if (typeof icon === 'string') {
      return <Icon className="nav-item-icon" icon={icon as IconName} />;
    }
    return icon;
  };

  return (
    <li role="menuitem" className={itemClass} title={title} onClick={onClick}>
      {renderIcon()}
      {children}
      {menuBadge}
    </li>
  );
};

export default NavItem2;
