import { IApiService } from 'web_core_library';
import { AuthError, IRevalidateToken, IUnreadMessages } from './types';

export function createAuthService() {
  let ApiService: IApiService;
  const getUserRoles = <T>() => {
    const userId = ApiService.getUserId();
    if (!userId) {
      throw new AuthError('User not defined!');
    }
    return ApiService.action<T>('users', {
      method: 'get',
      params: {
        fields: 'roles',
      },
      urlParams: {
        userId,
      },
    });
  };

  const getMessagesSummary = () => {
    const userId = ApiService.getUserId();
    if (!userId) {
      throw new AuthError('User not defined!');
    }
    const filter = 'countunread';
    return ApiService.action<IUnreadMessages>('users_messages', {
      method: 'get',
      urlParams: {
        filter,
        userId,
      },
    });
  };

  const revalidateSessionToken = () => {
    return ApiService.action<IRevalidateToken>('session_validate', {
      method: 'post',
    });
  };

  const validate = (token: string) => {
    if (!token) {
      throw new Error('Can not validate empty token!');
    }
    return ApiService.action('validate', {
      method: 'post',
      token,
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    getUserRoles,
    getMessagesSummary,
    revalidateSessionToken,
    validate,
  };
  return service;
}

const AuthService = createAuthService();
export default AuthService;
