import { PayloadAction } from '@reduxjs/toolkit';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ApiService, TApiFetchResponse } from 'web_core_library';
import HistogramCacheService from './histogramCache';
import HistogramService from './histogramService';
import { HistogramActions } from './slice';
import { IHistogramBin, ILoadHistogramPayload, IWorkoutHistogram } from './types';
import { getFallbackHistogram } from './utils';

export function* initFeatureSaga() {
  yield call(HistogramService.init, ApiService);
  yield call(HistogramCacheService.init);
  yield put(HistogramActions.histogramReady());
}

export function* checkHistogramCache(workoutIds: number[], groupId: number) {
  // resulting histogram map
  const result: IWorkoutHistogram = {};
  // array of ids not found in cache
  const emptyCache: number[] = [];
  // check cache for requested id's
  for (const workoutId of workoutIds) {
    const cachedHistogram: IHistogramBin[] | undefined = yield call(HistogramCacheService.get, +workoutId, groupId);
    // if cache is undefined or invalid (empty or expired) try to load it from api again
    if (!cachedHistogram) {
      emptyCache.push(+workoutId);
    } else {
      result[workoutId] = cachedHistogram;
    }
  }
  return [result, emptyCache];
}

export function* loadHistogramsFromApi(workoutIds: number[], groupId: number, userId?: number) {
  const apiResponse: TApiFetchResponse<typeof HistogramService.getWorkoutHistogramForAgeGroup> = yield call(
    HistogramService.getWorkoutHistogramForAgeGroup,
    workoutIds,
    groupId,
    userId
  );
  return apiResponse.data.trainingworkouthistogram;
}

export function* loadMissingHistograms(workoutIds: number[], groupId: number) {
  // resulting histogram map
  const result: IWorkoutHistogram = {};
  // load missing histograms from api
  const histogram: IWorkoutHistogram = yield call(loadHistogramsFromApi, workoutIds, groupId);
  // cache received data
  for (const workoutId in histogram) {
    if (histogram.hasOwnProperty(workoutId)) {
      yield fork(HistogramCacheService.set, +workoutId, groupId, histogram[workoutId]);
      result[workoutId] = histogram[workoutId];
    }
  }
  return result;
}

export function* updateHistogramsState(result: IWorkoutHistogram, userId?: number) {
  for (const workoutId in result) {
    if (result.hasOwnProperty(workoutId)) {
      const histogramEmpty = !isArray(result[workoutId]) || isEmpty(result[workoutId]);
      const histogram = !histogramEmpty ? result[workoutId] : getFallbackHistogram();
      yield put(HistogramActions.updateHistogram(+workoutId, histogram, userId));
    }
  }
}

export function* loadHistogramSaga(action: PayloadAction<ILoadHistogramPayload>): Generator<any, void, any> {
  const { workoutIds, groupId, userId } = action.payload;

  if (!userId) {
    // result - resulting histogram map
    // emptyCache - array of ids not found in cache
    let [result, emptyCache]: [IWorkoutHistogram, Array<number>] = yield call(checkHistogramCache, workoutIds, groupId);
    // fetch histogram from api for all non cached ids
    if (emptyCache.length > 0) {
      const fetchedData: IWorkoutHistogram = yield call(loadMissingHistograms, emptyCache, groupId);
      result = {
        ...result,
        ...fetchedData,
      };
    }
    // put result into state
    yield call(updateHistogramsState, result);
    return;
  }
  // for custom users histograms we don't use cache for now
  const histogram = yield call(loadHistogramsFromApi, workoutIds, groupId, userId);
  yield call(updateHistogramsState, histogram, userId);
}

export default function* histogramWatcher() {
  yield all([
    takeEvery(HistogramActions.initHistogram, initFeatureSaga),
    takeEvery(HistogramActions.loadHistogram, loadHistogramSaga),
  ]);
}
