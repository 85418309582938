enum EAppRoutes {
  Welcome = '/welcome',
  Mascot = '/welcome/mascot',
  Error = '/error',
  NotAuthorized = '/not-authorized',
  Assessment = '/assessment',
  Evaluation = '/evaluation',
}

export enum EEvaluationRoutes {
  Intro = '/intro',
  PeerGroup = '/peergroup',
  Evaluation = '/evaluation',
  Schedule = '/schedule',
  SpecialNeeds = '/special-needs',
  Led = '/led',
}

export function isAssessmentPage(pathname: string) {
  return pathname.includes(EAppRoutes.Assessment);
}

function isEvaluationPage(pathname: string) {
  return pathname.includes(EAppRoutes.Evaluation);
}

export function isPostRegPage(pathname: string) {
  // TODO: add more routes after separating pages (BW-7363)
  return isAssessmentPage(pathname) || isEvaluationPage(pathname);
}

export function isWelcomePage(pathname: string) {
  return pathname === EAppRoutes.Welcome || pathname === '/';
}

export function isLPPage(pathname: string) {
  return pathname.includes('lp/');
}

export default EAppRoutes;
