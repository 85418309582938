import { all, call, takeEvery } from 'redux-saga/effects';
import { NotificationActions, TNotificationAction } from './actions';
import showMessage from './service';

export function* showToast(action: TNotificationAction) {
  const { intent, message } = action.payload;
  yield call(showMessage, intent, message);
}

export default function* messageWatcher() {
  yield all([takeEvery(NotificationActions.showNotification, showToast)]);
}
