import { IApiService } from 'web_core_library';
import { IDomainCalculation } from './types';

export function createAssessmentEvaluationService() {
  let ApiService: IApiService;

  const saveCycleEvaluation = (domaincalculation: IDomainCalculation[]) => {
    const userId = ApiService.getUserId();
    if (!userId) {
      throw new Error('User not defined!');
    }
    return ApiService.action('domain_calculation', {
      method: 'post',
      urlParams: {
        userId,
      },
      data: {
        domaincalculation,
      },
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    saveCycleEvaluation,
  };
  return service;
}

const AssessmentEvaluationService = createAssessmentEvaluationService();
export default AssessmentEvaluationService;
