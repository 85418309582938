import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDomainCalculation } from './types';

export const statePropName = 'assessmentEvaluation';

export interface IAssessmentEvaluationState {
  loading: boolean;
  saving: boolean;
}

export const initialState: IAssessmentEvaluationState = {
  loading: true,
  saving: false,
};

export interface ISaveEvaluationPayload {
  cycleId: number;
  domaincalculation: IDomainCalculation[];
}

const assessmentEvaluationSlice = createSlice({
  name: statePropName,
  initialState,
  reducers: {
    initAssessmentEvaluation: (state: IAssessmentEvaluationState) => {},
    assessmentEvaluationReady: (state: IAssessmentEvaluationState) => {
      state.loading = false;
    },
    saveCycleEvaluationAction: (state: IAssessmentEvaluationState, action: PayloadAction<ISaveEvaluationPayload>) => {
      state.saving = true;
    },
    cycleEvaluationSaved: (state: IAssessmentEvaluationState) => {
      state.saving = false;
    },
  },
});

export const AssessmentEvaluationActions = assessmentEvaluationSlice.actions;
export default assessmentEvaluationSlice.reducer;
