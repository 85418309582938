import * as React from 'react';

import './UserAvatar.scss';

export interface IUserAvatarProps {
  className?: string;
  picture?: string;
  size?: 'big' | 'small';
}

export const UserAvatar = ({ picture, className, size }: IUserAvatarProps) => {
  const sizeClass = size ? ` ${size}` : '';
  const customClass = className ? ` ${className}` : '';
  const avatarClass = `user-avatar${sizeClass}${customClass}`;
  const avatarStyles: React.CSSProperties = {};
  if (picture) {
    avatarStyles.backgroundImage = `url("${picture}")`;
  }
  return <span className={avatarClass} style={avatarStyles} />;
};

export default UserAvatar;
