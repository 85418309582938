import { Popover, PopoverInteractionKind, PopoverPosition } from '@blueprintjs/core';
import React, { ReactNode, useState } from 'react';
import './NavMenu2.scss';

interface INavMenuProps {
  disabled?: boolean;
  content: ReactNode;
}

export const NavMenu2: React.FC<React.PropsWithChildren<INavMenuProps>> = ({ children, disabled, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="nav-menu-2">
      <Popover
        disabled={disabled}
        usePortal={false}
        content={<div className="nav-menu-container">{content}</div>}
        isOpen={isOpen}
        onInteraction={(state) => setIsOpen(state)}
        interactionKind={PopoverInteractionKind.HOVER}
        position={PopoverPosition.BOTTOM_RIGHT}>
        {children}
      </Popover>
    </div>
  );
};
export default NavMenu2;
