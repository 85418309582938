import { EDomain } from '../../common/types';

export function getSessionFocusDomain<T extends { workoutId: number }>(
  sessionWorkouts: T[],
  domainWeightsForAllWorkouts: Map<number, Map<number, number>>
) {
  const domainSums: Map<number, number> = new Map();
  let focus = EDomain.Speed;
  let maxValue = 0;
  for (const workout of sessionWorkouts) {
    for (const [domainId, domainWeights] of Array.from(domainWeightsForAllWorkouts)) {
      const domainValue = domainWeights.get(workout.workoutId || -1) ?? 0;
      let domainSum = domainSums.get(domainId) ?? 0;
      domainSum = domainSum + domainValue;
      domainSums.set(domainId, domainSum);
      if (domainSum > maxValue) {
        maxValue = domainSum;
        focus = domainId;
      }
    }
  }
  return focus;
}
