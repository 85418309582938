import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IUser,
  TExchangeTokenFunction,
  TRedirectLoginFunction,
  TRedirectLogoutFunction,
  TUpdateSearchParamsFunction,
} from './types';

export const statePropName = 'auth';

export interface IAuthState {
  session?: string;
  authorized: boolean;
  user?: IUser;
  expires?: number;
  // unread status: messages
  regular: number;
  // unread status: friendship requests
  friendship: number;
  // unread status: system requests
  system: number;
}

export const initialState: IAuthState = {
  session: undefined,
  authorized: false,
  regular: 0,
  friendship: 0,
  system: 0,
};

export interface IUpdateUserPayload {
  session: string;
  expires: number;
}

export interface IRestoreAuthPayload {
  getCurrentSearchParams: () => URLSearchParams;
  exchangeAccessToken: TExchangeTokenFunction;
  updateSearchParams: TUpdateSearchParamsFunction;
  refreshToken: () => void;
  redirectLogin: TRedirectLoginFunction;
  redirectLogout: TRedirectLogoutFunction;
  skipAccessToken?: boolean;
}

export const authSlice = createSlice({
  name: statePropName,
  initialState,
  reducers: {
    restoreAuthFail: (state: IAuthState) => {
      // Reset the state to the initial state
      return initialState;
    },
    updateUserAuth: (state: IAuthState, action: PayloadAction<IUpdateUserPayload>) => {
      const { session, expires } = action.payload;
      state.session = session;
      state.expires = expires;
    },
    updateUser: (state: IAuthState, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    restoreAuthSuccess: (state: IAuthState) => {
      state.authorized = true;
    },
    updateMessagesSummary: (
      state: IAuthState,
      action: PayloadAction<{ regular: number; friendship: number; system?: number }>
    ) => {
      const { regular, friendship, system } = action.payload;
      state.regular = regular;
      state.friendship = friendship;
      state.system = system ?? 0;
    },
    getMessagesSummary: (state: IAuthState) => {},
    redirectToSSO: (state: IAuthState) => {},
    logout: (state: IAuthState) => {},
    authRestore: (state: IAuthState, action: PayloadAction<IRestoreAuthPayload>) => {},
    authRefresh: (state: IAuthState) => {},
    authRefreshDone: (state: IAuthState) => {},
    startTokenRefreshTask: (state: IAuthState) => {},
    refreshSessionToken: (state: IAuthState) => {},
  },
});

export const AuthActions = authSlice.actions;
export default authSlice.reducer;
