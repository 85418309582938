import { MessagePayload } from 'firebase/messaging';

export interface IEventParam {
  key: string;
  value: string | number;
}

export interface IEventActionPayload {
  eventName: string;
  parameters?: IEventParam[];
}

export interface IAnalyticsEventData {
  [key: string]: string | number | undefined;
}

export type TRemoteConfigValue = string | number | boolean | object;

export type TMessageHandler = (message: MessagePayload) => void;

export interface IMessage {
  messageId: string;
  title?: string;
  body?: string;
  link?: string;
}

export enum EBannerMode {
  Mandatory = -1,
  NoBanner = 0,
  Postponable = 1,
}

export interface ICookieConsent {
  firebase: boolean;
  userData: boolean;
}
