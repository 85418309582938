import { SharedErrors } from 'web_core_library';

// ids used to identify the stat
export interface IUserStatId {
  statType: number;
  statId: number;
  statComponentId: number;
}

// payload with stat value
export interface IUserStat extends IUserStatId {
  valueInt: number;
  valueTxt: string;
}

// additional properties received from backend
export interface IUserStatGet extends IUserStat {
  lastEdit: number;
}

export interface IUserStatPost extends IUserStat {
  operator: string;
  time: number;
}

// data structure received from backend on get request
export interface IGetUserStatsResult {
  stats: IUserStatGet[];
}

export class StatsError extends SharedErrors.BaseError {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, StatsError.prototype);
    this.feature = 'Stats';
  }
}

export interface IUserPeerGroup {
  categoryId: number;
  groupId: number;
  inserted: number;
}

export interface IGetUserPeerGroupResult {
  peergroups: IUserPeerGroup[];
}

export interface IUserStatGetCache {
  stats: IUserStatGet[];
  timestamp: number;
}

export class StatsCacheError extends StatsError {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, StatsCacheError.prototype);
    this.feature = 'Stats_cache';
  }
}

export interface IStatsPayload {
  stats: IUserStat[];
  userId?: number;
}

export interface IStatsQueuePayload {
  statsQueue: IUserStat[];
  userId?: number;
}

export interface IUpdatePeerGroupsPayload {
  peergroups: IUserPeerGroup[];
  userId?: number;
}
