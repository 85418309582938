import { EDomain } from '../../common/types';
import { isChallengeWorkoutId } from '../../common/utils';
import { IConfigState, statePropName } from './slice';
import { ConfigNotLoadedError } from './types';
import * as Utils from './utils';

export interface IAppState {
  // this feature state
  [statePropName]: IConfigState;
  // other features we don't care of
  [otherKeys: string]: any;
}

const getConfigState = (state: IAppState) => state[statePropName];

export const isConfigLoading = (state: IAppState) => getConfigState(state).loading;

export const getConfigVariant = (state: IAppState) => getConfigState(state).variant;

export const getConfigVersion = (state: IAppState) => getConfigState(state).version;

const getWorkoutsConfig = (state: IAppState) => getConfigState(state).workouts;

export const getAllWorkouts = (state: IAppState) => {
  const config = getWorkoutsConfig(state);
  return Utils.getWorkoutsArray(config);
};

export const getDomainExerciseIds = (state: IAppState, domain: EDomain) => {
  const allWorkouts = getAllWorkouts(state);
  const exercises = Utils.filterByExerciseType(allWorkouts);
  const domainExercises = Utils.filterByDomain(exercises, domain);
  return Utils.getExerciseIds(domainExercises);
};

export const getCategoryGroupId = (state: IAppState, exerciseId: string) =>
  Utils.getCategoryGroupId(getWorkoutsConfig(state), exerciseId);

export const getCategoryGroupIdForWorkout = (state: IAppState, workoutId: number) => {
  const exerciseId = getWorkoutExerciseId(state, workoutId);
  return Utils.getCategoryGroupId(getWorkoutsConfig(state), exerciseId);
};

export const getWorkoutConfig = (state: IAppState, workoutId: number) => getWorkoutsConfig(state)[workoutId];

export const isWorkoutPremium = (state: IAppState, workoutId: number) => {
  const config = getWorkoutConfig(state, workoutId);
  if (!config) {
    return true;
  }
  return config.premium;
};

export const getWorkoutExerciseId = (state: IAppState, workoutId: number, ignoreChallenge = false) => {
  const config = getWorkoutConfig(state, workoutId);
  if (!config) {
    return '';
  }
  return !ignoreChallenge ? config.exerciseId : config.exerciseId.replace('Challenge', '');
};

export const getWorkoutDifficulty = (state: IAppState, workoutId: number) => {
  const config = getWorkoutsConfig(state)[workoutId];
  if (!config) {
    return '';
  }
  return config.categoryId;
};

export const getWorkoutDuration = (state: IAppState, workoutId: number) => {
  const config = getWorkoutConfig(state, workoutId);
  if (!config) {
    throw new ConfigNotLoadedError(workoutId);
  }
  return config.duration;
};

export const getExerciseIds = (state: IAppState) => {
  const allWorkouts = getAllWorkouts(state);
  const exercises = Utils.filterByExerciseType(allWorkouts);
  return Utils.getExerciseIds(exercises);
};

export const isWorkoutReleasedForWeb = (state: IAppState, workoutId: number) => {
  const isLoading = isConfigLoading(state);
  if (isLoading) {
    throw new ConfigNotLoadedError(workoutId);
  }
  const config = getWorkoutConfig(state, workoutId);
  if (!config) {
    // if given workout not found in config - its not supported
    return false;
  }
  return Utils.isReleasedForWeb(config);
};

export const getWorkoutType = (state: IAppState, workoutId: number) => {
  const config = getWorkoutConfig(state, workoutId);
  if (!config) {
    throw new ConfigNotLoadedError(workoutId);
  }
  return config.type;
};

export const isOnboardingWorkout = (state: IAppState, workoutId: number) => {
  const workoutType = getWorkoutType(state, workoutId);
  return workoutType === 'assessment';
};

export const isAssessmentWorkout = (state: IAppState, workoutId: number) => {
  const workoutType = getWorkoutType(state, workoutId);
  return workoutType === 'assessment' || workoutType === 'selfAssessmentQuestionaire';
};

export const isCFQWorkout = (state: IAppState, workoutId: number) => {
  const workoutType = getWorkoutType(state, workoutId);
  return workoutType === 'selfAssessmentQuestionaire';
};

export const getWorkoutIdsOfCategoryGroup = (state: IAppState, workoutId: number) => {
  const workoutType = getWorkoutType(state, workoutId);
  const categoryGroupId = getCategoryGroupIdForWorkout(state, workoutId);
  const allWorkouts = getAllWorkouts(state);
  // we want only workouts of the same category group
  const workoutsOfCategoryGroup = Utils.filterByCategoryGroupId(allWorkouts, categoryGroupId);
  // we want only web compatible workouts
  const filteredByPlatform = Utils.filterByPlatform(workoutsOfCategoryGroup);
  // we want workouts of the same type (assessment/exercise)
  const filteredByType = Utils.filterByType(filteredByPlatform, workoutType);
  return Utils.getWorkoutIds(filteredByType);
};

export const getWorkoutCategoryId = (state: IAppState, workoutId: number) => {
  const config = getWorkoutConfig(state, workoutId);
  if (!config) {
    throw new ConfigNotLoadedError(workoutId);
  }
  return config.categoryId;
};

export const getExerciseJsonFile = (state: IAppState, workoutId: number) => {
  const config = getWorkoutConfig(state, workoutId);
  if (!config) {
    throw new ConfigNotLoadedError(workoutId);
  }
  return config.exerciseJsonFile;
};

export const getWorkoutDomainsDistribution = (state: IAppState, workoutId: number) => {
  const config = getWorkoutConfig(state, workoutId);
  if (!config) {
    return [];
  }
  return config.domains;
};

const getDomains = (state: IAppState, cfqDomains: boolean) => {
  const allWorkouts = getAllWorkouts(state);
  return Utils.groupByDomain(allWorkouts, cfqDomains);
};

export const getDomainWeights = (state: IAppState) => getDomains(state, false);

export const getCognitiveFaluresWeights = (state: IAppState) => getDomains(state, true);

export const filterAssessmentWorkoutIds = (state: IAppState, workoutIds: number[]) => {
  const allWorkouts = getAllWorkouts(state);
  const filteredByIds = Utils.filterByIds(allWorkouts, workoutIds);
  const filteredByType = Utils.filterByAssessmentType(filteredByIds);
  return Utils.getWorkoutIds(filteredByType);
};

export const filterSelfassessmentWorkoutIds = (state: IAppState, workoutIds: number[]) => {
  const allWorkouts = getAllWorkouts(state);
  const filteredByIds = Utils.filterByIds(allWorkouts, workoutIds);
  const filteredByType = Utils.filterBySelfassessmentType(filteredByIds);
  return Utils.getWorkoutIds(filteredByType);
};

export const getAllSupportedExercisesWorkoutIds = (state: IAppState) => {
  const allWorkouts = getAllWorkouts(state);
  const filteredByPlatform = Utils.filterByPlatform(allWorkouts);
  const exercises = Utils.filterByExerciseType(filteredByPlatform);
  return Utils.getWorkoutIds(exercises);
};

export const getAllSupportedExercisesGroupIds = (state: IAppState) => {
  const allWorkouts = getAllWorkouts(state);
  const filteredByPlatform = Utils.filterByPlatform(allWorkouts);
  const exercises = Utils.filterByExerciseType(filteredByPlatform);
  return Utils.getCategoryGroupIds(exercises);
};

export const isChallengeWorkout = (state: IAppState, workoutId: number) => {
  const config = getWorkoutConfig(state, workoutId);
  if (!config) {
    return false;
  }
  return isChallengeWorkoutId(workoutId);
};

export const getAllSupportedChallengeWorkouts = (state: IAppState) => {
  const allWorkouts = getAllWorkouts(state);
  const filteredByPlatform = Utils.filterByPlatform(allWorkouts);
  return Utils.filterByChallengeType(filteredByPlatform);
};

export const isChallengeExerciseId = (state: IAppState, exerciseId?: string) => {
  if (!exerciseId) {
    return false; // in case of empty id it must be a normal exercise
  }
  const challengeWorkouts = getAllSupportedChallengeWorkouts(state);
  const exerciseIds = Utils.getExerciseIds(challengeWorkouts);
  const exerciseIndex = exerciseIds.findIndex((workout) => workout.toLowerCase() === exerciseId.toLowerCase());
  return exerciseIndex > -1;
};

export const getChallengeWorkoutIdByCategoryGroupId = (state: IAppState, categoryGroupId: number) => {
  const categoryId = 0; // challenge workouts for now always have normal difficulty
  const workoutId = 2000 + (categoryId + 1) * 100 + categoryGroupId;
  const config = getWorkoutConfig(state, workoutId);
  if (!config) {
    throw new ConfigNotLoadedError(workoutId);
  }
  return workoutId;
};

export const getDomainForWorkoutId = (state: IAppState, workoutId: number) => {
  const config = getWorkoutConfig(state, workoutId);
  if (!config) {
    return EDomain.Assessment;
  }
  return Utils.getWorkoutDomain(config);
};

export const getAssessmentWorkoutIds = (state: IAppState) => {
  const allWorkouts = getAllWorkouts(state);
  const filteredByPlatform = Utils.filterByPlatform(allWorkouts);
  const assessmentWorkouts = Utils.filterByTypes(filteredByPlatform, ['assessment', 'selfAssessmentQuestionaire']);
  return Utils.getWorkoutIds(assessmentWorkouts);
};
