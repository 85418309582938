import { CacheService } from 'web_core_library';

export function createCacheService() {
  // map for loaded lotties
  const LottieMap: { [name: string]: any } = {};

  function loadLottie<T = any>(name: string) {
    return new Promise<T | undefined>((resolve, reject) => {
      if (LottieMap[name]) {
        // resolve promise with existing value without loading
        resolve(LottieMap[name]);
        return;
      }
      CacheService.load<T>(name).then(resolve).catch(reject);
    });
  }

  function saveLottie<T = any>(name: string, lottie: T) {
    return new Promise<void>((resolve, reject) => {
      if (LottieMap[name]) {
        // resolve promise if saved already
        resolve();
      } else {
        // memoize value and save to cache
        LottieMap[name] = lottie;
        CacheService.save(name, lottie).then(resolve).catch(reject);
      }
    });
  }

  return {
    init: CacheService.init,
    load: loadLottie,
    save: saveLottie,
  };
}

const LottieCache = createCacheService();
export default LottieCache;
