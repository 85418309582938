import { Action } from 'redux';
import { IUrlParams } from './attributionService';
import { EBannerMode, ICookieConsent, IEventActionPayload, IMessage } from './types';

export const FIREBASE_INIT = '@@FIREBASE/FIREBASE_INIT';
export type FIREBASE_INIT = typeof FIREBASE_INIT;
export interface IFirebaseInitAction extends Action<FIREBASE_INIT> {
  type: FIREBASE_INIT;
  apiKey: string;
  projectId: string;
  appId: string;
  messagingSenderId: string;
  measurementId: string;
  searchParams: URLSearchParams;
}

export const FIREBASE_INITIALIZED = '@@FIREBASE/FIREBASE_INITIALIZED';
export type FIREBASE_INITIALIZED = typeof FIREBASE_INITIALIZED;
export interface IFirebaseInitializedAction extends Action<FIREBASE_INITIALIZED> {
  type: FIREBASE_INITIALIZED;
}

export const FIREBASE_START = '@@FIREBASE/FIREBASE_START';
export type FIREBASE_START_TYPE = typeof FIREBASE_START;
export interface IFirebaseStartAction extends Action<FIREBASE_START_TYPE> {
  type: FIREBASE_START_TYPE;
  options: {
    analytics?: boolean;
    config?: boolean;
    messaging?: boolean;
  };
}

export const FIREBASE_READY = '@@FIREBASE/FIREBASE_READY';
export type FIREBASE_READY = typeof FIREBASE_READY;
export interface IFirebaseReadyAction extends Action<FIREBASE_READY> {
  type: FIREBASE_READY;
  analytics: boolean;
  config: boolean;
  messaging: boolean;
}

export const FIREBASE_UPDATE_CONSENT = '@@FIREBASE/FIREBASE_UPDATE_CONSENT';
export type FIREBASE_UPDATE_CONSENT = typeof FIREBASE_UPDATE_CONSENT;
export interface IUpdateFirebaseConsentAction extends Action<FIREBASE_UPDATE_CONSENT> {
  type: FIREBASE_UPDATE_CONSENT;
  analytics: boolean;
  userData: boolean;
}

export const ANALYTICS_EVENT = '@@FIREBASE/ANALYTICS_EVENT';
export type ANALYTICS_EVENT = typeof ANALYTICS_EVENT;
export interface IAnalyticsEventAction extends Action<ANALYTICS_EVENT> {
  type: ANALYTICS_EVENT;
  analyticsEvent: IEventActionPayload;
}

export const SET_USER = '@@FIREBASE/SET_USER';
export type SET_USER_TYPE = typeof SET_USER;
export interface ISetUserAction extends Action<SET_USER_TYPE> {
  type: SET_USER_TYPE;
  userId: number;
  email?: string;
}

export const TABOOLA_INIT = '@@FIREBASE/TABOOLA_INIT';
export type TABOOLA_INIT_TYPE = typeof TABOOLA_INIT;
export interface IInitTaboolaAction extends Action<TABOOLA_INIT_TYPE> {
  type: TABOOLA_INIT_TYPE;
  taboolaClickId?: string;
}

export const TABOOLA_SUBSCRIBE_EVENT = '@@FIREBASE/TABOOLA_SUBSCRIBE_EVENT';
export type TABOOLA_SUBSCRIBE_EVENT_TYPE = typeof TABOOLA_SUBSCRIBE_EVENT;
export interface ITaboolaSubscribeEventAction extends Action<TABOOLA_SUBSCRIBE_EVENT_TYPE> {
  type: TABOOLA_SUBSCRIBE_EVENT_TYPE;
}

export const TABOOLA_VALIDATION_EVENT = '@@FIREBASE/TABOOLA_VALIDATION_EVENT';
export type TABOOLA_VALIDATION_EVENT_TYPE = typeof TABOOLA_VALIDATION_EVENT;
export interface ITaboolaValidationEventAction extends Action<TABOOLA_VALIDATION_EVENT_TYPE> {
  type: TABOOLA_VALIDATION_EVENT_TYPE;
}

export const OUTBRAIN_INIT = '@@FIREBASE/OUTBRAIN_INIT';
export type OUTBRAIN_INIT_TYPE = typeof OUTBRAIN_INIT;
export interface IInitOutbrainAction extends Action<OUTBRAIN_INIT_TYPE> {
  type: OUTBRAIN_INIT_TYPE;
  outbrainClickId?: string;
}

export const OUTBRAIN_SUBSCRIBE_EVENT = '@@FIREBASE/OUTBRAIN_SUBSCRIBE_EVENT';
export type OUTBRAIN_SUBSCRIBE_EVENT_TYPE = typeof OUTBRAIN_SUBSCRIBE_EVENT;
export interface IOutbrainSubscribeEventAction extends Action<OUTBRAIN_SUBSCRIBE_EVENT_TYPE> {
  type: OUTBRAIN_SUBSCRIBE_EVENT_TYPE;
}

export const OUTBRAIN_VALIDATION_EVENT = '@@FIREBASE/OUTBRAIN_VALIDATION_EVENT';
export type OUTBRAIN_VALIDATION_EVENT_TYPE = typeof OUTBRAIN_VALIDATION_EVENT;
export interface IOutbrainValidationEventAction extends Action<OUTBRAIN_VALIDATION_EVENT_TYPE> {
  type: OUTBRAIN_VALIDATION_EVENT_TYPE;
}

export const SET_PROPERTY = '@@FIREBASE/SET_PROPERTY';
export type SET_PROPERTY_TYPE = typeof SET_PROPERTY;
export interface ISetPropertyAction extends Action<SET_PROPERTY_TYPE> {
  type: SET_PROPERTY_TYPE;
  name: string;
  value: string | number | boolean | undefined;
}

export const REQUEST_PUSH_PERMISSION = '@@FIREBASE/REQUEST_PUSH_PERMISSION';
export type REQUEST_PUSH_PERMISSION_TYPE = typeof REQUEST_PUSH_PERMISSION;
export interface IRequestPermissionAction extends Action<REQUEST_PUSH_PERMISSION_TYPE> {
  type: REQUEST_PUSH_PERMISSION_TYPE;
  vapidKey: string;
}

export const PUSH_PERMISSION_RESULT = '@@FIREBASE/PUSH_PERMISSION_RESULT';
export type PUSH_PERMISSION_RESULT_TYPE = typeof PUSH_PERMISSION_RESULT;
export interface IPermissionResultAction extends Action<PUSH_PERMISSION_RESULT_TYPE> {
  type: PUSH_PERMISSION_RESULT_TYPE;
  permission: boolean;
  reason?: string;
  unsupported?: boolean;
}

export const FIREBASE_ENABLE_MESSAGING = '@@FIREBASE/FIREBASE_ENABLE_MESSAGING';
export type FIREBASE_ENABLE_MESSAGING_TYPE = typeof FIREBASE_ENABLE_MESSAGING;
export interface IFirebaseEnableMessagingAction extends Action<FIREBASE_ENABLE_MESSAGING_TYPE> {
  type: FIREBASE_ENABLE_MESSAGING_TYPE;
  vapidKey: string;
}

export const FIREBASE_MESSAGING_ENABLED = '@@FIREBASE/FIREBASE_MESSAGING_ENABLED';
export type FIREBASE_MESSAGING_ENABLED_TYPE = typeof FIREBASE_MESSAGING_ENABLED;
export interface IFirebaseMessagingEnabledAction extends Action<FIREBASE_MESSAGING_ENABLED_TYPE> {
  type: FIREBASE_MESSAGING_ENABLED_TYPE;
}

export const FIREBASE_MESSAGE_RECEIVED = '@@FIREBASE/FIREBASE_MESSAGE_RECEIVED';
export type FIREBASE_MESSAGE_RECEIVED_TYPE = typeof FIREBASE_MESSAGE_RECEIVED;
export interface IMessageReceivedAction extends Action<FIREBASE_MESSAGE_RECEIVED_TYPE> {
  type: FIREBASE_MESSAGE_RECEIVED_TYPE;
  payload: IMessage;
}

export const FIREBASE_MESSAGE_SHOWN = '@@FIREBASE/FIREBASE_MESSAGE_SHOWN';
export type FIREBASE_MESSAGE_SHOWN_TYPE = typeof FIREBASE_MESSAGE_SHOWN;
export interface IMessageShownAction extends Action<FIREBASE_MESSAGE_SHOWN_TYPE> {
  type: FIREBASE_MESSAGE_SHOWN_TYPE;
  message: IMessage;
}

export const FIREBASE_MESSAGE_CLICKED = '@@FIREBASE/FIREBASE_MESSAGE_CLICKED';
export type FIREBASE_MESSAGE_CLICKED_TYPE = typeof FIREBASE_MESSAGE_CLICKED;
export interface IMessageClickedAction extends Action<FIREBASE_MESSAGE_CLICKED_TYPE> {
  type: FIREBASE_MESSAGE_CLICKED_TYPE;
  messageId: string;
}

// GDPR Action Types
export const GDPR_UPDATE_UTM = 'GDPR_UPDATE_UTM';
export type GDPR_UPDATE_UTM = typeof GDPR_UPDATE_UTM;
export interface IUpdateUtmTrackingAction extends Action<GDPR_UPDATE_UTM> {
  type: GDPR_UPDATE_UTM;
  utm: IUrlParams;
}

export const BANNER_MODE_UPDATE = 'BANNER_MODE_UPDATE';
export type BANNER_MODE_UPDATE_TYPE = typeof BANNER_MODE_UPDATE;
export interface IUpdateBannerModeAction extends Action<BANNER_MODE_UPDATE_TYPE> {
  type: BANNER_MODE_UPDATE_TYPE;
  mode: EBannerMode;
}

export const GDPR_UPDATE_CONSENT = 'GDPR_UPDATE_CONSENT';
export type GDPR_UPDATE_CONSENT = typeof GDPR_UPDATE_CONSENT;
export interface IUpdateConsentAction extends Action<GDPR_UPDATE_CONSENT> {
  type: GDPR_UPDATE_CONSENT;
  consent: ICookieConsent;
}

export const GDPR_SAVE_USER_CONSENT = 'GDPR_SAVE_USER_CONSENT';
export type GDPR_SAVE_USER_CONSENT = typeof GDPR_SAVE_USER_CONSENT;
export interface ISaveUserConsentAction extends Action<GDPR_SAVE_USER_CONSENT> {
  type: GDPR_SAVE_USER_CONSENT;
  consent: ICookieConsent;
}

export const GDPR_SETTINGS_TOGGLE = 'GDPR_SETTINGS_TOGGLE';
export type GDPR_SETTINGS_TOGGLE = typeof GDPR_SETTINGS_TOGGLE;
export interface IToggleSettingsAction extends Action<GDPR_SETTINGS_TOGGLE> {
  type: GDPR_SETTINGS_TOGGLE;
}

export const GDPR_INIT_EXPORT = 'GDPR_INIT_EXPORT';
export type GDPR_INIT_EXPORT_TYPE = typeof GDPR_INIT_EXPORT;
export interface IInitGdprExportAction extends Action<GDPR_INIT_EXPORT_TYPE> {
  type: GDPR_INIT_EXPORT_TYPE;
}

export const GDPR_EXPORT_REQUEST = 'GDPR_EXPORT_REQUEST';
export type GDPR_EXPORT_REQUEST_TYPE = typeof GDPR_EXPORT_REQUEST;
export interface IRequestExportAction extends Action<GDPR_EXPORT_REQUEST_TYPE> {
  type: GDPR_EXPORT_REQUEST_TYPE;
}

// Further GDPR action types
export const GDPR_EXPORT_REQUEST_SUCCESS = 'GDPR_EXPORT_REQUEST_SUCCESS';
export type GDPR_EXPORT_REQUEST_SUCCESS_TYPE = typeof GDPR_EXPORT_REQUEST_SUCCESS;
export interface IRequestExportSuccessAction extends Action<GDPR_EXPORT_REQUEST_SUCCESS_TYPE> {
  type: GDPR_EXPORT_REQUEST_SUCCESS_TYPE;
}

export const GDPR_EXPORT_REQUEST_FAILURE = 'GDPR_EXPORT_REQUEST_FAILURE';
export type GDPR_EXPORT_REQUEST_FAILURE_TYPE = typeof GDPR_EXPORT_REQUEST_FAILURE;
export interface IRequestExportFailureAction extends Action<GDPR_EXPORT_REQUEST_FAILURE_TYPE> {
  type: GDPR_EXPORT_REQUEST_FAILURE_TYPE;
}

export type GdprExportResponseAction = IRequestExportSuccessAction | IRequestExportFailureAction;

export type IFirebaseActions =
  | IFirebaseInitAction
  | IFirebaseInitializedAction
  | IFirebaseStartAction
  | IUpdateFirebaseConsentAction
  | IFirebaseReadyAction
  | IAnalyticsEventAction
  | ISetUserAction
  | IInitTaboolaAction
  | ISetPropertyAction
  | ITaboolaSubscribeEventAction
  | IRequestPermissionAction
  | IPermissionResultAction
  | IFirebaseEnableMessagingAction
  | IFirebaseMessagingEnabledAction
  | IMessageReceivedAction
  | IMessageShownAction
  | IMessageClickedAction
  | IUpdateUtmTrackingAction
  | IUpdateBannerModeAction
  | IUpdateConsentAction
  // Further actions
  | ISaveUserConsentAction
  | IToggleSettingsAction
  | IInitGdprExportAction
  | IRequestExportAction
  | IRequestExportSuccessAction
  | IRequestExportFailureAction;
