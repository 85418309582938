import React, { FC, PropsWithChildren } from 'react';
import './NavBar2.scss';

export interface INavBarProps {
  className?: string;
  vertical?: boolean;
}

export const NavBar2: FC<PropsWithChildren<INavBarProps>> = ({ children, className, vertical }) => {
  const customClass = className ? ` ${className}` : '';
  const verticalClass = vertical ? ' vertical' : '';
  const menuClass = `nav-2${verticalClass}${customClass}`;
  return (
    <ul role="menu" className={menuClass}>
      {children}
    </ul>
  );
};

export default NavBar2;
