import find from 'lodash/find';
import * as AuthTypes from './types';

export enum Role {
  MODERATOR = 4,
  PREAUTHENTICATED = 9,
  WEBPREMIUM = 12,
  GROUP_ADMIN = 50,
}

export const findRole = (roles: AuthTypes.IRoleData[], role: Role) => find(roles, ['id', role]);

export const hasRole = (user: AuthTypes.IUser, role: Role) => {
  const roles = user.roles;
  if (!roles) {
    return false;
  }
  return !!findRole(roles, role);
};

export const hasPreAuthenticatedRole = (user: AuthTypes.IUser) => hasRole(user, Role.PREAUTHENTICATED);

export const hasPremiumRole = (user: AuthTypes.IUser) => hasRole(user, Role.WEBPREMIUM);

export const hasModeratorRole = (user: AuthTypes.IUser) => hasRole(user, Role.MODERATOR);

export const hasGroupAdminRole = (user: AuthTypes.IUser) => hasRole(user, Role.GROUP_ADMIN);

export const getRoleExpirationDate = (user: AuthTypes.IUser, role: Role) => {
  const roles = user.roles;
  if (!roles) {
    return;
  }
  const roleData = findRole(roles, role);
  return roleData?.expiresAt;
};
