import { sessionStorage } from 'web_core_library';
import { getUnixTimestamp } from '../../common/dateUtils';

export interface IAttribution {
  network?: string;
  campaigntype?: string;
  campaignid?: string;
  keyword?: string;
  gclid?: string;
  wbraid?: string;
  gbraid?: string;
}

export enum EUrlParamName {
  Source = 'utm_source',
  Medium = 'utm_medium',
  Campaign = 'utm_campaign',
  Content = 'utm_content',
  Term = 'utm_term',
  GClId = 'gclid',
  SessionId = 'sessionId',
  TaboolaId = 'taboola_click_id',
  OutbrainId = 'ob_click_id',
  BannerMode = 'cks',
  OBVariant = 'ob',
  OBParams = 'ob_params',
  SSOPage = 'sso',
  SSOBottom = 'sso_lp',
  LocaVariant = 'loca',
  SSOLocaVariant = 'sso_loca',
  TrustPilotV1 = 'v1',
  TrustPilotV2 = 'v2',
  MascotVariant = 'msct',
  ObFlow = 'ob_flow',
  WBRAID = 'wbraid',
  GBRAID = 'gbraid',
}

export type TParamValue = string | null;

export interface IUrlParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  gclid?: string;
  sessionId?: string;
  taboola_click_id?: string;
  ob_click_id?: string;
  cks?: string;
  ob?: string;
  ob_params?: string;
  sso?: string;
  sso_lp?: string;
  loca?: string;
  sso_loca?: string;
  v1?: string;
  v2?: string;
  msct?: string;
  ob_flow?: string;
  wbraid?: string;
  gbraid?: string;
}

const SESSION_ID_PREFIX = 'NN';

export function getAttributionService() {
  let trackingParams: IUrlParams = {};

  function restoreParams() {
    const saved = sessionStorage.load<IUrlParams>('utm');
    if (saved) {
      trackingParams = saved;
    }
  }

  function addParam(name: EUrlParamName, value: TParamValue) {
    if (!value) {
      // do not save null values to skip them when applying to url
      return;
    }
    if (trackingParams[name]) {
      // do not override value if already set
      return;
    }
    trackingParams[name] = value;
  }

  function updateParams(params: { [name: string]: string | null }) {
    const validNames: string[] = Object.values(EUrlParamName);
    for (const name in params) {
      if (Object.hasOwn(params, name) && validNames.includes(name)) {
        addParam(name as EUrlParamName, params[name]);
      }
    }
    saveParams();
  }

  function saveParams() {
    sessionStorage.save('utm', trackingParams);
  }

  function getParams() {
    return trackingParams;
  }

  function generateSessionId() {
    const seed = Math.floor(Math.random() * Math.pow(2, 32));
    const timestamp = getUnixTimestamp();
    return `${SESSION_ID_PREFIX}.${seed}.${timestamp}`;
  }

  function parseParamsFromUrl(urlParams?: URLSearchParams) {
    const params = urlParams || new URLSearchParams();
    // read internal tracking parameters
    const nnCampaigntype = params.get(EUrlParamName.Campaign);
    const nnNetwork = params.get(EUrlParamName.Source);
    const nnCampaignid = params.get(EUrlParamName.Content);
    const nnMedium = params.get(EUrlParamName.Medium);
    const nnTerm = params.get(EUrlParamName.Term);
    const nnGclid = params.get(EUrlParamName.GClId);
    const nnWBRAID = params.get(EUrlParamName.WBRAID);
    const nnGBRAID = params.get(EUrlParamName.GBRAID);
    const nnSessionId = params.get(EUrlParamName.SessionId);
    const nnTaboolaId = params.get(EUrlParamName.TaboolaId);
    const nnOutbrainId = params.get(EUrlParamName.OutbrainId);
    // read external utm tracking parameters
    const utmCampaigntype = params.get(EUrlParamName.Campaign);
    const utmNetwork = params.get(EUrlParamName.Source);
    const utmCampaignid = params.get(EUrlParamName.Content);
    const utmMedium = params.get(EUrlParamName.Medium);
    const utmTerm = params.get(EUrlParamName.Term);
    const utmGclid = params.get(EUrlParamName.GClId);
    const utmWBRAID = params.get(EUrlParamName.WBRAID);
    const utmGBRAID = params.get(EUrlParamName.GBRAID);
    const taboolaId = params.get(EUrlParamName.TaboolaId);
    const outbrainId = params.get(EUrlParamName.OutbrainId);
    const bannerMode = params.get(EUrlParamName.BannerMode);
    const obVariant = params.get(EUrlParamName.OBVariant);
    const obParams = params.get(EUrlParamName.OBParams);
    const ssoPage = params.get(EUrlParamName.SSOPage);
    const ssoBottom = params.get(EUrlParamName.SSOBottom);
    const loca = params.get(EUrlParamName.LocaVariant);
    const sso_loca = params.get(EUrlParamName.SSOLocaVariant);
    const trustPilot1 = params.get(EUrlParamName.TrustPilotV1);
    const trustPilot2 = params.get(EUrlParamName.TrustPilotV2);
    const obFlow = params.get(EUrlParamName.ObFlow);
    // update parameters in storage
    const utmParams = {
      [EUrlParamName.Campaign]: utmCampaigntype ?? nnCampaigntype,
      [EUrlParamName.Source]: utmNetwork ?? nnNetwork,
      [EUrlParamName.Content]: utmCampaignid ?? nnCampaignid,
      [EUrlParamName.Medium]: utmMedium ?? nnMedium,
      [EUrlParamName.Term]: utmTerm ?? nnTerm,
      [EUrlParamName.GClId]: utmGclid ?? nnGclid,
      [EUrlParamName.WBRAID]: utmWBRAID ?? nnWBRAID,
      [EUrlParamName.GBRAID]: utmGBRAID ?? nnGBRAID,
      [EUrlParamName.SessionId]: nnSessionId ?? generateSessionId(),
      [EUrlParamName.TaboolaId]: taboolaId ?? nnTaboolaId,
      [EUrlParamName.OutbrainId]: outbrainId ?? nnOutbrainId,
      [EUrlParamName.BannerMode]: bannerMode,
      [EUrlParamName.OBVariant]: obVariant,
      [EUrlParamName.OBParams]: obParams,
      [EUrlParamName.SSOPage]: ssoPage,
      [EUrlParamName.SSOBottom]: ssoBottom,
      [EUrlParamName.LocaVariant]: loca,
      [EUrlParamName.SSOLocaVariant]: sso_loca,
      [EUrlParamName.TrustPilotV1]: trustPilot1,
      [EUrlParamName.TrustPilotV2]: trustPilot2,
      [EUrlParamName.ObFlow]: obFlow,
    };
    updateParams(utmParams);
  }

  // this function propagates parameters saved from the initial url search string
  // to given search parameters to add them to the url for further redirect
  function updateSearchParams(searchParams: URLSearchParams) {
    const paramSet = [
      [EUrlParamName.Campaign, trackingParams.utm_campaign],
      [EUrlParamName.Content, trackingParams.utm_content],
      [EUrlParamName.Medium, trackingParams.utm_medium],
      [EUrlParamName.Source, trackingParams.utm_source],
      [EUrlParamName.Term, trackingParams.utm_term],
      [EUrlParamName.SessionId, trackingParams.sessionId],
      [EUrlParamName.TaboolaId, trackingParams.taboola_click_id],
      [EUrlParamName.OutbrainId, trackingParams.ob_click_id],
      [EUrlParamName.BannerMode, trackingParams.cks],
      [EUrlParamName.OBVariant, trackingParams.ob],
      [EUrlParamName.OBParams, trackingParams.ob_params],
      [EUrlParamName.SSOPage, trackingParams.sso],
      [EUrlParamName.SSOBottom, trackingParams.sso_lp],
      [EUrlParamName.LocaVariant, trackingParams.loca],
      [EUrlParamName.SSOLocaVariant, trackingParams.sso_loca],
      [EUrlParamName.TrustPilotV1, trackingParams.v1],
      [EUrlParamName.TrustPilotV2, trackingParams.v2],
      [EUrlParamName.MascotVariant, trackingParams.msct],
      [EUrlParamName.ObFlow, trackingParams.ob_flow],
      [EUrlParamName.GClId, trackingParams.gclid],
      [EUrlParamName.WBRAID, trackingParams.wbraid],
      [EUrlParamName.GBRAID, trackingParams.gbraid],
    ];
    for (const param of paramSet) {
      if (param[1] && param[0]) searchParams.set(param[0], param[1]);
    }
    return searchParams;
  }

  function getAttribution(searchParams?: URLSearchParams) {
    // restore params from session
    restoreParams();
    // try to parse parameters from url
    parseParamsFromUrl(searchParams);
    // prepare resulting structure
    const attribution: IAttribution = {
      network: trackingParams.utm_source,
      campaigntype: trackingParams.utm_campaign,
      campaignid: trackingParams.utm_content,
      keyword: trackingParams.utm_term,
      gclid: trackingParams.gclid,
      wbraid: trackingParams.wbraid,
      gbraid: trackingParams.gbraid,
    };
    return attribution;
  }

  return {
    addParam,
    getAttribution,
    getParams,
    parseParamsFromUrl,
    restoreParams,
    saveParams,
    updateSearchParams,
    updateParams,
  };
}

const AttributionService = getAttributionService();
export default AttributionService;
