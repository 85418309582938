import * as Reducer from './slice';

export interface IState {
  // this feature state
  [Reducer.statePropName]: Reducer.ILanguageState;
  // other features we don't care of
  [otherKeys: string]: any;
}

const getAppState = (state: IState) => state.language;

export const getLanguage = (state: IState) => getAppState(state).language;

export const isLoading = (state: IState) => !getLanguage(state);
