import { ItemPredicate, ItemRenderer } from '@blueprintjs/select';
import { ILanguage } from './types';

export const filterLanguage: ItemPredicate<ILanguage> = (query, language, _index, exactMatch) => {
  const normalizedCode = language.code.toLowerCase();
  const normalizedTitle = language.title.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedCode === normalizedQuery;
  } else {
    return `${normalizedTitle}. ${normalizedCode}`.indexOf(normalizedQuery) >= 0;
  }
};

export const renderLanguage: ItemRenderer<ILanguage> = (language, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <li key={language.code} onClick={handleClick}>
      <div className="language-selector-item">
        <div className="dropdown-flag">
          <span className={`flag flag-${language.code}`} />
        </div>
        <span>{language.title}</span>
      </div>
    </li>
  );
};
