import { Button, EButtonColor } from '@web_opxp/components';
import React from 'react';
import { renderMultilineLoca, useTranslation } from '../../language';

export interface IConsentBannerProps {
  privacyUrl: string;
  show: boolean;
  onAcceptAll: () => void;
  onDeclineAll: () => void;
  onToggleSettings: () => void;
}

export const ConsentBanner: React.FC<IConsentBannerProps> = ({
  privacyUrl,
  show,
  onAcceptAll,
  onDeclineAll,
  onToggleSettings,
}) => {
  const { t } = useTranslation();
  const cookieNote = React.useMemo(() => {
    const privacyPolicyLink = (
      <a href={privacyUrl} rel="noopener noreferrer" target="_blank">
        {t('common.common:Cookies.LinkLabel')}
      </a>
    );
    const cookiesNoteLocalized = t('common.common:Cookies.NoticeLines', { privacyPolicyLink: '[policylink]' });
    return renderMultilineLoca(cookiesNoteLocalized, { search: '[policylink]', replace: privacyPolicyLink });
  }, [privacyUrl, t]);
  if (!show) {
    return null;
  }
  return (
    <div className="cookie-consent-banner">
      <div className="container cookie-consent-banner-container">
        <div className="row">
          <div className="col-6 col-m-4">
            <div className="cookie-consent-content">{cookieNote}</div>
          </div>
          <div className="col-1 col-m-0"></div>
          <div className="col-4 col-m-4">
            <div className="buttons-container">
              <Button
                className="button"
                label={t('common.common:Cookies.CTA.Settings')}
                color={EButtonColor.white}
                onClick={onToggleSettings}
              />
              <Button
                className="button"
                label={t('common.common:Cookies.CTA.DeclineAll')}
                color={EButtonColor.blue}
                onClick={onDeclineAll}
              />
              <Button
                className="button"
                label={t('common.common:Cookies.CTA.AcceptAll')}
                color={EButtonColor.blue}
                onClick={onAcceptAll}
              />
            </div>
          </div>
          <div className="col-1 col-m-0"></div>
        </div>
      </div>
    </div>
  );
};
export default ConsentBanner;
