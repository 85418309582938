import lottie, { AnimationEventName, AnimationItem } from 'lottie-web';
import { Dispatch } from 'redux';
import * as Actions from './actions';
import LottieError from './LottieError';

interface IAnimationItem extends AnimationItem {
  currentFrame: number;
}

export interface ILottieMap {
  [name: string]: IAnimationItem;
}

export interface ILottieFrameEvent {
  type: AnimationEventName;
  currentTime: number;
  totalTime: number;
  direction: number;
}

export class LottieControllerClass {
  private lottieMap: ILottieMap;
  private dispatch?: Dispatch;

  constructor() {
    this.lottieMap = {};
  }

  setup(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  private containerIsEmpty(container: HTMLElement): boolean {
    return !container.getElementsByTagName('svg').length;
  }

  private lottieNotSavedBefore(id: string): boolean {
    return !this.lottieMap[id];
  }

  private isNewLottie(id: string, container: HTMLElement): boolean {
    return this.lottieNotSavedBefore(id) || this.containerIsEmpty(container);
  }

  load(id: string, animationData: any, autoplay = false, loop = false, dontKeep = false, stopOn = 0) {
    const container = document.getElementById(id);
    if (!container) {
      // container for animation was already destroyed - nothing to do
      return;
    }

    if (this.isNewLottie(id, container)) {
      this.lottieMap[id] = lottie.loadAnimation({
        name: id,
        container,
        autoplay,
        loop,
        animationData,
        renderer: 'svg',
      });
    }
    const item = this.lottieMap[id];
    if (stopOn) {
      item.addEventListener('enterFrame', (event: ILottieFrameEvent) => this.stopAtHandler(event, stopOn, id));
      // make animation slower when duration reduced
      item.setSpeed(stopOn / item.getDuration(true));
      if (item.currentFrame !== undefined && item.currentFrame < stopOn) {
        item.play();
      }
    }
    item.addEventListener('DOMLoaded', this.loadedHandler(id));
    item.addEventListener('complete', this.completeHandler(id, dontKeep));
  }

  stopAtHandler = (event: ILottieFrameEvent, frame: number, id: string) => {
    // currentTime is decimal number and can skip some frames, therefore ">" check is necessary
    if (event.currentTime > frame) {
      this.lottieMap[id].pause();
    }
  };

  loadedHandler = (id: string) => () => {
    if (!this.dispatch) {
      throw new LottieError('LottieController not set up properly!');
    }
    this.dispatch(Actions.lottieLoadedAction(id));
  };

  completeHandler = (id: string, dontKeep: boolean) => () => {
    if (!this.dispatch) {
      throw new LottieError('LottieController not set up properly!');
    }
    this.dispatch(Actions.lottieCompleteAction(id));
    if (dontKeep) {
      lottie.destroy(id);
      delete this.lottieMap[id];
    }
  };

  play = (id: string) => {
    lottie.play(id);
  };
}

export const LottieController = new LottieControllerClass();
export default LottieController;
