export enum EExerciseCategory {
  Medium = 0,
  Easy = 1,
  Hard = 2,
}

export enum EDomain {
  Assessment = 0,
  Speed = 1,
  Reasoning = 2,
  Attention = 3,
  Memory = 4,
  SelfRegulation = 5,
  CFQScore = 100,
  Forgetfulness = 101,
  Distractibility = 102,
  FalseTriggering = 103,
  Mood = 20001,
}

export interface IMapDomainIds {
  [key: number]: string;
}

export interface ITrainingIntensity {
  intensity: number;
  session_amount_in_cycle: number;
  workout_amount_in_session: number;
  cycle_duration_days: number;
}

export interface IOboardingStep {
  workoutId: number;
  exerciseId: string;
  image: string;
  description: string;
  title: string;
}

export interface IOnboardingConfig {
  steps: IOboardingStep[];
}
