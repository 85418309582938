import { IApiService, getHttpService } from 'web_core_library';

const IterableUrl = 'https://api.iterable.com/api';

export interface IIterableResponsePayload {
  msg: string;
  code:
    | 'Success'
    | 'BadApiKey'
    | 'BadParams'
    | 'BadJsonBody'
    | 'QueueEmailError'
    | 'GenericError'
    | 'InvalidEmailAddressError'
    | 'DatabaseError'
    | 'EmailAlreadyExists'
    | 'Forbidden';
  params?: unknown;
}

interface IAPITokenResponsePayload {
  result: {
    iterableToken: string;
    expiresIn: number;
    tokenType: 'Bearer';
    userId: number;
    apiKey: string;
  };
}

export function getIterableService(httpOverride?: ReturnType<typeof getHttpService>) {
  let ApiService: IApiService;
  const http = httpOverride ?? getHttpService(IterableUrl);
  let userId: string;
  let email: string;
  let iterableApiKey: string;
  let jwtToken: string;
  const init = (ApiServiceInstance: IApiService, uId: number, userEmail: string) => {
    ApiService = ApiServiceInstance;
    userId = `${uId}`;
    email = userEmail;
  };
  const getIterableToken = async () => {
    if (!userId || !email) {
      throw new Error('IterableService must be initialized first!');
    }
    const tokenResponse = await ApiService.action<IAPITokenResponsePayload>('iterable_auth', {
      method: 'post',
      urlParams: {
        userId,
      },
      data: {
        device: 'web',
      },
    });
    const { iterableToken, apiKey } = tokenResponse.data.result;
    jwtToken = iterableToken;
    http.setToken(iterableToken);
    iterableApiKey = apiKey;
  };
  const registerPushToken = async (browserToken: string) => {
    await getIterableToken();
    return http.request<IIterableResponsePayload>({
      method: 'POST',
      url: '/users/registerBrowserToken',
      data: {
        email,
        browserToken,
        userId,
      },
      headers: {
        'Api-Key': iterableApiKey,
      },
    });
  };
  const trackPushClick = async (messageId: string) => {
    if (!iterableApiKey) {
      await getIterableToken();
    }
    return http.request<IIterableResponsePayload>({
      method: 'POST',
      url: '/events/trackWebPushClick',
      data: {
        email,
        userId,
        messageId,
      },
      headers: {
        'Api-Key': iterableApiKey,
      },
    });
  };
  const getApiKey = async () => {
    if (!iterableApiKey) {
      await getIterableToken();
    }
    return iterableApiKey;
  };
  const getJWTToken = async () => {
    if (!jwtToken) {
      await getIterableToken();
    }
    return jwtToken;
  };
  return {
    init,
    registerPushToken,
    trackPushClick,
    getApiKey,
    getJWTToken,
  };
}

const IterableService = getIterableService();

export default IterableService;
