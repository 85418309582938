import * as React from 'react';

import './CircleProgressBar.scss';

export const CircleProgressBar: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <div className="circle-progress-container">
      <div className="progress-bar">
        <div className="progress-bg"></div>
        <div className="progress-items">{children}</div>
      </div>
    </div>
  );
};

export default CircleProgressBar;
