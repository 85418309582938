import i18next, { i18n, InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import { Dispatch } from 'redux';
import { getDefaultConfig } from './config';
import { LanguageActions } from './slice';
import { ELanguage, ILanguageStore, TStoreListener } from './types';

function emptyT() {
  return 'Loading...';
}

export function parseLang(lang: ELanguage | string) {
  // ensure that we have only 2 characters in lower case as language
  return lang.toLowerCase().slice(0, 2) as ELanguage;
}

export function initService() {
  let i18nextInstance: i18n;

  let store: ILanguageStore = {
    //@ts-ignore
    t: emptyT,
    i18n: i18next,
  };

  let listeners: TStoreListener[] = [];

  const subscribe = (listener: TStoreListener) => {
    if (!listeners.includes(listener)) {
      listeners.push(listener);
    }
    return () => unsubscribe(listener);
  };

  const unsubscribe = (oldListener: TStoreListener) => {
    listeners = listeners.filter((listener) => listener !== oldListener);
  };

  const update = () => {
    listeners.forEach((listener) => listener(store));
  };

  const init = async (localesPath: string, dispatch?: Dispatch, customConfig?: InitOptions) => {
    if (i18nextInstance) {
      listeners = []; // Clear listeners
      // Remove listeners from old instance
      i18nextInstance.off('languageChanged');
      i18nextInstance.off('initialized');
    }
    if (dispatch) {
      // connect to redux if dispatch given
      listeners.push((store) => {
        dispatch(LanguageActions.updateLanguage(store.language));
      });
    }
    // update listeners with initial state
    update();
    const config: InitOptions = {
      ...getDefaultConfig(localesPath),
      ...customConfig,
    };
    i18nextInstance = i18next.createInstance();
    i18nextInstance.on('languageChanged', (lng) => {
      const isInitialized = i18nextInstance.isInitialized;
      const newStore: ILanguageStore = {
        language: isInitialized ? parseLang(lng) : undefined,
        //@ts-ignore
        t: isInitialized ? i18nextInstance.getFixedT(lng) : emptyT,
        i18n: i18nextInstance,
      };
      store = newStore;
      // update listeners with new state
      update();
    });
    i18nextInstance.on('initialized', () => {
      const language = store.language ? store.language : i18nextInstance.language;
      const newStore: ILanguageStore = {
        language: parseLang(language),
        t: i18nextInstance.getFixedT(language),
        i18n: i18nextInstance,
      };
      store = newStore;
      // update listeners with new state
      update();
    });
    await i18nextInstance.use(Backend).use(LanguageDetector).init(config);
  };

  const changeLanguage = (lng: string) => {
    return store.i18n.changeLanguage(lng);
  };

  const getStore = () => {
    return store;
  };

  const getLanguage = () => {
    return store.language;
  };

  const getTFunction = () => {
    return store.t;
  };

  const exists = (loca: string | string[]) => {
    return store.i18n.exists(loca);
  };

  const hasResourceBundle = (lng: string, ns: string) => {
    return store.i18n.hasResourceBundle(lng, ns);
  };

  const getResourceBundle = (lng: string, ns: string) => {
    return store.i18n.getResourceBundle(lng, ns);
  };

  const loadNamespaces = (ns: string | Array<string>) => {
    return store.i18n.loadNamespaces(ns);
  };

  return {
    init,
    changeLanguage,
    subscribe,
    unsubscribe,
    getStore,
    getLanguage,
    getTFunction,
    exists,
    hasResourceBundle,
    getResourceBundle,
    loadNamespaces,
  };
}

const service = initService();
export default service;
