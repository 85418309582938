import * as React from 'react';

export interface ISectionSubtitleProps {
  className?: string;
  wrapperTag?: 'p' | 'div';
}

const SECTION_SUBTITLE_CLASS = 'section-subtitle';

const SectionSubtitle: React.FC<React.PropsWithChildren<ISectionSubtitleProps>> = ({
  className = '',
  wrapperTag = 'p',
  children,
}) => {
  const sectionClass = `${SECTION_SUBTITLE_CLASS} ${className}`;
  if (wrapperTag === 'div') return <div className={sectionClass}>{children}</div>;
  return <p className={sectionClass}>{children}</p>;
};

export default SectionSubtitle;
