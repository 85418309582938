import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ELanguage } from './types';

export interface ILanguageState {
  language?: ELanguage;
}

export const initialState: ILanguageState = {
  language: undefined,
};

export const statePropName = 'language';

const languageSlice = createSlice({
  name: statePropName,
  initialState,
  reducers: {
    initLanguage: (state: ILanguageState) => {
      // Handle language initialization if needed
    },
    changeLanguage: (state: ILanguageState, action: PayloadAction<ELanguage>) => {
      return initialState;
    },
    updateLanguage: (state: ILanguageState, action: PayloadAction<ELanguage | undefined>) => {
      state.language = action.payload;
    },
    languageFail: (state: ILanguageState, action: PayloadAction<Error>) => {
      // Handle language feature failure if needed
    },
  },
});

export const LanguageActions = languageSlice.actions;
export default languageSlice.reducer;
