import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ApiService, sessionStorage, TApiFetchResponse } from 'web_core_library';
import { AuthSelectors } from '.';
import CookieManager from '../../common/cookieManager';
import AuthService from './authService';
import { hasPreAuthenticatedRole } from './roles';
import RoutingService from './routingService';
import { AuthActions, IRestoreAuthPayload } from './slice';
import { AuthError, IUnreadMessages, IUser, NotVerfiedError } from './types';
import { parseSessionToken } from './utils';

export function* restoreAuthSaga(action: PayloadAction<IRestoreAuthPayload>) {
  yield call(RoutingService.init, action.payload);
  AuthService.init(ApiService);
  try {
    const searchParams = RoutingService.getCurrentSearchParams();
    let session: string | null;
    // check if we've got the access token
    const accessToken = searchParams.get('signin');
    if (accessToken && !action.payload.skipAccessToken) {
      // get session token from access token
      session = yield call(getSessionTokenFromAccessToken, accessToken);
      if (!session) {
        // Couldn't restore session from access token, redirect to SSO
        throw new AuthError('common:Errors.Unauthorized');
      }
    } else {
      // check session storage
      session = sessionStorage.load('session');
    }
    if (!session) {
      // check url
      session = searchParams.get('session');
      if (!session) {
        // We don't have the access token in params, check in cookies
        const cookieAccessToken = CookieManager.getCookie(CookieManager.COOKIE_AUTH_TOKEN);
        if (cookieAccessToken && !action.payload.skipAccessToken) {
          // get session token from access token
          session = yield call(getSessionTokenFromAccessToken, cookieAccessToken);
        }
        if (!session) {
          // Couldn't restore session from access token, redirect to SSO
          throw new AuthError('common:Errors.Unauthorized');
        }
      } else {
        // remove session from url
        searchParams.delete('session');
      }
    }
    let userId = 0;
    // check expiration date
    let expires: number;
    try {
      const processedToken = parseSessionToken(session);
      userId = processedToken.userId;
      expires = processedToken.expires;
    } catch (error) {
      // if parsing of the token fails (token invalid) - redirect to sso
      throw new AuthError('common:Errors.Unauthorized');
    }
    // if session was taken from url - replace url
    // we are trying to replace url in history without redirect
    RoutingService.updateSearchParams(searchParams);

    yield call(processSessionToken, session, expires, userId);
    // start background task to update session token from access token after renew threshold reached
    yield put(AuthActions.startTokenRefreshTask());
  } catch (error) {
    yield put(AuthActions.restoreAuthFail());
    sessionStorage.remove('session');
    if (error instanceof AuthError || error instanceof NotVerfiedError) {
      // silently suppress Auth Errors since apps will react to restoreAuthFail action
      return;
    }
    // rethrow other error further
    throw error;
  }
}

export function redirectToSSO() {
  return RoutingService.redirectLogin();
}

export function logoutSaga() {
  return RoutingService.redirectLogout();
}

export function* loadMessagesSummarySaga() {
  const summaryResponse: AxiosResponse<IUnreadMessages> = yield call(AuthService.getMessagesSummary);
  if (!summaryResponse || summaryResponse.status !== 200) {
    throw new Error('common:Errors.HttpUnknown');
  }
  const { regular, friendship, system } = summaryResponse.data;
  yield put(AuthActions.updateMessagesSummary({ regular, friendship, system }));
}

export function* refreshTokenSaga() {
  try {
    const response: TApiFetchResponse<typeof AuthService.revalidateSessionToken> = yield call(
      AuthService.revalidateSessionToken
    );
    const session = response.data.result.sessionToken;
    const userData = JSON.parse(window.atob(session.split('.')[1]));
    const userId = userData.uid;
    const expires = parseInt(userData.exp, 10) * 1000;
    sessionStorage.save('session', session);
    yield put(AuthActions.updateUserAuth({ session, expires }));

    // Set user data to ApiService
    yield call(ApiService.setUserData, userId, session, RoutingService.refreshToken);
  } catch (error) {
    // do nothing
  }
  yield put(AuthActions.restoreAuthSuccess());
  yield put(AuthActions.authRefreshDone());
}

export function* getSessionTokenFromAccessToken(accessToken: string) {
  try {
    const validationResult: AxiosResponse = yield call(AuthService.validate, accessToken);
    const { sessionToken } = validationResult.data.result;
    return sessionToken;
  } catch (error) {
    // if token validation fails - return null
    return null;
  }
}

export function* processSessionToken(sessionToken: string, expires: number, userId: number) {
  sessionStorage.save('session', sessionToken);
  yield put(AuthActions.updateUserAuth({ session: sessionToken, expires }));

  // Set user data to ApiService
  yield call(ApiService.setUserData, userId, sessionToken, RoutingService.refreshToken);
  const userResponse: AxiosResponse = yield call(AuthService.getUserRoles);
  if (!userResponse || userResponse.status !== 200) {
    throw new Error('common:Errors.HttpUnknown');
  }
  const user: IUser = userResponse.data.user;
  yield put(AuthActions.updateUser(user));
  const isNotVerified = hasPreAuthenticatedRole(user);
  if (isNotVerified) {
    // if user not verified - show the page with proper hint
    throw new NotVerfiedError('common:Errors.UnauthorizedOrUnverified');
  }

  yield put(AuthActions.getMessagesSummary());
  yield put(AuthActions.restoreAuthSuccess());
}

export function* startTokenRefreshTaskSaga() {
  const sessionToken: string | undefined = yield select(AuthSelectors.getToken);
  if (!sessionToken) throw new Error('session token not available to start autorenew');

  const { expires, iat } = parseSessionToken(sessionToken);
  const renew_timestamp = iat + (2 / 3) * (expires - iat);
  let now = Date.now();
  // Wait until expiration is less than threshold
  while (now < renew_timestamp) {
    yield delay(60000);
    now = Date.now();
  }
  // Start updating the token
  yield put(AuthActions.refreshSessionToken());
}

export function* refreshSessionTokenSaga() {
  try {
    const accessToken = CookieManager.getCookie(CookieManager.COOKIE_AUTH_TOKEN);
    if (!accessToken) {
      // We have no access token in cookies, redirect to SSO
      throw new AuthError('common:Errors.Unauthorized');
    }
    const sessionToken: string | null = yield call(getSessionTokenFromAccessToken, accessToken);
    if (!sessionToken) {
      // Couldn't restore session from access token, redirect to SSO
      throw new AuthError('common:Errors.Unauthorized');
    }
    const processedToken = parseSessionToken(sessionToken);
    yield call(processSessionToken, sessionToken, processedToken.expires, processedToken.userId);
    // start background task to update session token from access token after renew threshold reached
    yield put(AuthActions.startTokenRefreshTask());
  } catch (error) {
    yield put(AuthActions.restoreAuthFail());
    sessionStorage.remove('session');
    if (error instanceof AuthError || error instanceof NotVerfiedError) {
      // redirect to sso
      yield put(AuthActions.redirectToSSO());
      return;
    }
    // rethrow other error further
    throw error;
  }
}

export default function* authWatcher() {
  yield all([
    takeEvery(AuthActions.authRestore, restoreAuthSaga),
    takeEvery(AuthActions.redirectToSSO, redirectToSSO),
    takeEvery(AuthActions.logout, logoutSaga),
    takeLatest(AuthActions.getMessagesSummary, loadMessagesSummarySaga),
    takeEvery(AuthActions.authRefresh, refreshTokenSaga),
    takeLatest(AuthActions.startTokenRefreshTask, startTokenRefreshTaskSaga),
    takeEvery(AuthActions.refreshSessionToken, refreshSessionTokenSaga),
  ]);
}
