import { AxiosResponse } from 'axios';
import { getHttpService } from 'web_core_library';

export interface ILottieService {
  setAssetsUrl: (assetsUrl: string) => void;
  loadLottie: (name: string) => Promise<AxiosResponse<unknown>>;
}

const defaultAssetsPath = 'https://cms.cdn.neuronation.com/assets/opxp';
export function createLottieService(assetsUrl: string = defaultAssetsPath): ILottieService {
  const lottieUrl = `${assetsUrl}/lottie/`;
  const http = getHttpService(lottieUrl);
  const setAssetsUrl = (newAssetsUrl: string) => {
    http.setBaseURL(`${newAssetsUrl}/lottie/`);
  };
  const loadLottie = (name: string) => {
    const url = `${name}.json`;
    return http.get(url);
  };
  return {
    loadLottie,
    setAssetsUrl,
  };
}

const LottieService = createLottieService();
export default LottieService;
