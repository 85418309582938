import { Intent, OverlayToaster, Position, Toaster } from '@blueprintjs/core';
import { createRoot } from 'react-dom/client';
import { MessageContent } from './types';

let toaster: Toaster | undefined = undefined;

export default async function showMessage(intent: Intent, message: MessageContent, timeout = 10000) {
  if (!toaster) {
    toaster = await OverlayToaster.createAsync(
      {
        className: 'app-toaster',
        position: Position.TOP,
        maxToasts: 5,
      },
      {
        domRenderer: (toaster, containerElement) => createRoot(containerElement).render(toaster),
      }
    );
  }
  toaster.show({
    intent,
    message,
    timeout,
  });
}
