import Gauge from './Gauge';
import './SpeedometerCard.scss';

export interface ISpeedometerCardProps {
  id?: string;
  value: number;
  title: string;
  iconSrc: string;
  className?: string;
  gaugeMinLabel: string;
  gaugeMaxLabel: string;
  subtitle?: any;
}

export default function SpeedometerCard({
  id,
  value,
  title,
  iconSrc,
  className = '',
  gaugeMinLabel,
  gaugeMaxLabel,
  subtitle = null,
}: Readonly<ISpeedometerCardProps>) {
  return (
    <div className={`speedometer-card ${className}`} id={id}>
      <div className="speedometer-card-icon-wrapper">
        <img className="speedometer-card-icon" src={iconSrc} alt={title} />
      </div>
      <div className="speedometer-card-wrapper">
        <h3 className="speedometer-card-title">{title}</h3>
        <Gauge amount={value} minLabel={gaugeMinLabel} maxLabel={gaugeMaxLabel} />
        <div className="speedometer-card-subtitle">{subtitle}</div>
      </div>
    </div>
  );
}
