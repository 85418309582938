import { IApiService } from 'web_core_library';
import { IWorkoutConfigApiResponse } from './types';

export function createConfigService() {
  let ApiService: IApiService;

  const getWorkoutsConfig = () => {
    return ApiService.action<IWorkoutConfigApiResponse>('training_workouts', {
      method: 'get',
      urlParams: {
        userId: ApiService.getUserId(),
      },
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    getWorkoutsConfig,
  };
  return service;
}

const ConfigService = createConfigService();
export default ConfigService;
