import * as React from 'react';

import './Badge.scss';

export interface IBadgeProps {
  className?: string;
  color?: 'green' | 'red' | 'blue';
}

export const Badge: React.FC<React.PropsWithChildren<IBadgeProps>> = ({ className, color = 'green', children }) => {
  const customClass = className ? ` ${className}` : '';
  const badgeClass = `badge ${color}${customClass}`;
  return <span className={badgeClass}>{children}</span>;
};

export default Badge;
