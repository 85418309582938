import * as React from 'react';

import './NavBar.scss';

export interface INavBarProps {
  className?: string;
  vertical?: boolean;
}

export const NavBar: React.FC<React.PropsWithChildren<INavBarProps>> = ({ className, vertical = false, children }) => {
  const customClass = className ? ` ${className}` : '';
  const verticalClass = vertical ? ' nav__vertical' : '';
  const menuClass = `nav${verticalClass}${customClass}`;
  return <ul className={menuClass}>{children}</ul>;
};

export default NavBar;
