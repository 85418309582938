import { Classes } from '@blueprintjs/core';
import { FC } from 'react';

export interface IDialogFooterProps {
  className?: string;
  actionsClassName?: string;
}

const DialogFooter: FC<React.PropsWithChildren<IDialogFooterProps>> = ({ className, actionsClassName, children }) => {
  const customClassname = className ?? '';
  return (
    <div className={`${Classes.DIALOG_FOOTER} ${customClassname}`}>
      <div className={`${Classes.DIALOG_FOOTER_ACTIONS} ${actionsClassName}`}>{children}</div>
    </div>
  );
};

export default DialogFooter;
