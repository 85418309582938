import * as React from 'react';

import './CircleIndicator.scss';

export interface ICircleIndicatorProps {
  size?: number;
  width?: number;
  progress?: number;
}

export const CircleIndicator: React.FC<ICircleIndicatorProps> = ({ size = 120, width = 2, progress = 0 }) => {
  const center = size / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const transform = `rotate(-90,${center},${center})`;
  const radius = center - width;
  let valueCircle;
  if (progress > 0) {
    const value = 2 * Math.PI * radius * progress;
    valueCircle = (
      <circle
        className="circle-value"
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={width + 2}
        strokeDasharray={value + ',20000'}
        transform={transform}
        strokeLinecap="round"
      />
    );
  }
  return (
    <svg
      className="circle-indicator"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox={viewBox}>
      <circle className="circle-border" cx={center} cy={center} r={radius} strokeWidth={width} />
      {valueCircle}
    </svg>
  );
};

export default CircleIndicator;
