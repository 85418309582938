import * as React from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { lottieLoadAction } from './actions';
import * as ActionTypes from './actionTypes';

export interface ILottieAnimationProps {
  name: string;
  autoplay?: boolean;
  loop?: boolean;
  dontKeep?: boolean;
  id?: string;
  stopOn?: number;
  className?: string;
}

export interface IProps extends ILottieAnimationProps {
  onLoad: ActionCreator<ActionTypes.ILottieLoadAction>;
}

export function LottieAnimationContainer({
  name,
  autoplay = false,
  loop = false,
  dontKeep = false,
  id = name,
  stopOn,
  className,
  onLoad,
}: Readonly<IProps>) {
  const container: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);
  React.useEffect(() => {
    if (!container.current && !onLoad) {
      return;
    }
    onLoad(name, autoplay, loop, dontKeep, id, stopOn);
  }, [onLoad, name, autoplay, loop, dontKeep, id, stopOn, container]);
  const customClass = className ? ` ${className}` : '';
  const containerClass = `lottie-container${customClass}`;
  return <div id={id} className={containerClass} ref={container} />;
}

export const mapDispatchToProps = {
  onLoad: lottieLoadAction,
};

export const LottieAnimation = connect(null, mapDispatchToProps)(LottieAnimationContainer);
export default LottieAnimation;
